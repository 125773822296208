import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { filterPayers } from '../utils/staticData';
import { sharedStyles } from '../sharedStyles';

export default function PayerDropdown({state, setter}) {
  

  const handleChange = (event) => {
    setter(event.target.value);
  };


  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={[sharedStyles?.minWidth120, sharedStyles?.mb1]} size='small'>
        <InputLabel id="filter-payers">Type</InputLabel>
        <Select
          labelId="filter-payers"
          id="filter-payers-select"
          value={state}
          label="Type"
          onChange={handleChange}
          sx={[sharedStyles?.wFull, sharedStyles?.fs08]}
        >
            {
                filterPayers?.map((item, index) => (
                        <MenuItem sx={sharedStyles?.fs08} key={index} value={item.value}>{item.label}</MenuItem>
                ))
            }
        </Select>
      </FormControl>
    </Box>
  );
}
