import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import PageLoader from '../../../../loader/PageLoader'
import CdTable from '../../../../table/CdTable'
import toast from 'react-hot-toast'
import { AetnaSrContext } from '../../../../../context/priorAuthorizationForms/AetnaSrContext'
import { getAllCDcodes } from '../../../../../services/pVerify'
import CommonNextButton from '../../components/CommonNextButton'
import ServiceInfoList from '../../components/ServicesInfoList'
import { paFormErrors } from '../../../../../utils/staticData'

const DiagnoseSrForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [cptCodes, setCptCodes] = useState([])
    const {diagnose:serviceInfo, setDiagnose:setServiceInfo, setSelectedTab,onceCompleted} = useContext(AetnaSrContext)
    const [errors, setErrors] = useState([])

    // functions
    const handleSubmit = (submit) => {
        const inCompleteDiagnoses = serviceInfo?.filter(e => !e?.qualifierCode)
        if(inCompleteDiagnoses?.length){
            setErrors(serviceInfo?.map(e => {
                return(
                    {
                        qualifierCode : e?.qualifierCode ? false : true
                    }
                )
            }))
            toast?.error(paFormErrors?.propInfo)
            return
        }
       submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
    };

    const getCptCodes = async () => {
        try {
            setIsLoading(true)
            const data = await getAllCDcodes();
            if (data) {
                setCptCodes(data?.data?.data)
            } else {
                setCptCodes([])
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCptCodes();
    }, [])

    const onRowSelected = (rowData) => {
        setServiceInfo(prevServiceInfo => {
            const isDuplicate = prevServiceInfo?.some(e => e?.id === rowData?.id);
    
            if (isDuplicate) {
                toast.error('This diagnosis is already selected');
                return prevServiceInfo; // No change
            }
    
            if (prevServiceInfo?.length < 3) {
                return [...prevServiceInfo, rowData]; // Add new item
            } else {
                toast.error('Select only up to 3 diagnoses');
                return prevServiceInfo; // No change
            }
        });
    };
    
    const handleOnRemove = (idToRemove) => {
        setServiceInfo((prevServiceInfo) => {
            const updated = prevServiceInfo.filter((item) => item.id !== idToRemove);
            if (updated.length !== prevServiceInfo.length) {
                // toast.success('Diagnosis removed successfully');
            }
            return updated;
        });
    };

    
  return (
    <>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Diagnoses Information
                </Typography>


                <Box sx={styles?.commonPaperContainer}>
                    {isLoading ?
                        <PageLoader />
                        :
                        (
                            <>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <CdTable data={cptCodes} onRowSelected={onRowSelected} />
                                    </Grid>

                                </Grid>
                                    {serviceInfo?.length ?
                                    <ServiceInfoList serviceInfoArray={serviceInfo} onRemove={handleOnRemove}
                                    errors={errors}
                                    setServiceInfoArray={setServiceInfo}
                                    showQualifierCode
                                    /> : null
                                    
                                    }

                            </>
                        )}
                </Box>

                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
            </Paper>
        </>
  )
}

export default DiagnoseSrForm