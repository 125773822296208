import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { sharedStyles } from '../../../../sharedStyles';
import { styles } from './styles';
import NextButton from '../components/NextButton';
import { AetnaContext } from '../../../../context/priorAuthorizationForms/AetnaContext';
import { getAllCDcodes, getAllCPTcodes } from '../../../../services/pVerify';
import HorizontalHeadingPlusText from '../../../shared/HorizontalHeadingPlusText'
import CdTable from '../../../table/CdTable';
import CptTable from '../../../table/CptTable';
import PageLoader from '../../../../components/loader/PageLoader';

const CommonProceduresForm = ({ procedures,  setProcedures, button}) => {
    const [cptCodes, setCptCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const getCptCodes = async () => {
        try {
            setIsLoading(true)
          const data = await getAllCPTcodes();
          if(data) {
            setCptCodes(data?.data?.data)
          } else {
            setCptCodes([])
          }
        } catch (error) {
          console.log(error)
        }
        finally{
            setIsLoading(false)
        }
      }

    useEffect(() => {
        getCptCodes();
    }, [])
   
    const onRowSelected = (rowData) => {
      setProcedures(rowData)
    };
    
    return (

        <>
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Procedure Information
            </Typography>

            
                <Box sx={styles?.commonPaperContainer}>
                    {isLoading ?
                      <PageLoader />
                :
                (
                    <>
                        <Grid container spacing={3}>

                        <Grid item xs={12}>
                        <CptTable  data={cptCodes} onRowSelected={onRowSelected}/>
                        </Grid>

                    </Grid>
                    {procedures?.id && 
                    <div style={sharedStyles?.mt10px}>
                        <HorizontalHeadingPlusText heading={'Selected Procedure'} text={`${procedures?.CptCode}`}/>
                    </div>
                    }
                    
                    </>
                )    
                }
                </Box>
{button && button}
        </Paper>
</>
    );
};

export default CommonProceduresForm;
