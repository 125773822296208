import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import CommonNextButton from '../../components/CommonNextButton';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, aetnaSrDefaultData, hsspReqDefaultErrors } from '../../../../../utils/staticData';
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext';

const RequestingProviderSshpForm = () => {
    const [errors, setErrors] = useState(hsspReqDefaultErrors);
    const {  requestingProvider: formData, setRequestingProvider: setFormData, setSelectedTab, onceCompleted } = useContext(SilverSummitHealtPlanHsContext)
    const [showAllFields, setShowAllFields] = useState(false);


    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const checkRequiredFields = () => {
        const requiredFields = ['fax','city','phone','zipCode','lastName','roleCode','stateCode','contactName','addressLine1','npi'];
        let hasErrors = false;
        const errors = requiredFields.reduce((acc, field) => {
            if (!formData?.[field]) {
                acc[field] = true;
                hasErrors = true;
            } else {
                acc[field] = false;
            }
            return acc;
        }, {});
        return { hasErrors, errors };
    };

    const validateAddressFields = () => {
        const errors = {};
        const { addressLine1, addressLine2, npi, city, zipCode, phone, extension, fax } = formData;

        if (addressLine1.length < 2) {
            errors.addressLine1 = 'Enter a valid mailing address from 2 to 55 characters in length.';
        } else {
            errors.addressLine1 = false;
        }

        if (addressLine2 && addressLine2.length < 2) {
            errors.addressLine2 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine2 = false;
        }

        if (npi?.length < 10) {
            errors.npi = 'Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.'
        }
        else {
            errors.npi = false
        }

        if (city.length < 2) {
            errors.city = 'Enter a valid city name from 2 to 30 characters in length.';
        } else {
            errors.city = false;
        }

            if (zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)) {
                errors.zipCode = "Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric."
            }
            else {
                errors.zipCode = false
            }

        if (phone?.length < 10) {
            errors.phone = 'Enter a valid phone number containing 10 numeric digits including area code.';
        } else {
            errors.phone = false;
        }

       
        if(fax?.length !== 10) {
            errors.fax = "Enter a valid fax number containing 10 numeric digits including area code."
        }
        else {
            errors.fax = false
        }

        return errors;
    };

    const handleSubmit = (submit) => {

        const { hasErrors: missingRequiredFields, errors: requiredErrors } = checkRequiredFields();
        setErrors((prev) => ({ ...prev, ...requiredErrors }));

        if (missingRequiredFields) {
            toast.error('Please enter all important information');
            return;
        }

        const addressErrors = validateAddressFields();
        setErrors((prev) => ({ ...prev, ...addressErrors }));

        if (!Object.values({ ...requiredErrors, ...addressErrors }).some(Boolean)) {
            // handleHsFormSubmit()
            submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab(prev => prev + 1)
        }
    };





    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Requesting Provider Information
            </Typography>
            <RadioGroup
                value={showAllFields}
                onClick={(event) => setShowAllFields(!showAllFields)}
            >
                <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
            </RadioGroup>
            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    {/* Last Name */}
                    
                    <Grid item xs={12} md={showAllFields ? 4 : 6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange('lastName')}
                            inputProps={{ maxLength: 60 }}
                            required
                            error={errors?.lastName}

                        />
                    </Grid>

                    {/* First Name */}
                    {showAllFields && <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange('firstName')}
                            inputProps={{ maxLength: 35 }}

                        />
                    </Grid>}

                   
                     <Grid item xs={12} md={showAllFields ? 4 : 6}>
                        <TextField
                            required
                            fullWidth
                            label="Contact Name"
                            value={formData.contactName}
                            onChange={handleChange('contactName')}
                            inputProps={{ maxLength: 60 }}
                            error={errors?.contactName}

                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            label="NPI"
                            value={formData.npi}
                            onChange={handleChange('npi')}
                            inputProps={{ maxLength: 10 }}
                            helperText='Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4'
                            error={errors?.npi}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            label=" Contact Fax"
                            value={formData.fax}
                            onChange={handleChange('fax')}
                            inputProps={{ maxLength: 10 }}
                            helperText="Enter a valid fax number containing 10 numeric digits including area code."
                            error={errors?.fax}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            label=" Contact Phone"
                            value={formData.phone}
                            onChange={handleChange('phone')}
                            inputProps={{ maxLength: 10 }}
                            error={errors?.phone}
                            helperText={errors?.phone}
                        />
                    </Grid>

                    
                    <>
                    <Grid item xs={12} md={showAllFields ?  6:12}>
                        <TextField
                            fullWidth
                            label=" Address Line 1"
                            value={formData.addressLine1}
                            onChange={handleChange('addressLine1')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine1}
                            helperText={errors?.addressLine1}
                            required

                        />
                    </Grid>
                    {showAllFields && <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label=" Address Line 2"
                            value={formData.addressLine2}
                            onChange={handleChange('addressLine2')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine2}
                            helperText={errors?.addressLine2}

                        />
                    </Grid>}
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            label=" City"
                            value={formData.city}
                            onChange={handleChange('city')}
                            inputProps={{ maxLength: 30 }}
                            error={errors?.city}
                            helperText={errors?.city}

                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth required
                        error={errors?.stateCode}
                        >
                            <InputLabel> State</InputLabel>
                            <Select
                                value={formData.stateCode}
                                label=" State"
                                onChange={handleChange('stateCode')}
                            >
                                {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                                    <MenuItem key={value} value={code}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label=" ZIP Code"
                            value={formData.zipCode}
                            onChange={handleChange('zipCode')}
                            inputProps={{ maxLength: 9 }}
                            error={errors?.zipCode}
                            helperText={errors?.zipCode}
                            required

                        />
                    </Grid>

                    {showAllFields && <>
                        <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label=" Tax ID"
                            value={formData.taxId}
                            onChange={handleChange('taxId')}
                            inputProps={{ maxLength: 9 }}
                            error={errors?.taxId}
                            helperText="Enter a valid  Tax ID."

                        />
                    </Grid>

                   
                    
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label=" Contact Extension"
                            value={formData.extension}
                            onChange={handleChange('extension')}
                            inputProps={{ maxLength: 8 }}
                            error={errors?.extension}
                            helperText={errors?.extension}
                        />
                    </Grid>
                    </>}
                    </>
                    


                    <Grid item xs={12} md={showAllFields ? 4 : 12}>
                        <FormControl fullWidth error={!!errors?.roleCode} required>
                            <InputLabel> Type</InputLabel>
                            <Select
                                value={formData.roleCode}
                                label="Type"
                                onChange={handleChange('roleCode')}
                                error={errors?.roleCode}
                            >
                                {aetnaDefaultData?.roleCodeVals?.map(({ value, code }) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
        </Paper>
    );
};

export default RequestingProviderSshpForm;
