import { createContext, useState } from "react";
import {  aetnaDefaultData, aetnaSrDefaultData, anthemScRequestingProviderDefault, hsSubscriberDefaultData, smplHsServiceDefault } from "../../utils/staticData";
const AnthemGaScContext = createContext();


const AnthemGaScContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        npi:'',
        city:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        firstName:'',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',
        payerAssignedProviderId:'',

     }]
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(aetnaSrDefaultData?.patientInfo)
    const [onceCompleted, setOnceCompleted] = useState(false)
    const [datesInfo, setDatesInfo] = useState(aetnaDefaultData?.datesInfo);
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [subscriber, setSubscriber] = useState(hsSubscriberDefaultData)
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceInfo, setServiceInfo] = useState(smplHsServiceDefault)
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [requestingProvider, setRequestingProvider] = useState(anthemScRequestingProviderDefault)


    return (
        <AnthemGaScContext.Provider value={{ 
            selectedTab, setSelectedTab,
            patient, setPatient,
            onceCompleted, setOnceCompleted,
            datesInfo, setDatesInfo,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            providerNotes, setProviderNotes,
            serviceInfo, setServiceInfo,
            renderingProviders, setRenderingProviders,
            defaultRenderingProvider,
            requestingProvider, setRequestingProvider
            }}>
            {children}
        </AnthemGaScContext.Provider>
    )
};


export { AnthemGaScContextProvider, AnthemGaScContext };