import { Button, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { sharedStyles } from '../../sharedStyles'

const CenteredSubmitCancel = ({ mt, onClick, disabled, loading, hideCancel }) => {
    return (
        <Grid
            container
            spacing={3}
            sx={sharedStyles?.horizontalCenteredFlex}
            mt={mt} >
            {!hideCancel && <Grid item>
                <Button label="Cancel" variant="outlined" color="inherit">
                    Cancel
                </Button>
            </Grid>}
            <Grid item>
                <Button
                    label="Search"
                    variant="contained"
                    color="success"
                    onClick={onClick}
                    disabled={disabled}
                >
                    {loading ? (
                        <CircularProgress size={23} color="inherit" />
                    ) : (
                        "Search"
                    )}
                </Button>
            </Grid>
        </Grid>
    )
}

export default CenteredSubmitCancel