import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import PageLoader from '../../../../loader/PageLoader';
import CommonNextButton from '../../components/CommonNextButton';
import { getAllCPTcodes } from '../../../../../services/pVerify';
import { AetnaSrContext } from '../../../../../context/priorAuthorizationForms/AetnaSrContext';
import ServiceInfoList from '../../components/ServicesInfoList';
import CptTable from '../../../../table/CptTable';
import { paFormErrors } from '../../../../../utils/staticData';

const ProcedureSrForm = () => {
    const [cptCodes, setCptCodes] = useState([]);
    const { procedure, setProcedure, setSelectedTab, onceCompleted } = useContext(AetnaSrContext)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const handleSubmit = (submit) => {
        const inCompleteProcedures = procedure?.filter(e => !e?.qualifierCode)
        if(inCompleteProcedures?.length){
            setErrors(procedure?.map(e => {
                return(
                    {
                        qualifierCode : e?.qualifierCode ? false : true
                    }
                )
            }))
            toast?.error(paFormErrors?.propInfo)
            return
        }
    submit  ? setSelectedTab(onceCompleted) :   setSelectedTab(prev => prev + 1)
    };


    const getCptCodes = async () => {
        try {
            setIsLoading(true)
            const data = await getAllCPTcodes();
            if (data) {
                setCptCodes(data?.data?.data)
            } else {
                setCptCodes([])
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCptCodes();
    }, [])

    const onRowSelected = (rowData) => {
        setProcedure(prevProcedures => {
            const isDuplicate = prevProcedures?.some(e => e?.id === rowData?.id);
    
            if (isDuplicate) {
                toast.error('This diagnosis is already selected');
                return prevProcedures; // No change
            }
    
            if (prevProcedures?.length < 5) {
                return [...prevProcedures, rowData]; // Add new item
            } else {
                toast.error('Select only up to 5 diagnoses');
                return prevProcedures; // No change
            }
        });
    };

    const handleOnRemove = (idToRemove) => {
        setProcedure((prevProcedures) => {
            const updated = prevProcedures.filter((item) => item.id !== idToRemove);
            if (updated.length !== prevProcedures.length) {
                // toast.success('Diagnosis removed successfully');
            }
            return updated;
        });
    };

    return (

        <>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Procedures Information
                </Typography>


                <Box sx={styles?.commonPaperContainer}>
                    {isLoading ?
                        <PageLoader />
                        :
                        (
                            <>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <CptTable data={cptCodes} onRowSelected={onRowSelected} />
                                    </Grid>

                                </Grid>
                                {procedure?.length ?
                                    <ServiceInfoList serviceInfoArray={procedure} onRemove={handleOnRemove} procedure 
                                    showQualifierCode
                                    errors={errors}
                                    setServiceInfoArray={setProcedure}
                                    /> : null

                                }

                            </>
                        )
                    }
                </Box>

                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
            </Paper>
        </>
    );
};

export default ProcedureSrForm;
