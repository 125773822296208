import React, { useContext } from 'react'
import CommonDiagnosesForm from '../../components/CommonDiagnosesForm'
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'
import CommonNextButton from '../../components/CommonNextButton'
import toast from 'react-hot-toast'

const DiagnosesCaForm = () => {
    const {diagnosesInfo, setDiagnosesInfo,setSelectedTab} = useContext(AnthemCaContext)

    const handleSubmit = () => {

        if (!diagnosesInfo) {
            toast.error('Please select 1 record')
            return
        };

        setSelectedTab((prev) => prev + 1)
    };

  return (
<>
<CommonDiagnosesForm
    serviceInfo={diagnosesInfo}
    setServiceInfo={setDiagnosesInfo}
    button={<CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab}/>}
    />

</>
  )
}

export default DiagnosesCaForm