
import React, { useContext, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import { styles } from './styles';
import { sharedStyles } from '../../../../sharedStyles';
import { aetnaDefaultData } from '../../../../utils/staticData';
import CommonNextButton from './CommonNextButton';

const CommonServiceInfoForm = ({setSelectedTab, serviceLevelCode, setServiceLevelCode,handleSubmit}) => {

    const handleChange = (event,) => {
        const { value } = event.target;
        setServiceLevelCode(value)
    };

    
    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service Level Code Info
            </Typography>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <FormControl fullWidth >
                            <InputLabel>Level Of Service</InputLabel>
                            <Select
                                value={serviceLevelCode}
                                label="Level Of Service"
                                onChange={handleChange}
                            >
                                {aetnaDefaultData?.serviceLevelCodeOptions?.map((item) => (
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.value}
                                    </MenuItem>
                                ))}
                            
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />
        </Paper>
    );
};

export default CommonServiceInfoForm;
