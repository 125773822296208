import React, { useContext, useState } from 'react'
import DatesInfoArForm from '../../components/Aetna/DefaultAetnaDatesInfoForm'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const DateInfoArForms = () => {
    const {setSelectedTab, datesInfo, setDatesInfo, onceCompleted} = useContext(AetnaArContext)
    const [dateLimits] = useState({max:'2025-06-23',min:'2024-06-27'})
  return (
    <DatesInfoArForm
    setFormData={setDatesInfo}
    formData={datesInfo}
    setSelectedTab={setSelectedTab}
    dateLimits={dateLimits}
    onceCompleted={onceCompleted}
    />
  )
}

export default DateInfoArForms