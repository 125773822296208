import React, { useContext } from 'react'
import DefaultHsPatientForm from '../../components/SilverSummitHealthPlanHs/DefaultHsPatientForm'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'

const PatientAnthemGaHsForm = () => {
    const {  setSelectedTab, patient, setPatient,onceCompleted} = useContext(AnthemGaHsContext)
  return (
    <DefaultHsPatientForm
    setSelectedTab={setSelectedTab} 
    formData={patient} 
    setFormData={setPatient} 
    noBack 
    importantFields={['lastName', 'firstName', 'birthDate']}
    fieldsToHide={['suffix']}
    dateLimits={{min:'1870-01-01',max:'2024-12-25'}}
    onceCompleted={onceCompleted}
/>

  )
}

export default PatientAnthemGaHsForm