import React, { useContext, useState } from 'react'
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext'
import {  getAllCPTcodes } from '../../../../../services/pVerify'
import DefaultAetnaProceduresForm from '../../components/Aetna/DefaultAetnaProceduresForm'
import { paFormErrors } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import DefaultAetnaExtendedProceduresForm from '../../components/Aetna/DefaultAetnaExtendedProceduresForm'

const ProceduresHsForm = () => {
    const { proceduresInfo, setProceduresInfo, setSelectedTab, onceCompleted } = useContext(AetnaHsContext)
      const [errors, setErrors] = useState([])

    const fetchData = async () => {
        const data = await getAllCPTcodes();
        return data?.data?.data || [];
    };

    const handleNext = (submit) => {
        
        if(proceduresInfo?.length ){
            const inCompleteProcedures = proceduresInfo?.filter(e => !e?.fromDate || !e?.quantity || !e?.quantityTypeCode || !e?.qualifierCode)
            if(inCompleteProcedures?.length){
                setErrors(proceduresInfo?.map(e => {
                    return(
                        {
                            fromDate: e?.fromDate ? false : true,
                            quantity: e?.quantity ? false : true,
                            quantityTypeCode : e?.quantityTypeCode ? false : true,
                            qualifierCode : e?.qualifierCode ? false : true
                        }
                    )
                }))
                toast?.error(paFormErrors?.propInfo)
                return
            }
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.proceduresImp)
        }
    };

    return (
        
        <DefaultAetnaExtendedProceduresForm
        title="Procedures Information"
        fetchData={fetchData}
        onRowSelected={(row) => setProceduresInfo((prev) => [...prev, row])}
        onRemove={(id) => setProceduresInfo((prev) => prev.filter((item) => item.id !== id))}
        selectedData={proceduresInfo}
        setSelectedData={setProceduresInfo}
        onNext={handleNext}
        maxSelections={5}
        setSelectedTab={setSelectedTab}
        onceCompleted={onceCompleted}
        errors={errors}
        showDates={{toDate:true,fromDate:true}}
        showServices={{serviceQuantity:true,serviceQuantityType:true}}
        showQualifierCode
        notReqFields={['toDate']}
    />
    )
}

export default ProceduresHsForm