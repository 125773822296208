import React, { useContext } from 'react'
import DefaultAetnaRequestingProvider from '../../components/Aetna/DefaultAetnaRequestingProviderForm'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const RequestingProviderArForm = () => {
    const {setSelectedTab,requestingProvider,setRequestingProvider, onceCompleted} = useContext(AetnaArContext)
  return (
    <DefaultAetnaRequestingProvider
    setFormData={setRequestingProvider}
    formData={requestingProvider}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />
  )
}

export default RequestingProviderArForm