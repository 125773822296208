import React, { useContext, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { styles } from '../styles';
import CommonNextButton from '../CommonNextButton';
import { sharedStyles } from '../../../../../sharedStyles';

const DatesInfoArForm = ({dateLimits,formData,setFormData,setSelectedTab, onceCompleted}) => {

    const [errors, setErrors] = useState({fromDate:false})
    const [showAllFields, setShowAllFields] = useState(false);


    const handleDateChange = (date,field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };


    const handleSubmit = (submit) => {
        if(!formData?.fromDate){
            toast.error('please enter required admission date to continue')
            setErrors((prev)=>({...prev,fromDate:true}))
            return
        }
        else {
            setErrors((prev) => ({ ...prev, fromDate: false }))
            submit?  setSelectedTab(onceCompleted) :  setSelectedTab((prev) => prev + 1)
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Dates Information
                </Typography>
                <RadioGroup
                            value={showAllFields}
                            onClick={(event) => setShowAllFields(!showAllFields)}
                        >
                            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                        </RadioGroup>
                <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3} mb={2}>
                  

                    {/* From Date */}
                    <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <DatePicker
                            label="Admission Date"
                            sx={errors?.fromDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                            value={formData.fromDate}
                            onChange={(date) => handleDateChange(date,'fromDate')}
                            minDate={dayjs(dateLimits?.min)}
                            maxDate={dayjs(dateLimits?.max)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    inputProps: { readOnly: true },
                                }
                            }}
                        />
                    </Grid>

                    {/* To Date */}
                   {showAllFields &&
                    <Grid item xs={12} md={6}>
                    <DatePicker
                        label="Discharge Date"
                        value={formData.toDate}
                        onChange={(date) => handleDateChange(date,'toDate')}
                        minDate={dayjs(dateLimits?.min)}
                        maxDate={dayjs(dateLimits?.max)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                inputProps: { readOnly: true },
                            }
                        }}
                    />
                </Grid>
                   }

                   

                </Grid>
                </Box>
                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
            </Paper>
            
        </LocalizationProvider>
    );
};

export default DatesInfoArForm;