import React, { useContext } from 'react'
import DefaultAetnaProviderNotesSrForm from '../../components/Aetna/DefaultAetnaProviderNotes'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'

const ProviderNotesAnthemGaScForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes, onceCompleted} = useContext(AnthemGaScContext)
  return (
    <DefaultAetnaProviderNotesSrForm
    setProviderNote={setProviderNotes}
    providerNote={providerNotes}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />)
}

export default ProviderNotesAnthemGaScForm