import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
} from '@mui/material';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { sharedStyles } from '../../../../../sharedStyles';
import { paFormErrors,  serviceTypeHsOptions, smplyHsPosOptions, smplyHsQuantityTypes, smplyHsServiceLevels } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';

const ImpServicesForm = ({errors, setErrors,setSelectedTab, serviceInfo, setServiceInfo, onceCompleted,fieldsToHide }) => {
    const hideServiceLevel = fieldsToHide?.includes('serviceLevel')
  

    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field !== 'quantity') {
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
        }
        else {
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
    };

    const handleSubmit = (submit) => {

        if (
            !serviceInfo?.quantity ||
            !serviceInfo?.serviceType ||
            !serviceInfo?.placeOfServiceCode ||
            (!hideServiceLevel && !serviceInfo?.serviceLevel) ||
            !serviceInfo?.quantityTypeCode
        ) {

            setErrors({
                quantity: !serviceInfo?.quantity ? paFormErrors?.reqField : false,
                serviceType: !serviceInfo?.serviceType ? paFormErrors?.reqField : false,
                placeOfServiceCode: !serviceInfo?.placeOfServiceCode ? paFormErrors?.reqField : false,
                serviceLevel: (!hideServiceLevel && !serviceInfo?.serviceLevel) ? paFormErrors?.reqField : false,
                serviceType: !serviceInfo?.serviceType ? paFormErrors?.reqField : false,
                quantityTypeCode: !serviceInfo?.quantityTypeCode ? paFormErrors?.reqField : false,
            });
            return
        }

        else {
            setErrors({
                quantity: false,
                serviceType: false,
                placeOfServiceCode: false,
                serviceLevel: false,
                quantityTypeCode: false
            });
            submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service & Quantity Type Information
            </Typography>


            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={!hideServiceLevel ? 6 : 12}>
                        <FormControl fullWidth required
                        error={errors?.placeOfServiceCode}
                        >
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                            >
                                {smplyHsPosOptions?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                   {!hideServiceLevel && <Grid item xs={12} md={6}>
                        <FormControl fullWidth required
                        error={errors?.serviceLevel}
                        >
                            <InputLabel>Service Level</InputLabel>
                            <Select
                                value={serviceInfo?.serviceLevel}
                                label="Service Level"
                                onChange={(e) => handleChange(e, 'serviceLevel')}
                            >
                                {smplyHsServiceLevels?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceLevel && errors?.serviceLevel}
                            </FormHelperText>
                        </FormControl>
                    </Grid>}

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required error={errors?.serviceType}>
                            <InputLabel>Service Type</InputLabel>
                            <Select
                                value={serviceInfo?.serviceType}
                                label="Service Type"
                                onChange={(e) => handleChange(e, 'serviceType')}
                                error={errors?.serviceType}
                            >
                                {serviceTypeHsOptions?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceType && errors?.serviceType}
                            </FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Quantity"
                            value={serviceInfo?.quantity}
                            onChange={(e) => handleChange(e, 'quantity')}
                            error={errors?.quantity}
                            helperText={errors?.quantity}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required
                        error={errors?.quantityTypeCode}
                        >
                            <InputLabel>Quantity Type</InputLabel>
                            <Select
                                value={serviceInfo?.quantityTypeCode}
                                label="Quantity Type"
                                onChange={(e) => handleChange(e, 'quantityTypeCode')}
                                error={errors?.quantityTypeCode}
                            >
                                {smplyHsQuantityTypes?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
};

export default ImpServicesForm;
