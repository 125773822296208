import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { sharedStyles } from '../../../../../sharedStyles';
import { posArFields, serviceTypeArFields } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext';

const PosQuantitiesArForm = () => {
    const [errors, setErrors] = useState({
        quantity: false,
        serviceType: false

    })
    const [showAllFields, setShowAllFields] = useState(false);
    const {setSelectedTab, serviceInfo, setServiceInfo, onceCompleted} = useContext(AetnaArContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field !== 'quantity') {
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
        }
        else {
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
    };

    const handleSubmit = (submit) => {

        if (!serviceInfo?.quantity || !serviceInfo?.serviceType) {

            setErrors({ 
                quantity: !serviceInfo?.quantity ? 'Required Field' : false,
                serviceType: !serviceInfo?.serviceType ? 'Required Field' : false
            });
            return
        }

        else {
            setErrors({ quantity: false, serviceType: false });
            submit ?  setSelectedTab(onceCompleted) :setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service & Quantity Type Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    {showAllFields && <Grid item xs={12} md={6}>
                        <FormControl fullWidth >
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                            >
                                {posArFields?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>}

                    <Grid item xs={12} md={!showAllFields ? 12 : 6}>
                        <FormControl fullWidth required error={errors?.serviceType}>
                            <InputLabel>Service Type</InputLabel>
                            <Select
                                value={serviceInfo?.serviceType}
                                label="Service Type"
                                onChange={(e) => handleChange(e, 'serviceType')}
                                error={errors?.serviceType}
                            >
                                {serviceTypeArFields?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceType && errors?.serviceType}
                            </FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Quantity"
                            value={serviceInfo?.quantity}
                            onChange={(e) => handleChange(e, 'quantity')}
                            error={errors?.quantity}
                            helperText={errors?.quantity}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required>
                            <InputLabel>Quantity Type</InputLabel>
                            <Select
                                value={serviceInfo?.quantityTypeCode}
                                label="Quantity Type"
                                onChange={(e) => handleChange(e, 'quantityTypeCode')}
                                error={errors?.quantityTypeCode}
                                disabled
                            >
                                    <MenuItem value={'DY'}>
                                        Days
                                    </MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
};

export default PosQuantitiesArForm;
