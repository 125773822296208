import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import PageLoader from '../../../../loader/PageLoader';
import CommonNextButton from '../CommonNextButton';

const DefaultAmerigroupDiagnosisForm = ({title = 'Diagnoses Information',
    fetchCodesApi,
    limit = 3,
    TableComponent,
    ListComponent,
    onSubmit,
    selectedItems,
    setSelectedItems,
    setSelectedTab,
    onceCompleted}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [codes, setCodes] = useState([])


    const fetchCodes = async () => {
        try {
            setIsLoading(true);
            const data = await fetchCodesApi();
            setCodes(data?.data?.data || []);
        } catch (error) {
            console.error('Error fetching codes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddItem = (item) => {
        setSelectedItems((prevItems) => {
            const isDuplicate = prevItems.some((e) => e?.id === item?.id);
            if (isDuplicate) {
                toast.error('This diagnosis is already selected');
                return prevItems;
            }
            if (prevItems.length < limit) {
                return [...prevItems, item];
            } else {
                toast.error(`Select only up to ${limit} diagnoses`);
                return prevItems;
            }
        });
    };

       const handleRemoveItem = (id) => {
            setSelectedItems((prevItems) => {
                const updated = prevItems.filter((item) => item.id !== id);
                if (updated.length !== prevItems.length) {
                    // toast.success('Diagnosis removed successfully');
                }
                return updated;
            });
        };
    
        useEffect(() => {
            fetchCodes();
        }, []);

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
              {title}
          </Typography>
          <Box sx={styles?.commonPaperContainer}>
              {isLoading ? (
                  <PageLoader />
              ) : (
                  <>
                      <Grid container spacing={3}>
                          <Grid item xs={12}>
                              {TableComponent && (
                                  <TableComponent data={codes} onRowSelected={handleAddItem} />
                              )}
                          </Grid>
                      </Grid>
                      {selectedItems?.length > 0 && ListComponent && (
                          <ListComponent items={selectedItems} onRemove={handleRemoveItem} />
                      )}
                  </>
              )}
          </Box>
          <CommonNextButton handleSubmit={onSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
      </Paper>
    )
}

export default DefaultAmerigroupDiagnosisForm
