import React, { useContext } from 'react'
import DefaultAetnaPatientForm from '../../components/Aetna/DefaultAetnaPatientForm'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const PatientArForm = () => {
    const {patient, setPatient, setSelectedTab, onceCompleted} = useContext(AetnaArContext)
  return (
    <DefaultAetnaPatientForm
    noBack
    setSelectedTab={setSelectedTab}
    setFormData={setPatient}
    formData={patient}
    onceCompleted={onceCompleted}
    />
  )
}

export default PatientArForm