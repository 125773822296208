import React, { useContext } from 'react'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'
import DefaultAmerigroupSubscriberForm from '../../components/Amerigroup/DefaultAmerigroupSubscriberForm'

const AmerigroupSubscribersForm = () => {
    const {setSelectedTab, subscriberInfo, setSubscriberInfo, onceCompleted} = useContext(AmerigroupContext)
  
  return (
    <DefaultAmerigroupSubscriberForm
        setSelectedTab={setSelectedTab}
        subscriberInfo={subscriberInfo}
        setSubscriberInfo={setSubscriberInfo}
        onceCompleted={onceCompleted}
    />
  )
}

export default AmerigroupSubscribersForm
