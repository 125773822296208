import { createContext, useState } from "react";
import { aetnaDefaultData, aetnaHsRequestingForm, aetnaSrDefaultData, placeOfServiceCodesAndQuantitiesAr } from "../../utils/staticData";
;

const AetnaArContext = createContext();


const AetnaArContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        firstName:'',
        lastName:'',
        contactName:'',
        city:'',
        roleCode:'Attending Physician',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',
        phone:'',
        zipCode:'',
        npi:''
      }]
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(aetnaSrDefaultData?.patientInfo)
    const [subscriber, setSubscriber] = useState(aetnaDefaultData?.subscriberInfo)
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [datesInfo, setDatesInfo] = useState(aetnaDefaultData?.datesInfo)
    const [serviceInfo, setServiceInfo] = useState(placeOfServiceCodesAndQuantitiesAr)
    const [providerNotes, setProviderNotes] = useState('')
    const [admissionInfo, setAdmissionInfo] = useState({type:'',source:''})
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [requestingProvider,setRequestingProvider] = useState(aetnaHsRequestingForm)
    const [additionalServiceInfo, setAdditionalServiceInfo] = useState({type:[],nursingCode:''})
    const [onceCompleted, setOnceCompleted] = useState(false)
   

    const handleArFormSubmit = async() => {
        console.log('submit')
    }
   
    return (
        <AetnaArContext.Provider value={{ 
            selectedTab, setSelectedTab,
            patient, setPatient,
            subscriber, setSubscriber,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            datesInfo, setDatesInfo,
            serviceInfo, setServiceInfo,
            providerNotes, setProviderNotes,
            admissionInfo, setAdmissionInfo,
            renderingProviders, setRenderingProviders,
            defaultRenderingProvider,
            requestingProvider,setRequestingProvider,
            additionalServiceInfo, setAdditionalServiceInfo,
            handleArFormSubmit,
            onceCompleted, setOnceCompleted
            }}>
            {children}
        </AetnaArContext.Provider>
    )
};


export { AetnaArContextProvider, AetnaArContext };