import React, { useEffect, useState } from 'react';
import {  AgGridReact } from 'ag-grid-react';
import { capitalizedFirstLetter, failedApiReq } from '../../utils/reusableFunctions';
import {  get_GetAllInsuranceDiscoveryDataPaginated } from '../../services/registration';
import { phoneCellRenderer } from '../../components/GridComponents/GridComponents';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styles } from './styles';
import { sharedStyles } from '../../sharedStyles';
import { dayJsMdyFormat } from '../../utils/DateFunctions';

const InsuranceDiscoveryGrid = ({openEditor,pagename,refetch}) => {
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const navigate = useNavigate();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: async(params) => {
          const page = params.endRow / perPage;
          try {
            gridApi.showLoadingOverlay();

            // Fetching the data
            const data = await get_GetAllInsuranceDiscoveryDataPaginated(perPage, page);

            // Processing the data
            const dataArray = data?.insuranceDiscoveryDtosList?.map((element) => {
              // Handling multiple payer responses if comboPayerResponses exist
              const statuses = element?.comboPayerResponses?.map(response => response?.status || '') || [];
              const payerNames = element?.comboPayerResponses?.map(response => response?.payerName || 'N/A') || [];

              // If no comboPayerResponses exist, fallback to using the single payerName field
              const combinedPayerNames = payerNames.length > 0
                ? payerNames.join(' / ')
                : element?.payerName || 'No Insurance Found';

              return {
                ID: element?.patientId,
                Transactiondate: dayJsMdyFormat(element?.transactionDate)|| 'N/A',
                Patient: `${capitalizedFirstLetter(element?.patientData?.firstName)} ${capitalizedFirstLetter(element?.patientData?.lastName)}`,
                Phone: element?.patientData?.phone || 'N/A',
                Insurance: combinedPayerNames,
                // Status: statuses.length > 0
                //   ? statuses.join(' / ')
                //   : (element?.planCoverageSummary?.status || 'No Status Found'),
                Status:element?.searchStatus || 'No Status Found',
                Requestid: element?.requestID || 'N/A',
                completeData: {
                  ...element?.patientData,
                  payerName: combinedPayerNames,
                },
                TableId:element?.id
              };
            });

            // Pass processed data to the successCallback
            params.successCallback(dataArray, data.count);
          }
          catch(e){
            params.successCallback([], 0);
            failedApiReq(e)
          }
          finally{
            gridApi.hideOverlay();
          }
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi,pagename,refetch]);
  
  const statusCellRenderer = (params) => {
    const statusValue = params.value;
    return <span style={styles?.statusStyle}>{statusValue}</span>;
  };

  const DetailsButton = (params) => {
   if(params?.data){
    return(
      <Button
        className='primary'
        style={styles?.detailsButton}
        onClick={() => handleButtonClick(params.data)}
      >
        View Details
      </Button>
    )}
    else return <div></div>
  };
  const handleButtonClick = (data) => {
    navigate({
      pathname: '/Discoverydetails1',
      search: `?rowData=${encodeURIComponent(JSON.stringify(data))}`,
    });
  };

  const columnDefs = [
    // { headerName: 'ID#', field: 'ID' },
    { headerName: 'Transaction Date', field: 'Transactiondate', sortable: true, filter: true,width:163,resizable:false },
    { headerName: 'Patient', field: 'Patient',sortable: true, filter: true,resizable:false },
    { headerName: 'Phone', field: 'Phone',sortable: true, filter: true, cellRenderer:(params) => phoneCellRenderer(params,()=>openEditor({...params?.data?.completeData,Requestid:params?.data?.Requestid})), width:123, resizable:false  },
    { headerName: 'Insurance', field: 'Insurance' , sortable: true, filter: true, width:174, resizable:false },
    { headerName: 'Status', field: 'Status', sortable: true, filter: true ,cellRenderer: statusCellRenderer,width:92, resizable:false },
    { headerName: 'Details', field: 'Details', filter: true, cellRenderer: DetailsButton,flex:1,minWidth:143, resizable:false },
  ]



  return (
    <div >
      <div className="ag-theme-quartz"
      style={sharedStyles?.h500}  >
       <AgGridReact
          pagination={true}
          rowModelType={'infinite'}
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          rowHeight={60}
          defaultColDef={{ 
            // width:236,
            flex:1,
            minWidth:170
           }}
          columnDefs={columnDefs}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
          overlayNoRowsTemplate={'<span class="ag-overlay-no-rows-center">No Data Found</span>'} 
        />
      </div>
    </div>
  );
}

export default InsuranceDiscoveryGrid;
