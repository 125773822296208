import React, { useContext } from 'react'
import CommonAnthemScRenderingProviders from '../../components/RenderingProviders/CommonAnthemScRenderingProvider'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'

const RenderingProvidersAnthemGaScForm = () => {
  const {
    renderingProviders, 
    setRenderingProviders,
    defaultRenderingProvider,
    setSelectedTab,
    onceCompleted
  } = useContext(AnthemGaScContext)
  return (
    <CommonAnthemScRenderingProviders
    renderingProviders = {renderingProviders}
    setRenderingProviders = {setRenderingProviders}
    setSelectedTab = {setSelectedTab}
    defaultRenderingProvider = {defaultRenderingProvider}
    onceCompleted = {onceCompleted}
    limit = {14}
    />
  )
}

export default RenderingProvidersAnthemGaScForm