import React from "react";
import { FormControl, TextField, CircularProgress, Autocomplete } from "@mui/material";

const DefaultSelect = ({
  label = "Select Option",
  options = [],
  value = null,
  loading = false,
  disabled = false,
  onChange = () => { },
  onScroll = () => { },
  styles = {},
  customOptionLabel = (option) => option,
  disabledOptions = [],
  error = false,
  color="success"

}) => {
  const renderOptions = (props, options) => {
    return (
      <li {...props} key={Math.random()}>
        {options}
      </li>
    )
  }
  return (
    <FormControl sx={styles} fullWidth>
      <Autocomplete
        loading={loading}
        disabled={disabled}
        options={options}
        getOptionLabel={customOptionLabel}
        getOptionDisabled={(option) => disabledOptions.includes(option)}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            label={label}
            variant="outlined"
            color={color}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={renderOptions}
        ListboxProps={{
          sx: styles?.listBox,
          onScroll: onScroll,
        }}
      />
    </FormControl>
  );
};

export default DefaultSelect;
