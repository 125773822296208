import React, { useContext } from 'react'
import {  getAllCPTcodes } from '../../../../../services/pVerify'
import DefaultAetnaProceduresForm from '../../components/Aetna/DefaultAetnaProceduresForm'
import { paFormErrors } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext'

const ProceduresSshpForm = () => {
    const { proceduresInfo, setProceduresInfo, setSelectedTab, onceCompleted } = useContext(SilverSummitHealtPlanHsContext)

    const fetchData = async () => {
        const data = await getAllCPTcodes();
        return data?.data?.data || [];
    };

    const handleNext = (submit) => {
        if(proceduresInfo?.length ){
            submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.proceduresImp)
        }
    };

    return (
        
        <DefaultAetnaProceduresForm
        title="Procedures Information"
        fetchData={fetchData}
        onRowSelected={(row) => setProceduresInfo((prev) => [...prev, row])}
        onRemove={(id) => setProceduresInfo((prev) => prev.filter((item) => item.id !== id))}
        selectedData={proceduresInfo}
        setSelectedData={setProceduresInfo}
        onNext={handleNext}
        maxSelections={99}
        setSelectedTab={setSelectedTab}
        onceCompleted={onceCompleted}
    />
    )
}

export default ProceduresSshpForm