import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import UseWidth from '../../custom-hooks/UseWidth';
import { sharedStyles } from '../../sharedStyles';


const CptTable = ({ data,onRowSelected }) => {
    const [rowData, setRowData] = useState(null);
    const { width } = UseWidth();


    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: true },
            { headerName: 'CPT Code', field: 'CptCode', filter: true, width: 200, resizable: true },
            {
                headerName: 'Full Description', field: 'fullDescription', filter: true, width: 400, resizable: true,
            },
            !onRowSelected && { headerName: 'Long Description', field: 'longDescription', width: 400, filter: true, resizable: true },
            { headerName: 'Short Description', field: 'shortDescription', width: 400, filter: true, resizable: true },
        ];
    }


    useEffect(() => {
        if (data) {
            const rowsData = data?.map(({ cptCode, fullDescription, longDescription, shortDescription, id }) => ({
                id: id || 'N/A',
                CptCode: cptCode,
                fullDescription: fullDescription || 'N/A',
                longDescription: longDescription || 'N/A',
                shortDescription: shortDescription || 'N/A'
            }))
            setRowData(rowsData);
        } else {
            setRowData([])
        }
    }, [data])

    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        onRowClicked: (event) => {
            if (onRowSelected) {
                onRowSelected(event.data); // Pass the row's data to the provided handler
            }
        }
    };

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate={'No CPT Codes Found!'}
            />
        </Box>
    )
}

export default CptTable
