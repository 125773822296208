import { Box, CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { sharedStyles } from '../../sharedStyles';
import {  allowedArPayers, defaultBasicPaDetailFields,  hsAllowedPayers, scAllowedPayers } from '../../utils/staticData';
import HorizontalBox from '../../components/shared/HorizontalBox'
import { fetchPayersData, getAllSubTypes, handleScroll, handleSelectedPayer } from './PaFunctions';
import DefaultSelect from '../../components/shared/DefaultSelect';
import AnthemCaForms from '../../components/eligibility/paForms/RxForms/AnthemCa/AnthemCaForms';
import AetnaArForms from '../../components/eligibility/paForms/arForms/Aetna/AetnaArForms';
import HsForms from './AvailityServiceReviewForms/HsForms';
import ArForms from './AvailityServiceReviewForms/ArForms';
import ScForms from './AvailityServiceReviewForms/ScForms';

const PaForm = () => {
    // states
    // const [patientSelected, setPatientSelected] = useState(null);
    const [allPayers, setAllPayers] = useState([])
    const [loaders, setLoaders] = useState({ payers: false,patientDetails:false,subType:false })
    const [selectedPayer, setSelectedPayer] = useState(null)
    const [patientBasicDetails, setPatientBasicDetails] = useState(defaultBasicPaDetailFields)
    const [formType, setFormType] = useState(null)
    const [page, setPage] = useState(1)
    const [allSubTypes, setAllSubTypes] = useState([])
    const [selectedSubType, setSelectedSubType] = useState(null)
    const [totalNumOfPayers, setTotalNumOfPayers] = useState(0)

    // useEffects
    useEffect(() => {
        getAllSubTypes(setAllSubTypes,setLoaders,loaders)
    }, [])

    useEffect(()=>{
        selectedSubType && fetchPayersData(page,setLoaders,setAllPayers,allPayers,loaders,selectedSubType?.split(' - ')?.[1],totalNumOfPayers, setTotalNumOfPayers)
    },[page,selectedSubType])

    useEffect(()=>{
       if(selectedSubType){ 
        setPage(1)
        setAllPayers([])
        setSelectedPayer(null)
        fetchPayersData(1,setLoaders,setAllPayers,allPayers,loaders,
            selectedSubType?.split(' - ')?.[1],
            totalNumOfPayers, setTotalNumOfPayers
        )}
        else {
            setSelectedPayer(null)
            setFormType(null)
        }
    },[selectedSubType])

    return (
        <Box>
            <Box>
                <form>
                    <Grid container spacing={2}>
                        {/* first row for sub types */}
                    <Grid item xs={6}>
                            <DefaultSelect
                                label="Insurance Sub Type"
                                options={allSubTypes?.map(({subTypeValue, subTypeId})=> `${subTypeValue} - ${subTypeId}`)}
                                value={selectedSubType}
                                loading={loaders?.subType}
                                disabled={!allSubTypes?.length}
                                onChange={(e, newValue) => setSelectedSubType(newValue ?? '')}
                                styles={sharedStyles?.mb2}
                                disabledOptions={allSubTypes
                                    ?.filter(({ subTypeId }) =>  subTypeId === "RX" )
                                    ?.map(({ subTypeValue, subTypeId }) => `${subTypeValue} - ${subTypeId}`)}
                            />
                        </Grid>

                        {/* second row for insurance */}
                        <Grid item xs={6}>
                            <DefaultSelect
                                label="Insurance Name"
                                options={allPayers?.map(e => e?.payerName)}
                                value={selectedPayer ??  null}
                                loading={loaders?.payers}
                                disabled={!allPayers?.length || !selectedSubType}
                                onChange={(e, newValue) => handleSelectedPayer(e, newValue, allPayers, setSelectedPayer, setFormType, setPatientBasicDetails)}
                                // onScroll={(e) => { handleScroll(e, setPage, page) }}
                                styles={sharedStyles?.mb2}
                                disabledOptions={
                                    selectedSubType?.includes("RX")
                                      ? allPayers
                                          ?.filter(elem => elem?.payerName?.toUpperCase() !== "ANTHEM - CA" && elem?.payerName?.toUpperCase() !== 'AETNA')
                                          ?.map(elem => elem.payerName)
                                      : 
                                      selectedSubType?.includes("HS")
                                      ? allPayers
                                          ?.filter(elem => !hsAllowedPayers?.includes(elem?.payerName?.toUpperCase()))
                                          ?.map(elem => elem.payerName)
                                      : 
                                      selectedSubType?.includes("SC")
                                      ? allPayers
                                          ?.filter(elem => !scAllowedPayers?.includes(elem?.payerName?.toUpperCase()))
                                          ?.map(elem => elem.payerName)
                                      : 
                                      selectedSubType?.includes("AR")
                                      ? allPayers
                                      ?.filter(elem => !allowedArPayers?.includes(elem?.payerName?.toUpperCase()))
                                      ?.map(elem => elem.payerName) 
                                      :
                                      null
                                  }
                            />
                        </Grid>
                        

                        
                        {
                            loaders?.patientDetails ?
                                <HorizontalBox sx={{ ...sharedStyles?.fullWidth, ...sharedStyles?.justifyCenter }} >
                                    <CircularProgress color='success' size={20} />
                                </HorizontalBox>
                                :
                                selectedSubType?.split(' - ')?.[1] === 'SC' && selectedPayer ?
                                <ScForms selectedPayer={selectedPayer}/> :
                                selectedSubType?.split(' - ')?.[1] === 'RX' && selectedPayer === 'ANTHEM - CA' ?
                                <AnthemCaForms /> 
                                :
                                selectedSubType?.split(' - ')?.[1] === 'HS' && selectedPayer ?
                                <HsForms selectedPayer={selectedPayer}/>
                                : 
                                selectedSubType?.split(' - ')?.[1] === 'AR' && selectedPayer ?
                                 <ArForms selectedPayer={selectedPayer}/> :
                                null
                        }
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default PaForm