import React, { useState } from 'react'
import PayersDetail from '../../components/table/PayersDetail';
import PayerDropdown from '../../components/PayerDropdown';

const PayerSearch = () => {
    const [fitlerSelection, setFilterSelection] = useState(0);



    return (
        <div>
            <PayerDropdown setter={setFilterSelection} state={fitlerSelection} />
            <PayersDetail filterSelection={fitlerSelection} />
        </div>
    )
}

export default PayerSearch
