import React, { useContext } from 'react'
import DefaultProviderNotesArrayForm from '../../components/DefaultProviderNotesArrayForm'
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext'

const ProviderNotesSshpForm = () => {
    const {  providerNotes, setProviderNotes, setSelectedTab, onceCompleted } = useContext(SilverSummitHealtPlanHsContext)
    return (
        <DefaultProviderNotesArrayForm
            setSelectedTab={setSelectedTab}
            providerNotes={providerNotes}
            setProviderNotes={setProviderNotes}
            maxFields={262}
            onceCompleted={onceCompleted}
        />
    )
}

export default ProviderNotesSshpForm