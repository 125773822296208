import React, { useContext } from 'react'
import { SmplyContext } from '../../../../../context/priorAuthorizationForms/SmplyContext'
import DefaultAdmissionForm from '../../components/Smply/DefaultAdmissionInfo'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'

const AdmissionAnthemGaHsForm = () => {
    const {setSelectedTab,  admissionInfo, setAdmissionInfo, onceCompleted, setOnceCompleted} = useContext(AnthemGaHsContext)
    
    const handleSubmit = (submit) => {
      submit ? setSelectedTab(onceCompleted):   setSelectedTab((prev) => prev + 1)
      setOnceCompleted(10)
    }

  return (
    <DefaultAdmissionForm
    setSelectedTab={setSelectedTab}
    admissionInfo={admissionInfo}
    setAdmssionInfo={setAdmissionInfo}
    onceCompleted={onceCompleted}
    handleSubmit={handleSubmit}
    />
  )
}

export default AdmissionAnthemGaHsForm