import { createContext, useState } from "react";
import { aetnaDefaultData, defaultPatientSmplyHs, hsSubscriberDefaultData, smplHsServiceDefault, smplyHsRpDefault, } from "../../utils/staticData";



const AnthemGaHsContext = createContext();


const AnthemGaHsContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        fax:'',
        npi:'',
        city:'',
        taxId:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        firstName:'',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',
        payerAssignedProviderId:'',

     }]

    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(defaultPatientSmplyHs)
    const [datesInfo, setDatesInfo] = useState(aetnaDefaultData?.datesInfo);
    const [onceCompleted, setOnceCompleted] = useState(false)
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [subscriber, setSubscriber] = useState(hsSubscriberDefaultData)
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceInfo, setServiceInfo] = useState(smplHsServiceDefault)
    const [requestingProvider, setRequestingProvider] = useState(smplyHsRpDefault)
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [admissionInfo, setAdmissionInfo] = useState('')


    return (
        <AnthemGaHsContext.Provider value={{
            selectedTab, setSelectedTab,
            patient, setPatient,
            datesInfo, setDatesInfo,
            onceCompleted, setOnceCompleted,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            providerNotes, setProviderNotes,
            serviceInfo, setServiceInfo,
            requestingProvider, setRequestingProvider,
            renderingProviders, setRenderingProviders,
            admissionInfo, setAdmissionInfo,
            defaultRenderingProvider
        }}>
            {children}
        </AnthemGaHsContext.Provider>
    )
};


export { AnthemGaHsContextProvider, AnthemGaHsContext };