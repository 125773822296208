import React from "react";
import {Box} from "@mui/material";
import MyTabs from "../../components/tabs/MyTabs";
import ClaimSearchresultdata from "../Claims/ClaimSearchresultdata";
import  "../../css/ClaimSearchStyles.css";
const Claimresults = () => {
  const tabs = [
    {
      label: "Search",
      value: <ClaimSearchresultdata />,
      changeTab: 0,
      button: "Back",
      backPath: '/ClaimTab?preSelectedTab=2'
    },
    // {
    //   label: "Patient Eligibility ",
    //   value: <disabled />,
    //   button: "New Search",
    //   changeTab: 0,
    // },
  ];

  return (
    <section className="claim">
      <Box className="customBox">
        <MyTabs tabs={tabs} />
      </Box>
    </section>
  );
};

export default Claimresults;
