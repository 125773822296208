
import React, { useContext, useEffect } from 'react'
import { AetnaSrContext, AetnaSrContextProvider } from '../../../../../context/priorAuthorizationForms/AetnaSrContext'
import PatientSrForm from './PatientSrForm'
import dayjs from 'dayjs'
import ServiceTypeForm from './ServiceTypeForm'
import DiagnoseSrForm from './DiagnoseSrForm'
import ProcedureSrForm from './ProceduresSrForm'
import SubscriberSrForm from './SubscriberSrForm'
import ProviderNotesSrForm from './ProviderNotesSrForm'
import POSQuantitiesSrForm from './POSQuantitesSrForm'
import RenderingProviderSrForm from './RenderingProviderSrForm'
import RequestingProvideSrForm from './RequestingProviderSrForm'
import FormDetails from '../../components/FormDetails'

const AetnaSrForms = ({ title }) => {
    return (
        <AetnaSrContextProvider>
            <AllAetnaForms title={title} />
        </AetnaSrContextProvider>
    )
}

export default AetnaSrForms

const AllAetnaForms = ({ title }) => {

    const { 
        selectedTab, 
        setPatient,
        patient,
        serviceType,
        diagnose,
        procedure,
        subscriberInfo,
        providerNote,
        serviceInfo,
        requestingProvider,
        renderingProvider,
        setSelectedTab,
        handleSrFormSubmit,
        isLoading
    } = useContext(AetnaSrContext);

   
    const forms = [
        <PatientSrForm />,
        <ProcedureSrForm />,
        <DiagnoseSrForm />,
        <SubscriberSrForm />,
        <ProviderNotesSrForm />,
        <POSQuantitiesSrForm />,
        <RenderingProviderSrForm />,
        <RequestingProvideSrForm />,
        <FormDetails data={{
                patient,
                serviceType,
                diagnose,
                procedure,
                subscriberInfo,
                providerNote:[providerNote],
                serviceInfo,
                requestingProvider,
                renderingProvider:[renderingProvider],
            }}
            setSelectedTab={setSelectedTab}
            onSubmit={handleSrFormSubmit}
            isLoading={isLoading}
            tabNums={{patient:0,diagnosesInfo:2, proceduresInfo:1,subscriberInfo:3,providerNotes:4,serviceInfo:5,renderingProviders:6,requestingProvider:7}}
            title={`for ${title} (SC)`}
            />
            
    ];

    return forms[selectedTab] || null;
};