import React, { useContext } from 'react'
import DefaultAetnaProviderNotesSrForm from '../../components/Aetna/DefaultAetnaProviderNotes'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'

const ProviderNotesAnthemGaHsForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes, onceCompleted} = useContext(AnthemGaHsContext)
  return (
    <DefaultAetnaProviderNotesSrForm
    setProviderNote={setProviderNotes}
    providerNote={providerNotes}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />)
}

export default ProviderNotesAnthemGaHsForm