import toast from "react-hot-toast"
import { get_GetPatientDataById } from "../../services/addPatient"
import { post_dentaleligibility } from "../../services/DentalEligibilityService"
import { get_payersdata } from "../../services/PayerService"
import { ymdDateFormat } from "../../utils/DateFunctions"
import { failedApiReq, failedGetApi, getLocalRequestNpi, getRequestNpi } from "../../utils/reusableFunctions"
import { validationErrorStr } from "../../utils/staticData"
import { checkEligibilityDetails, checkEligibilityDetailsFromDiscovery, post_eligibilitysearchdata } from "../../services/registration"

export const fetchPatientDetails = async (patientSelected,setSelectedPatient,setformDetails,formDetails) => {
    try{
        const patientData = await get_GetPatientDataById(patientSelected?.id)
        if (patientData?.patientInfoData) {
            const {
                id: patientid,
                firstName: PatientFirstName,
                lastName: LastName,
                dob,
                phone,
                patientInsuranceDetails: insuranceName,
                memberId,
            } = patientData?.patientInfoData
    
            const data = {
                patientid,
                PatientInsurancePayer: insuranceName ?? [],
                PatientFirstName,
                LastName,
                dateofservice: ymdDateFormat(new Date()),
                dob,
                memberId,
                phone,
                NPI: getRequestNpi()
            }
            setSelectedPatient(data)
            setformDetails({...formDetails,...data})
        }
        else {
            failedGetApi()
        }   
    }
    catch{
        failedGetApi()
    }
}

export const PayersDropdown = async (setPayersData, setPayersLoader) => {
    try {
        setPayersLoader(true)
        const payersdata = await get_payersdata();
        if (payersdata) {
            setPayersData(payersdata?.map(elem => elem?.payerName))
        }
    }
    catch {
        failedGetApi()
    }
    finally{
        setPayersLoader(false)
    }
};

export const handleUserEligibilitySearch = async(updatedDetails,navigate) => {
    try{
        const postsearchdata = await post_dentaleligibility(updatedDetails);
        if(postsearchdata){
            navigate("/DentalSearchresults", { state: { searchData: postsearchdata??"" } });
        }
        else{failedApiReq('No Data')}
    }
    catch(e){
        failedApiReq(e)
    }
}

export const fetchPatientFormDetails = async (patientSelected,setSelectedPatient,setformDetails,formDetails) => {
    try{
        const patientData = await get_GetPatientDataById(patientSelected?.id)
       
        if (patientData?.data) {
            const {
                id: patientid,
                firstName: patientFirstName,
                lastName: patientLastName,
                middleName,
                dob:patientDOB,
                phone:phoneNumber,
                patientInsuranceDetails,
                memberId:patientMemberId,
                email,
                ssn:socialSecurityNumber,
                transactionDate,
                dateOfService,
                isSubscriberPatient
            } = patientData?.data
            const data = {
                patientid,
                patientFirstName,
                patientLastName,
                patientMiddleName: middleName,
                patientDOB,
                patientMemberId: patientMemberId,
                phoneNumber:phoneNumber ?? '',
                email: email ?? '',
                insurancename:patientInsuranceDetails?.length  ? patientInsuranceDetails  : '',
                socialSecurityNumber,
                transactionDate,
                dateOfService: dateOfService ? ymdDateFormat(dateOfService): ymdDateFormat(new Date()),
                isSubscriberPatient: isSubscriberPatient ?? "true",
                providerNpiNumber: getLocalRequestNpi(),
            }
        
            setSelectedPatient(data)
            setformDetails({...formDetails,...data})
        }
        else {
            failedGetApi()
        }   
    }
    catch{
        failedGetApi()
    }
}

export const handleEmailError = () => {
    toast.error(validationErrorStr)
    console.log(validationErrorStr);
}

export const getEligibilityRequestId = async (data,fetchData) => {
    try {
        const eligibilityData = await post_eligibilitysearchdata(data)
        if(eligibilityData?.eligibilityDto?.requestID){
            fetchData(eligibilityData?.eligibilityDto?.requestID);
        }
        else{
            failedGetApi()
        }
    }
    catch (e) {
        failedGetApi(e)
    }
}

export const getCheckEligibilityDetails = async(requestid, patientid, transactionDate,setSearchData,setEligibilityData) =>{
  try{const data = await  checkEligibilityDetailsFromDiscovery(requestid, patientid, transactionDate)
  if(data?.eligibilityDto){
    setEligibilityData(data?.eligibilityDto)
    const deductibleData = data?.eligibilityDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet?.value || data?.eligibilityDto?.hbpC_Deductible_OOP_Summary?.individualDeductibleRemainingInNet;
    const memberId = data?.eligibilityDto?.miscellaneousInfoSummary?.memberID
    const requestId = data?.eligibilityDto?.requestID ;
    const effectiveDate = data?.eligibilityDto?.planCoverageSummary?.effectiveDate
    const policyType = data?.eligibilityDto?.patientData?.planDetails?.policyType
    const isProviderInNetwork = data?.eligibilityDto?.isProviderInNetwork
    setSearchData({
        deductibleData,
        requestId,
        memberId,
        effectiveDate,
        policyType,
        isProviderInNetwork
    })
  }
  else{
    failedGetApi()
  }}
  catch(e){
    failedGetApi(e)
  }
}