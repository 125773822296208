import React, { useContext } from 'react'
import DefaultAmerigroupPatientForm from '../../components/Amerigroup/DefaultAmerigroupPatientForm'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'

const AmeriGroupPatientForm = () => {
    const { patientInfo, setPatientInfo, onceCompleted, setSelectedTab, noBack } = useContext(AmerigroupContext)

    return (
        <DefaultAmerigroupPatientForm
            formData={patientInfo}
            setFormData={setPatientInfo}
            onceCompleted={onceCompleted}
            setSelectedTab={setSelectedTab}
            noBack={noBack}
        />
    )
}

export default AmeriGroupPatientForm
