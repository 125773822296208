import { createContext, useState } from "react";
import {  aetnaDefaultData, anthemCaDefaultData, defaultPaPayload } from "../../utils/staticData";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { submitPaForm, submitPriorAuthAvailityForm } from "../../services/PayerService";


const AnthemCaContext = createContext();


const AnthemCaContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        fax:'',
        city:'',
        phone:'',
        taxId:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        extension:'',
        firstName:'',
        stateCode:'',
        middleName:'',
        addressLine1:'',
        addressLine2:'',
        licenseNumber:'',
        licenseStateCode:''
      
      }]
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [requestingProvider, setRequestingProvider] = useState(anthemCaDefaultData?.requestingProvider)
    const [subscriber, setSubscriber] = useState(anthemCaDefaultData?.subscriber)
    const [patient, setPatient] = useState(anthemCaDefaultData?.patient)
    const [serviceInfo, setServiceInfo] = useState(aetnaDefaultData?.placeOfServiceCodesAndQuantities)
    const [datesInfo, setDatesInfo] = useState(anthemCaDefaultData?.datesInfo)
    const [diagnosesInfo, setDiagnosesInfo] = useState(null)
    const [proceduresInfo, setProceduresInfo] = useState(null)
    const [prescriptions, setPrescriptions] = useState(anthemCaDefaultData?.prescriptions)
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceLevelCode, setServiceLevelCode] = useState(null)
    const [renderingProviders,setRenderingProviders] = useState(defaultRenderingProvider)

    const handleSrFormSubmit = async() => {
     
     const payload = {
            ...defaultPaPayload,
            ToDate: ymdDateFormat(datesInfo?.toDate) ?? null,
            FromDate: ymdDateFormat(datesInfo?.fromDate) ?? null,
            Patient:{
                City:patient?.city,
                Height:patient?.height,
                Weight: patient?.weight ,
                ZipCode:patient?.zipCode,
                LastName:patient?.lastName,
                FirstName:patient?.firstName,
                HomePhone:patient?.homePhone,
                StateCode:patient?.stateCode,
                WorkPhone:patient?.workPhone,
                GenderCode:patient?.genderCode,
                AddressLine1:patient?.addressLine1,
                AddressLine2:patient?.addressLine2,
                SubscriberRelationshipCode:patient?.subscriberRelationshipCode
            },
            Quantity:serviceInfo?.quantity,
            ICDCodeId:diagnosesInfo?.id,
            CPTCodeId:proceduresInfo?.id,
            Subscriber:{
                LastName: subscriber?.lastName,
                MemberId: subscriber?.memberId,
                FirstName: subscriber?.firstName,
                GroupName: subscriber?.groupName,
                GroupNumber: subscriber?.groupNumber
            },
            Prescriptions:[
                { 
                    DateInitiated:ymdDateFormat(prescriptions?.dateInitiated) ?? null,
                    DateOfLastDose:ymdDateFormat(prescriptions?.dateOfLastDose) ?? null,
                    Note:prescriptions?.note,
                    Dosage:prescriptions?.dosage,
                    Frequency: prescriptions?.frequencey,
                    Medication:prescriptions?.medication,
                    NewPrescription: prescriptions?.newPrescription
                }
            ],
            ProviderNotes:[
                {Message:providerNotes}
            ],
            RequestTypeCode:'RX',
            QuantityTypeCoce:serviceInfo?.quantityTypeCode,
            ServiceLevelCode:serviceLevelCode,
            PlaceOfServiceCode:serviceInfo?.placeOfServiceCode,
            RenderingProviders:renderingProviders?.map(elem => {
                return({
                    Fax:elem?.fax,
                    City:elem?.city,
                    Phone:elem?.phone,
                    TaxId:elem?.taxId,
                    ZipCode:elem?.zipCode,
                    LastName:elem?.lastName,
                    RoleCode:elem?.roleCode,
                    Extension:elem?.extension,
                    FirstName:elem?.firstName,
                    StateCode:elem?.stateCode,
                    MiddleName:elem?.middleName,
                    AddressLine1:elem?.addressLine1,
                    AddressLine2:elem?.addressLine2,
                    LicenseNumber: elem?.licenseNumber,
                    LicenseStateCode: elem?.licenseStateCode
                })
            }),
            RequestingProvider:{
                Fax:requestingProvider?.fax,
                Npi:requestingProvider?.npi,
                City:requestingProvider?.city,
                Phone:requestingProvider?.phone,
                TaxId:requestingProvider?.taxId,
                ZipCode:requestingProvider?.zipCode,
                LastName:requestingProvider?.lastName,
                RoleCode:requestingProvider?.roleCode,
                Extension:requestingProvider?.extension,
                FirstName:requestingProvider?.firstName,
                StateCode:requestingProvider?.stateCode,
                ContactName:requestingProvider?.contactName,
                AddressLine1:requestingProvider?.addressLine1,
                AddressLine2:requestingProvider?.addressLine2,
                EmailAddress:requestingProvider?.emailAddress,
                LicenseNumber: requestingProvider?.licenseNumber,
                LicenseStateCode: requestingProvider?.licenseStateCode

            },

        }
        console.log('--->>',payload)
        const postSubmission = await submitPriorAuthAvailityForm(payload)

    }
   
    return (
        <AnthemCaContext.Provider value={{ 
            selectedTab, setSelectedTab,
            requestingProvider, setRequestingProvider,
            subscriber, setSubscriber,
            patient, setPatient,
            serviceInfo, setServiceInfo,
            datesInfo, setDatesInfo,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            prescriptions, setPrescriptions,
            providerNotes, setProviderNotes,
            serviceLevelCode, setServiceLevelCode,
            renderingProviders,setRenderingProviders,
            defaultRenderingProvider,
            handleSrFormSubmit
            }}>
            {children}
        </AnthemCaContext.Provider>
    )
};


export { AnthemCaContextProvider, AnthemCaContext };