import React, { useContext, useState } from 'react'
import { Box, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { handleChangePaRegexs } from '../../handleChangeRegexes'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import CommonNextButton from '../../components/CommonNextButton'
import { memberIdRequiredStr, paFormErrors } from '../../../../../utils/staticData'
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

const PrescriptionCaForm = () => {

    const [errors, setErrors] = useState({ 
        medication: false, 
        dose: false, 
        frequency: false, 
        note: false
    })
    const { setSelectedTab, prescriptions, setPrescriptions, } = useContext(AnthemCaContext)
    const [showAllFields, setShowAllFields] = useState(false);

    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setPrescriptions((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        const {medication, dose, frequency, note} = prescriptions
        if (!medication || !dose || !frequency || !note) {
            toast.error(paFormErrors?.importantInfo)
            setErrors({ 
                medication:medication ? false : paFormErrors?.medication, 
                dose:dose  ? false : paFormErrors?.dosage, 
                frequency: frequency ? false : paFormErrors?.frequencey, 
                note:note ? false : paFormErrors?.note
             })
            return
        }
        else {
            setErrors({ 
                medication: false, 
                dose: false, 
                frequency: false, 
                note: false
            })
            setSelectedTab((prev) => prev + 1)
        }
    }

    const handleDateChange = (date, field) => {
        setPrescriptions({
            ...prescriptions,
            [field]: date
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Prescription Information
                </Typography>

                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
                <Box sx={styles?.commonPaperContainer}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label="Medication"
                                value={prescriptions?.medication}
                                onChange={handleChange('medication')}
                                inputProps={{ maxLength: 100 }}
                                error={errors?.medication}
                                helperText={errors?.medication || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label="Dose"
                                value={prescriptions?.dose}
                                onChange={handleChange('dose')}
                                inputProps={{ maxLength: 50 }}
                                error={errors?.dose}
                                helperText={errors?.dose || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label="Frequency"
                                value={prescriptions?.frequency}
                                onChange={handleChange('frequency')}
                                inputProps={{ maxLength: 50 }}
                                error={errors?.frequency}
                                helperText={errors?.frequency || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label="Directions"
                                value={prescriptions?.note}
                                onChange={handleChange('note')}
                                inputProps={{ maxLength: 100 }}
                                error={errors?.note}
                                helperText={errors?.note || ''}
                            />
                        </Grid>
                        {
                        showAllFields &&
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Is this medication a New Start?"
                                    value={prescriptions?.newPrescription}
                                    onChange={handleChange('newPrescription')}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    label="Initiation Date"
                                    sx={errors?.dateInitiated ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                    value={prescriptions.dateInitiated}
                                    onChange={(date) => handleDateChange(date, 'dateInitiated')}
                                    minDate={dayjs('2002-01-01')}
                                    maxDate={dayjs('2024-12-25')}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    label="Date of Last Dose"
                                    sx={errors?.dateOfLastDose ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                    value={prescriptions.dateOfLastDose}
                                    onChange={(date) => handleDateChange(date, 'dateOfLastDose')}
                                    minDate={dayjs('2002-01-01')}
                                    maxDate={dayjs('2024-12-25')}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    }
                    </Grid>
                   
                </Box>

                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />
            </Paper>
        </LocalizationProvider>
    )
}

export default PrescriptionCaForm