import React, { useContext } from 'react'
import DefaultAetnaSubscribersForm from '../../components/Aetna/DefaultAetnaSubscribersForm'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const SubscriberArForm = () => {
    const {setSelectedTab, subscriber, setSubscriber, onceCompleted} = useContext(AetnaArContext)
  return (
    <DefaultAetnaSubscribersForm
    subscriberInfo={subscriber}
    setSubscriberInfo={setSubscriber}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />
  )
}

export default SubscriberArForm