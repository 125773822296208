import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { getServiceReviewsResponse } from '../../services/eligibility';
import { paDetails } from '../../utils/staticData';
import { dayJsMdyFormatSlash } from '../../utils/DateFunctions';
import { sharedStyles } from '../../sharedStyles';

const PaDetails = () => {
  // router params
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  // Access individual parameters
  const sr = searchParams.get("sr");
  const pId = searchParams.get("pId");
  const tDate = searchParams.get("tDate");
  // Sample data
  const [data, setData] = useState({})

  const getServiceReview = async () => {
    const review = await getServiceReviewsResponse(sr, pId, tDate)
    if (review?.error) {
      setData(paDetails)
    }
    else setData(review?.data)
  }
  useEffect(() => {
    getServiceReview()
  }, [])


  return (
    <div style={{ padding: '20px', width: '100%', margin: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <div>
            <Typography variant="h6" fontSize={18}>
              Authorization Details
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ID: {data.id}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button variant="contained" color="success" onClick={() => window.location.reload()}>
            Refresh
          </Button>
          <Button variant="outlined" color='success'>Export</Button>
        </div>
      </div>

      {/* Info Cards Grid */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Status</Typography>
              <Typography variant="h6">{data?.status ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Payer</Typography>
              <Typography variant="h6">{data?.payer_name ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Network</Typography>
              <Typography variant="h6">{data?.network ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Member ID</Typography>
              <Typography variant="h6">{data?.subscriber?.memberID ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Patient Information */}
      <CardHeader title="Patient Information" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Full Name</Typography>
              <Typography>{`${data?.subscriber?.firstName ?? ''} ${data?.subscriber?.lastName ?? ''}`}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Date of Birth</Typography>
              <Typography>{data?.subscriber?.dob ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={sharedStyles?.detaislCard}>
            <CardContent>
              <Typography color="textSecondary">Location</Typography>
              <Typography>{data?.subscriber?.address ?? 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* History Table */}
      <Card style={{ marginTop: '20px' }}>
        <CardHeader title="History" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!data?.history?.length && data?.history?.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>{dayJsMdyFormatSlash(record?.logTime)}</TableCell>
                    <TableCell>{record.actionType}</TableCell>
                    <TableCell>{record.tranStatus}</TableCell>
                    <TableCell>{record.notes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default PaDetails;
