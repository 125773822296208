import toast from "react-hot-toast"
import { get_payersdata, get_payersdataBySubId, getAllPayerSubTypes } from "../../services/PayerService"
import { basicPaPayerIds, defaultBasicPaDetailFields } from "../../utils/staticData"
import { get_GetPatientDataById } from "../../services/addPatient"
import { filterSupportedObjects } from "../../utils/reusableFunctions"

 // fetching payers data
 export const fetchPayersData = async (page,setLoaders,setAllPayers,allPayers,loaders,selectedSubType, totalNumOfPayers, setTotalNumOfPayers) => {
  
  if (
    !selectedSubType || 
    loaders?.payers 
  ) {
    return;
  }
     try {
        setLoaders({ ...loaders, payers: true })
        const payersData = await get_payersdataBySubId(null, null,selectedSubType) 
        if (payersData?.data?.length) {
            const loadedPayers = payersData?.data
            setAllPayers(page > 1 ? allPayers?.concat(loadedPayers) : loadedPayers)
            setTotalNumOfPayers(payersData?.totalCount)
        }
    }
    catch (e) {
        console.log(e)
    }
    finally {
        setLoaders({ ...loaders, payers: false })
    }
}

// set selected payer
export const handleSelectedPayer = (event, newValue,allPayers,setSelectedPayer,setFormType,setPatientBasicDetails) => {
    if(newValue){
        setSelectedPayer(newValue)
        setFormType('availityRx')
        // setFormType('availity')
    
    }
    else {
        removePayerInfo(setPatientBasicDetails,setSelectedPayer,setFormType)
    }
  };

// handling basic details of user being changed
export const handleBasicDetailChange = (e,setPatientBasicDetails) => {
    const { name, value } = e.target;
    setPatientBasicDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value, 
    }));
  };

// getting selected patient's complete detail
export const getPatientDetails = async(patientSelected,setLoaders,loaders,setPatientBasicDetails,patientBasicDetails,setSelectedPayer,setFormType)=>{
    if(patientSelected?.id){
        removePayerInfo(setPatientBasicDetails,setSelectedPayer,setFormType)
       try{
        setLoaders({...loaders,patientDetails:true})
        const {data:{firstName,middleName,lastName,dob,memberId,patientInsuranceDetails,id}} = await get_GetPatientDataById(patientSelected?.id)
           setPatientBasicDetails({
               ...patientBasicDetails,
               firstName,
               middleName,
               lastName,
               dob,
               memberId,
               id
           })
        setSelectedPayer(patientInsuranceDetails?.[0])
        setFormType('availityRx')
       }
       catch(e){
        console.log(e)
       }
       finally{
        setLoaders({...loaders,patientDetails:false})
       }
    }
}

// remove payer info
export const removePayerInfo = (setPatientBasicDetails,setSelectedPayer,setFormType) => {
        setSelectedPayer(null)
        setPatientBasicDetails(defaultBasicPaDetailFields)
        setFormType(null)
}

// pagination on scroll
export const handleScroll = (event, setPage, page, loaders) => {
    const listboxNode = event.currentTarget;
  
    if (!loaders?.payers) {
      const nearBottom =
        listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5; // Adding a small buffer
      if (nearBottom) {
        setPage(page + 1);
      }
    }
  };

export const getAllSubTypes = async (setAllSubTypes, setLoaders) => {
    try {
        setLoaders((prev) => {
            return { ...prev, subType: true };
          });
        const allSubTypes = await getAllPayerSubTypes()
        setAllSubTypes(allSubTypes ?? [])
    }
    catch (e) {
        console.log('error fetching subTypes', e)
    }
    finally {
        setLoaders((prev) => {
            return { ...prev, subType: false };
          });
    }
}