import React, { useContext } from 'react'
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'
import CommonServiceInfoForm from '../../components/CommonServiceInfoForm'

const ServiceLevelCaForm = () => {
    const {setSelectedTab, serviceLevelCode, setServiceLevelCode} = useContext(AnthemCaContext)
    const handleSubmit = () => {
        setSelectedTab((prev) => prev + 1)
    };

  return (
    <CommonServiceInfoForm
    setSelectedTab={setSelectedTab}
    serviceLevelCode={serviceLevelCode}
    setServiceLevelCode={setServiceLevelCode}
    handleSubmit={handleSubmit}
    />
  )
}

export default ServiceLevelCaForm