import { createContext, useState } from "react";
import { aetnaDefaultData, aetnaSrDefaultData } from "../../utils/staticData";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { submitPriorAuthAvailityForm } from "../../services/PayerService";
import toast from "react-hot-toast";
import { failedApiReq } from "../../utils/reusableFunctions";
;

const AetnaSrContext = createContext();


const AetnaSrContextProvider = ({ children }) => {
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [requestType, setRequestType] = useState(null);
    const [patient, setPatient] = useState(aetnaSrDefaultData?.patientInfo)
    const [serviceType, setServiceType] = useState(null)
    const [diagnose, setDiagnose] = useState([])
    const [procedure, setProcedure] = useState([])
    const [subscriberInfo, setSubscriberInfo] = useState(aetnaDefaultData?.subscriberInfo)
    const [providerNote, setProviderNote] = useState('')
    const [serviceInfo, setServiceInfo] = useState(aetnaDefaultData?.placeOfServiceCodesAndQuantities)
    const [requestingProvider, setRequestingProvider] = useState(aetnaSrDefaultData?.renderingProvider)
    const [renderingProvider,setRenderingProvider] = useState(aetnaSrDefaultData?.renderingProvider)
    const [isLoading, setIsLoading] = useState(false)
    const [onceCompleted, setOnceCompleted] = useState(false)

    const handleSrFormSubmit = async() => {
        try{
            setIsLoading(true)
        // console.log(
        //     {patient,
        //     serviceType,
        //     diagnose,
        //     procedure,
        //     subscriberInfo,
        //     providerNote,
        //     serviceInfo,
        //     requestingProvider,
        //     renderingProvider}
        // )
        const payload = {
            requiredFields:{
                patientFirstName:patient?.firstName || '',
                patientMiddleName:null,
                patienLastName:patient?.lastName || '',
                patientDoB: patient?.birthDate ? ymdDateFormat(patient?.birthDate) : null,

            },
            configFields:{
                patient:{
                    birthDate:patient?.birthDate ? ymdDateFormat(patient?.birthDate) : null,
                    ...(patient?.genderCode && {genderCode: aetnaDefaultData?.patientGenders?.find(e => e?.label === patient?.genderCode)?.value}),
                    ...(patient?.subscriberRelationshipCode && {subscriberRelationshipCode: aetnaDefaultData?.patientRelationToSubscriber?.find(e => e?.label === patient?.subscriberRelationshipCode)?.value}),
                    ...(patient?.suffix && {suffix: patient?.suffix}),
                    ...(patient?.lastName && {lastName: patient?.lastName}),
                    ...(patient?.firstName && {firstName: patient?.firstName}),
                },
                ...(serviceInfo?.quantity && {quanitity: serviceInfo?.quantity}),
                icdCodeIds:diagnose?.length ? diagnose?.map(elem => elem?.id) : null,
                cptCodeIds:procedure?.length ? procedure?.map(elem => elem?.id) : null,
                subscriber:{
                    ...(subscriberInfo?.suffix && {suffix: subscriberInfo?.suffix}),
                    ...(subscriberInfo?.lastName && {lastName: subscriberInfo?.lastName}),
                    ...(subscriberInfo?.firstName && {firstName: subscriberInfo?.firstName}),
                    ...(subscriberInfo?.suffix && {suffix: subscriberInfo?.suffix}),
                    memberId:subscriberInfo?.memberId
                },
                providerNotes:[providerNote],
                requestTypeCode:'SC',
                serviceTypeCode:aetnaSrDefaultData?.serviceTypes?.find(e => e?.value === serviceType)?.code,
                quantityTypeCode: serviceInfo?.quantityTypeCode ? 'VS' : null,
                placeOfServiceCode:serviceInfo?.placeOfServiceCode ? aetnaSrDefaultData?.placeOfServiceCode?.find(e => e?.value === serviceInfo?.placeOfServiceCode)?.code : null,
                renderingProviders:[{
                    ...(renderingProvider?.npi && {npi: renderingProvider?.npi}),
                    ...(renderingProvider?.city && {city: renderingProvider?.city}),
                    ...(renderingProvider?.phone && {phone: renderingProvider?.phone}),
                    ...(renderingProvider?.zipCode && {zipCode: renderingProvider?.zipCode}),
                    ...(renderingProvider?.lastName && {lastName: renderingProvider?.lastName}),
                    ...(renderingProvider?.extension && {extension: renderingProvider?.extension}),
                    ...(renderingProvider?.firstName && {firstName: renderingProvider?.firstName}),
                    ...(renderingProvider?.addressLine1 && {addressLine1: renderingProvider?.addressLine1}),
                    ...(renderingProvider?.addressLine2 && {addressLine2: renderingProvider?.addressLine2}),
                    ...(renderingProvider?.stateCode && {stateCode: renderingProvider?.stateCode}),
                    roleCode: 'SJ',
                }],
                requestingProvider:{
                    npi:requestingProvider?.npi,
                    ...(requestingProvider?.city && {city: requestingProvider?.city}),
                    ...(requestingProvider?.phone && {phone: requestingProvider?.phone}),
                    ...(requestingProvider?.zipCode && {zipCode: requestingProvider?.zipCode}),
                    ...(requestingProvider?.lastName && {lastName: requestingProvider?.lastName}),
                    roleCode:renderingProvider?.roleCode === 'Provider' ? '1P' : 'FA',
                    ...(requestingProvider?.extension && {extension: requestingProvider?.extension}),
                    ...(requestingProvider?.firstName && {firstName: requestingProvider?.firstName}),
                    ...(requestingProvider?.stateCode && {stateCode: requestingProvider?.stateCode}),
                    ...(requestingProvider?.contactName && {contactName: requestingProvider?.contactName}),
                    ...(requestingProvider?.addressLine1 && {addressLine1: requestingProvider?.addressLine1}),
                    ...(requestingProvider?.addressLine2 && {addressLine2: requestingProvider?.addressLine2}),
                    ...(requestingProvider?.stateCode && {stateCode: requestingProvider?.stateCode}),
                }
                
            }
        }
        const postSubmission = await submitPriorAuthAvailityForm(payload)
        JSON.parse(postSubmission?.message)?.userMessage && toast.success(JSON.parse(postSubmission?.message)?.userMessage)
        }
        catch(e){
            failedApiReq(e)
        }
        finally{
            setIsLoading(false)
        }
    }
   
    return (
        <AetnaSrContext.Provider value={{ 
            selectedTab, setSelectedTab,
            requestType, setRequestType,
            patient, setPatient,
            handleSrFormSubmit,
            serviceType, setServiceType,
            diagnose, setDiagnose,
            procedure, setProcedure,
            subscriberInfo, setSubscriberInfo,
            providerNote, setProviderNote,
            serviceInfo, setServiceInfo,
            requestingProvider, setRequestingProvider,
            renderingProvider,setRenderingProvider,
            isLoading,
            onceCompleted, setOnceCompleted
            }}>
            {children}
        </AetnaSrContext.Provider>
    )
};


export { AetnaSrContextProvider, AetnaSrContext };