import React, { useContext } from 'react'
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'
import CommonProceduresForm from '../../components/CommonProceduresForm'
import CommonNextButton from '../../components/CommonNextButton'
import toast from 'react-hot-toast'

const ProceduresCaForm = () => {
    const {proceduresInfo, setProceduresInfo, setSelectedTab} = useContext(AnthemCaContext)

    const handleSubmit = () => {

        if (!proceduresInfo) {
            toast.error('Please select 1 record')
            return
        };

        setSelectedTab((prev) => prev + 1)
    };


  return (
        <CommonProceduresForm
        procedures={proceduresInfo}
        setProcedures={setProceduresInfo}
        button={<CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />}
        />
  )
}

export default ProceduresCaForm