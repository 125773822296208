import dayjs from "dayjs"
import { get_GetPatientDataById } from "../../../../services/addPatient"

export const getPatientDetails = async(patientSelected,setLoaders,setPatientBasicDetails,patientBasicDetails)=>{
   try{
        setLoaders(true)
        const {data:{firstName,middleName,lastName,dob,memberId,patientInsuranceDetails,id}} = await get_GetPatientDataById(patientSelected?.id)
           setPatientBasicDetails({
               ...patientBasicDetails,
               firstName,
               middleName,
               lastName,
               birthDate: dayjs(dob ?? new Date()) ,
               memberId,
               id
           })
       }
       catch(e){
        console.log(e)
       }
       finally{
        setLoaders(false)
    }
}