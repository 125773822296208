import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import CommonNextButton from '../../components/CommonNextButton';
import { AetnaSrContext } from '../../../../../context/priorAuthorizationForms/AetnaSrContext';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { aetnaSrDefaultData } from '../../../../../utils/staticData';

const RenderingProviderSrForm = () => {
    const [errors, setErrors] = useState({npi:false,phone:false,roleCode:false,addressLine1:false,addressLine2:false,city:false,zipCode:false,
    });
    const {setSelectedTab,renderingProvider:formData, setRenderingProvider:setFormData, onceCompleted} = useContext(AetnaSrContext)
    const [showAllFields, setShowAllFields] = useState(false);
    

    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const checkRequiredFields = () => {
        const requiredFields = ['roleCode'];
        let hasErrors = false;
        const errors = requiredFields.reduce((acc, field) => {
            if (!formData?.[field]) {
                acc[field] = true;
                hasErrors = true;
            } else {
                acc[field] = false;
            }
            return acc;
        }, {});
        return { hasErrors, errors };
    };
    
    const validateAddressFields = () => {
        const errors = {};
        const { addressLine1, addressLine2,npi,city,zipCode,phone } = formData;
    
        if (addressLine1 && addressLine1.length < 2) {
            toast.error('Enter a valid mailing address from 2 to 55 characters in length.');
            errors.addressLine1 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine1 = false;
        }
    
        if (addressLine2 && addressLine2.length < 2) {
            toast.error('Enter a valid mailing address from 2 to 55 characters in length.');
            errors.addressLine2 = 'Enter a valid mailing address from 2 to 55 characters in length. (optional)';
        } else {
            errors.addressLine2 = false;
        }
        
        if(npi && npi?.length < 10){
            toast.error('Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.');
            errors.npi = 'Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4.'
         } 
         else {
            errors.npi = false
        }

         if (city && city.length < 2) {
            toast.error('Enter a valid city name from 2 to 30 characters in length.');
            errors.city = 'Enter a valid city name from 2 to 30 characters in length. (optional)';
        } else {
            errors.city = false;
        }

        if(zipCode){
            if(zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)){
                toast.error("Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric.")
                errors.zipCode = "Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric. (optional)"
            }
            else {
                errors.zipCode = false
            }
        }
        else {
            errors.zipCode = false
        }

        if(phone && phone?.length < 10){
            toast.error('Enter a valid phone number containing 10 numeric digits including area code.');
            errors.phone = 'Enter a valid phone number containing 10 numeric digits including area code.';
        } else {
            errors.phone = false;
        }

       

        return errors;
    };
    
    const handleSubmit = (submit) => {
       
        const { hasErrors: missingRequiredFields, errors: requiredErrors } = checkRequiredFields();
        setErrors((prev) => ({ ...prev, ...requiredErrors }));
    
        if (missingRequiredFields) {
            toast.error('Please enter all important information');
            return;
        }
    
        const addressErrors = validateAddressFields();
        setErrors((prev) => ({ ...prev, ...addressErrors }));
    
        if (!Object.values({ ...requiredErrors, ...addressErrors }).some(Boolean)) {
           submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    };
    
    
    
    

    return (
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Rendering Provider Information
                </Typography>
                <RadioGroup
                            value={showAllFields}
                            onClick={(event) => setShowAllFields(!showAllFields)}
                        >
                            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                        </RadioGroup>
                <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                   {showAllFields &&
                   <>
                    {/* Last Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider Last Name"
                            value={formData.lastName}
                            onChange={handleChange('lastName')}
                            inputProps={{ maxLength: 60 }}
                            
                        />
                    </Grid>

                    {/* First Name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider First Name"
                            value={formData.firstName}
                            onChange={handleChange('firstName')}
                            inputProps={{ maxLength: 35 }}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider NPI"
                            value={formData.npi}
                            onChange={handleChange('npi')}
                            inputProps={{ maxLength: 10 }}
                            helperText='Enter a valid Rendering Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4'
                            error={errors?.npi}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider Address Line 1"
                            value={formData.addressLine1}
                            onChange={handleChange('addressLine1')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine1}
                            helperText={errors?.addressLine1}
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider Address Line 2"
                            value={formData.addressLine2}
                            onChange={handleChange('addressLine2')}
                            inputProps={{ maxLength: 55 }}
                            error={errors?.addressLine2}
                            helperText={errors?.addressLine2}
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Rendering Provider City"
                            value={formData.city}
                            onChange={handleChange('city')}
                            inputProps={{ maxLength: 30 }}
                            error={errors?.city}
                            helperText={errors?.city}
                            
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Rendering Provider State</InputLabel>
                            <Select
                                value={formData.stateCode}
                                label="Rendering Provider State"
                                onChange={handleChange('stateCode')}
                            >
                                {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                                    <MenuItem key={value} value={code}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Rendering Provider ZIP Code"
                            value={formData.zipCode}
                            onChange={handleChange('zipCode')}
                            inputProps={{ maxLength: 9 }}
                            error={errors?.zipCode}
                            helperText={errors?.zipCode}
                            
                        />
                    </Grid>
                   
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Rendering Provider Contact Phone*"
                            value={formData.phone}
                            onChange={handleChange('phone')}
                            inputProps={{ maxLength: 10 }}
                            error={errors?.phone}
                            helperText={errors?.phone}
                        />
                    </Grid>
                   </>
                   }

                    <Grid item xs={12} >
                        <FormControl fullWidth required>
                            <InputLabel>Rendering Provider Type</InputLabel>
                            <Select
                                value={formData.roleCode}
                                label="Rendering Provider Type"
                                onChange={handleChange('roleCode')}
                                error={errors?.roleCode}
                            >
                                <MenuItem key={'SJ'} value={"Service Provider"}>
                                Service Provider
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                </Box>
                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
            </Paper>
    );
};

export default RenderingProviderSrForm;
