import React, { useContext } from 'react'
import CommonRenderingProviders from '../../components/RenderingProviders/CommonRenderingProviders'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'

const RenderingProviderAnthemGaHsForm = () => {
    const {
        renderingProviders,
        setRenderingProviders,
        setSelectedTab,
        defaultRenderingProvider,
        onceCompleted
    } = useContext(AnthemGaHsContext)
    return (
        <CommonRenderingProviders
            renderingProviders={renderingProviders}
            setRenderingProviders={setRenderingProviders}
            setSelectedTab={setSelectedTab}
            defaultRenderingProvider={defaultRenderingProvider}
            onceCompleted={onceCompleted}
        />
    )
}

export default RenderingProviderAnthemGaHsForm