import React, { useContext } from 'react'
import DefaultAetnaDiagnosesForm from '../../components/Aetna/DefaultAetnaDiagnosesForm'
import { getAllCDcodes } from '../../../../../services/pVerify'
import CdTable from '../../../../table/CdTable'
import ServiceInfoList from '../../components/ServicesInfoList'
import toast from 'react-hot-toast'
import { paFormErrors } from '../../../../../utils/staticData'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const DiagnosesArForm = () => {
    const { diagnosesInfo, setDiagnosesInfo, setSelectedTab, onceCompleted} = useContext(AetnaArContext)

    const handleSubmit = (submit) => {
        if(diagnosesInfo?.length){
         submit ? setSelectedTab(onceCompleted) :   setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.diagnosesImp)
        }
    }

    return (
        
            <DefaultAetnaDiagnosesForm
                fetchCodesApi={getAllCDcodes}
                limit={5}
                TableComponent={CdTable}
                ListComponent={(props) => (
                    <ServiceInfoList
                        serviceInfoArray={props.items}
                        onRemove={(id) => props.onRemove(id)}
                    />
                )}
                selectedItems={diagnosesInfo}
                setSelectedItems={setDiagnosesInfo}
                setSelectedTab={setSelectedTab}
                onSubmit={handleSubmit}
                onceCompleted={onceCompleted}
            />
    )
}

export default DiagnosesArForm