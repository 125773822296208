// apiService.js
import axios from 'axios';
import toast from "react-hot-toast";
import { API_BASE_URL_NEW } from '../utils/staticData';
import { userGetAxios, userPostAxios } from './userApis';
import { ymdDateFormat } from '../utils/DateFunctions';
const API_BASE_URL = process.env.REACT_APP_API_URL;


export const get_payersdata = async (pageNumber, pageSize, filterSelection) => {
    const url = `${API_BASE_URL_NEW}/api/Payers/GetAllPayersData`;

    const params = {
        ...(filterSelection && { filterSelection }),
        ...(pageNumber && { pageNumber }),
        ...(pageSize && { pageSize }),
    };

    try {
        const response = await axios.get(url, { params });
        return response.data;
    } catch (error) {
        console.log('Error fetching payers info:', error);
        toast.error("Error Fetching Payers Info (NETWORK error)");
    }
};

export const get_payersdataBySubId = async (pageNumber, pageSize,subtypeId) => {
    const url = `${API_BASE_URL_NEW}/api/Payers/GetPayersBySubtypeId`;

    try {
        const response = await axios.get(url, {
            params: {
                ...(pageNumber && { pageNumber }), 
                ...(pageSize && { pageSize }),
                ...(subtypeId && { subtypeId }),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching payers info:', error);
        toast.error("Error Fetching Payers Info (NETWORK error)");
    }
};

export const get_GetAllPayersData = async (payerDetail) => {
    const url = `${API_BASE_URL}/api/Payers/GetAllPayersData/payerCode=${payerDetail?.payerCode}/payerName=${payerDetail?.payerCode}/address=${payerDetail?.address}/phoneNumber=${payerDetail?.phoneNumber}/status=${payerDetail?.status}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error Fetching Data (NETWORK error)");

    }
};

export const get_GetPayerCodeByName = async (PayerName) => {
    const url = `${API_BASE_URL_NEW}/api/Payers/GetPayerCodeByName?PayerName=${PayerName}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log("Error: ", error)
        toast.error("Error Fetching Data (NETWORK error)");
    }
};


export const submitPaForm = async (data) => {
    const payload = {...data,
        IsSubscriberPatient:true,
        TransactionDate:ymdDateFormat(new Date()),
          
    }
    const url = `${API_BASE_URL_NEW}/api/PriorAuthorization/pVerify/PostPriorAuthInquiry`;
    try {
        const response = await userPostAxios(url,payload)
        return response?.data?.isSuccessful
    }
    catch(e){
        toast.error('Failed to submit form')
        console.log(e)
    }
}

export const submitPaAvailityForm = async (data) => {
    // const payload = {...data,
    //     isSubscriberPatient:true,
    //     transactionDate:ymdDateFormat(new Date())    
    // }
    const url = `${API_BASE_URL_NEW}/api/Availity/ServiceReviews/PostServiceReviews`;
    try {
        const response = await userPostAxios(url,data)
        return response?.data?.isSuccessful
    }
    catch(e){
        toast.error('Failed to submit form')
        console.log(e)
    }
}

export const getPaConfigs = async (payerName) => { 
    const url = `${API_BASE_URL_NEW}/api/Payers/GetPayerCodeByName?payerName=${payerName}`;
    try {
        const response = await userGetAxios(url)
        return response?.data
    }
    catch(e){
        console.log(e)
    }
}

export const getAllPayerSubTypes = async () => {
    const url = `${API_BASE_URL_NEW}/api/Payers/GetSubTypeAndIdFromPriorAuthConfig`;
    try {
        const response = await userGetAxios(url)
        return response?.data
    }
    catch(e){
        console.log(e)
    }
}

export const submitPriorAuthAvailityForm = async (data) => {
    const payload = {...data,
       requiredFields:{
        ...data?.requiredFields,
        isSubscriberPatient:true,
        transactionDate:ymdDateFormat(new Date()),
       }
          
    }
    const url = `${API_BASE_URL_NEW}/api/Availity/ServiceReviews/PostServiceReviews`;
    try {
        const response = await userPostAxios(url,payload)
        return response?.data
    }
    catch(e){
        toast.error('Failed to submit form')
        console.log(e)
    }
}