import React, { useContext } from 'react'
import { AmerigroupContext } from '../../../../../context/priorAuthorizationForms/AmerigroupArContext'
import DefaultAmerigroupDiagnosisForm from '../../components/Amerigroup/DefaultAmerigroupDiagnosisForm'
import { getAllCDcodes } from '../../../../../services/pVerify'
import CdTable from '../../../../table/CdTable'
import ServiceInfoList from '../../components/ServicesInfoList'
import toast from 'react-hot-toast'
import { paFormErrors } from '../../../../../utils/staticData'

const AmerigroupDiagnosisForm = () => {
    const { diagnosesInfo, setDiagnosesInfo, setSelectedTab, onceCompleted } = useContext(AmerigroupContext)

    const handleSubmit = (submit) => {
        if (diagnosesInfo?.length) {
            submit ? setSelectedTab(onceCompleted) : setSelectedTab(prev => prev + 1)
        }
        else {
            toast?.error(paFormErrors?.diagnosesImp)
        }
    }
    return (

        <DefaultAmerigroupDiagnosisForm
            fetchCodesApi={getAllCDcodes}
            limit={5}
            TableComponent={CdTable}
            ListComponent={(props) => (
                <ServiceInfoList
                    serviceInfoArray={props.items}
                    onRemove={(id) => props.onRemove(id)}
                />
            )}
            selectedItems={diagnosesInfo}
            setSelectedItems={setDiagnosesInfo}
            setSelectedTab={setSelectedTab}
            onSubmit={handleSubmit}
            onceCompleted={onceCompleted}
        />
    )
}

export default AmerigroupDiagnosisForm
