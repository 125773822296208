import React, { useContext } from 'react'
import CommonProviderNotes from '../../components/CommonProvideNotes'
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext'

const ProviderNotesHsForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes, onceCompleted, setOnceCompleted} = useContext(AetnaHsContext)
    const handleSubmit = (submit) => {
      submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
    }
  return (
    <CommonProviderNotes
    setProviderNote={setProviderNotes}
    setSelectedTab={setSelectedTab}
    providerNote={providerNotes}
    handleSubmit={handleSubmit}
    onceCompleted={onceCompleted}
    setOnceCompleted={setOnceCompleted}
    />
  )
}

export default ProviderNotesHsForm