import React, { useContext } from 'react'
import DefaultAetnaPatientForm from '../../components/Aetna/DefaultAetnaPatientForm'
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext'

const PatientHsForm = () => {
    const {patient, setPatient, setSelectedTab, onceCompleted, setOnceCompleted} = useContext(AetnaHsContext)
  return (
    <DefaultAetnaPatientForm
    noBack
    setSelectedTab={setSelectedTab}
    setFormData={setPatient}
    formData={patient}
    onceCompleted={onceCompleted}
    setOnceCompleted={setOnceCompleted}
    />
  )
}

export default PatientHsForm