import React, { useContext, useState } from 'react'
import {  getAllCPTcodes } from '../../../../../services/pVerify'
import DefaultAetnaProceduresForm from '../../components/Aetna/DefaultAetnaProceduresForm'
import { paFormErrors } from '../../../../../utils/staticData'
import toast from 'react-hot-toast'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'
import DefaultAetnaExtendedProceduresForm from '../../components/Aetna/DefaultAetnaExtendedProceduresForm'

const ProceduresAnthemGaHsForm = () => {
    const { proceduresInfo, setProceduresInfo, setSelectedTab, onceCompleted } = useContext(AnthemGaHsContext)
    const [errors, setErrors] = useState([])

    const fetchData = async () => {
        const data = await getAllCPTcodes();
        return data?.data?.data || [];
    };

    const handleNext = (submit) => {
        if(proceduresInfo?.length ){
            // const inCompleteProcedures = proceduresInfo?.filter(e =>   !e?.qualifierCode)
            // if(inCompleteProcedures?.length){
            //     setErrors(proceduresInfo?.map(e => {
            //         return(
            //             {
            //                 qualifierCode : e?.qualifierCode ? false : true
            //             }
            //         )
            //     }))
            //     toast?.error(paFormErrors?.propInfo)
            //     return
            // }
            submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.proceduresImp)
        }
    };

    return (
    <DefaultAetnaExtendedProceduresForm
        title="Procedures Information"
        fetchData={fetchData}
        onRowSelected={(row) => setProceduresInfo((prev) => [...prev, row])}
        onRemove={(id) => setProceduresInfo((prev) => prev.filter((item) => item.id !== id))}
        selectedData={proceduresInfo}
        setSelectedData={setProceduresInfo}
        onNext={handleNext}
        maxSelections={28}
        setSelectedTab={setSelectedTab}
        onceCompleted={onceCompleted}
        errors={errors}
        showDates={{toDate:true,fromDate:true}}
        showServices={{serviceQuantity:true,serviceQuantityType:true}}
        showQualifierCode
        notReqFields={['toDate','fromDate','quantity','quantityTypeCode','timePeriodQuantity','timeframeFrequency','timePeriodQuantityQualifier','timeFrameFrequencyQualifier','qualifierCode']}
        showTimeFields={{timePeriodQuantity:true,timeframeFrequency:true,timePeriodQuantityQualifier:true,timeFrameFrequencyQualifier:true}}
    />
    )
}

export default ProceduresAnthemGaHsForm