export const handleChangePaRegexs = {
    aetna: {
      lastName: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,60}$/, 
      firstName: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,35}$/, 
      suffix: /^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,10}$/,
      npi:/^[1-4][0-9]{0,9}$/,
      addressLine1: /^[a-zA-Z0-9\s!"&'()+,\-./;?=%@\[\]_{}|#$]{1,55}$/,
      addressLine2: /^[a-zA-Z0-9\s!"&'()+,\-./;?=%@\[\]_{}|#$]{1,55}$/,
      city:/^[a-zA-Z0-9\s!"&'()+,\-./;?=%@[\]_{}|#$]{1,30}$/,
      zipCode:/^\d{1,5}(\d{1,4})?$/,
      contactName:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,60}$/,
      phone:/^[0-9]{1,10}$/,
      extension:/^[0-9]{1,8}$/,
      memberId:/^[a-zA-Z0-9]{1,13}$/,
      quantity:/^[0-9]{1,15}$/,
      message:/^[a-zA-Z0-9!\"&'()+,\-./:;?=\s]{1,264}$/,
      middleName:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,25}$/,
      fax:/^[0-9]{1,10}$/,
      emailAddress: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      fax:/^[0-9]{0,10}$/,
      taxId:/^[0-9]{1,9}$/,
      licenseNumber:/^[a-zA-Z0-9]{1,50}$/,
      groupName:/^[a-zA-Z0-9\s!&,()+'\-./;?=#]{1,80}$/,
      groupNumber:/^[a-zA-Z0-9\s!&,()+'\-./;?=#]{1,50}$/,
      height:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,10}$/,
      weight:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,10}$/,
      homePhone:/^[0-9]{1,10}$/,
      workPhone:/^[0-9]{1,10}$/,
      medication:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,100}$/,
      note:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,100}$/,
      dose:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,50}$/,
      frequency:/^[a-zA-Z0-9\s!&,()+'\-./;?=#\\]{1,50}$/,
      sshpMemberId:/^[a-zA-Z0-9]{1,80}$/,
      smplyHsMemberId: /^(?![Rr][0-9]{9}$)[A-Za-z0-9]{1,17}$|^[Rr][0-9]{8}$/,
      payerAssignedProviderId:/^[a-zA-Z0-9]{1,50}$/,
      timePeriodQuantity:/^[0-9]{1,15}$/,
      timeframeFrequency:/^[0-9]{1,15}$/,
        },
  };
  