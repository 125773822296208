import React, { useContext } from 'react'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'
import CommonRequestingProviders from '../../components/RequestingProviders/CommonRequestingProviders'
import CommonAnthemScRequestingProviders from '../../components/RequestingProviders/CommonAnthemScRequestingProviders'

const RequestingProviderAnthemGaScForm = () => {
    const { requestingProvider: formData, setRequestingProvider: setFormData, setSelectedTab, onceCompleted,setOnceCompleted } = useContext(AnthemGaScContext)
  return (
  <CommonAnthemScRequestingProviders
  formData={formData}
  setFormData={setFormData}
  setSelectedTab={setSelectedTab}
  onceCompleted={onceCompleted}
  onSubmit = {()=>setOnceCompleted(9)}
  />
  )
}

export default RequestingProviderAnthemGaScForm