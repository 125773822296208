import { Button, styled } from '@mui/material';
import { colors } from '../../utils/colors';

const GreenButton = styled(Button)({
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
        backgroundColor: 'darkgreen',
    },
    '&.Mui-disabled': {
        backgroundColor: colors?.lightGrayBorder, 
        color: colors?.shadedGray,
    },
});

export default GreenButton