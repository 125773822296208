import React from 'react'
import AetnaArForms from '../../../components/eligibility/paForms/arForms/Aetna/AetnaArForms';
import AmeriGroupForm from '../../../components/eligibility/paForms/arForms/Amerigroup/AmerigroupForm';

const ArForms = ({ selectedPayer }) => {
    const renderForm = () => {
        switch (selectedPayer) {
            case 'AMERIGROUP COMMUNITY CARE':
                return <AmeriGroupForm />
            case 'AETNA':
                return <AetnaArForms />
        }
    }

    return renderForm();
}

export default ArForms
