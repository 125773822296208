import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, aetnaSrDefaultData, anthemCaDefaultData, paFormErrors } from '../../../../../utils/staticData';
import CommonNextButton from '../../components/CommonNextButton';
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext';

const POSQuantitiesCaForm = () => {
    const [errors, setErrors] = useState({
        placeOfServiceCode: false

    })
    const { setSelectedTab, serviceInfo, setServiceInfo } = useContext(AnthemCaContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field !== 'quantity') {
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
        }
        else {
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
    };

    const handleSubmit = () => {
        const {quantity, placeOfServiceCode, quantityTypeCode} = serviceInfo
        if (!placeOfServiceCode || !quantity || !quantityTypeCode) {
            toast.error('Please fill all important fields')
            let allErrors = {}
            if(!quantity){
                allErrors.quantity = paFormErrors?.quantity
            }
            else allErrors.quantity = false
            if(!placeOfServiceCode){
                allErrors.placeOfServiceCode = paFormErrors?.pos
            }
            else allErrors.placeOfServiceCode = false
            if(!quantityTypeCode){
                allErrors.quantityTypeCode = paFormErrors.quantityType
            }
            else allErrors.quantityTypeCode = false
            setErrors(allErrors);
            return
        }
        else {
            setErrors({ placeOfServiceCode: false, quantity: false, quantityTypeCode: false });
            setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Place Of Service & Quantity Type Information
            </Typography>


            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                                error={errors?.placeOfServiceCode}
                            >
                                {anthemCaDefaultData?.pos?.map((item) => (
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                   
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Quantity"
                                    value={serviceInfo?.quantity}
                                    onChange={(e) => handleChange(e, 'quantity')}
                                    error={errors?.quantity}
                                    helperText={errors?.quantity}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required>
                                    <InputLabel>Quantity Type</InputLabel>
                                    <Select
                                        value={serviceInfo?.quantityTypeCode}
                                        label="Quantity Type"
                                        onChange={(e) => handleChange(e, 'quantityTypeCode')}
                                        error={errors?.quantityTypeCode}
                                    >
                                        {aetnaDefaultData?.quantityTypes?.map((item) => (
                                            <MenuItem key={item.code} value={item.code}>
                                                {item.value}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                    <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.quantityTypeCode && errors?.quantityTypeCode}
                            </FormHelperText>
                                </FormControl>
                            </Grid>
                        
                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />
        </Paper>
    );
};

export default POSQuantitiesCaForm;
