import React, { useContext } from 'react'
import CommonProviderNotes from '../../components/CommonProvideNotes'
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'

const ProviderNotesCaForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes} = useContext(AnthemCaContext)
    const handleSubmit = () => {
        setSelectedTab((prev) => prev + 1)
    }
  return (
    <CommonProviderNotes
    setProviderNote={setProviderNotes}
    setSelectedTab={setSelectedTab}
    providerNote={providerNotes}
    handleSubmit={handleSubmit}
    />
  )
}

export default ProviderNotesCaForm