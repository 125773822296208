import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { styles } from './styles';
import { sharedStyles } from '../../sharedStyles'
import PageLoader from '../../components/loader/PageLoader'
import TableHeader from '../../components/TableHeader';
import { availityServicePayload, paFormErrors, policyHolderRelationship } from '../../utils/staticData';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DefaultSelect from '../../components/shared/DefaultSelect';
import { get_payersdata } from '../../services/PayerService';
import { get_GetPatientDataById } from '../../services/addPatient';
import { getLocalRequestNpi, handlePaginationScroll } from '../../utils/reusableFunctions';
import GreenButton from '../../components/claims/GreenButton';
import { getServiceReviewsByParam } from '../../services/pVerify';
import { useNavigate } from 'react-router-dom';

const NewSubmitAvaility = () => {
    // states
    const [errors, setErrors] = useState({ 
        birthDate: false, 
        lastName: false, 
        firstName: false, 
        insuranceName: false, 
        patientSubscriberRelationshipCode: false,
        patientFirstName:false,
        patientLastName:false,
        patientBirthDate:false,
        patientSubscriberRelationshipCode:false,
        insuranceName:false
    });
    const [patientSelected, setPatientSelected] = useState(null)
    const [loader, setLoader] = useState({ patient: false, payersData: false,form:false })
    const [page, setPage] = useState(1)
    const [formData, setFormData] = useState(availityServicePayload)
    const [payersData, setPayersData] = useState([])
    const [showAllFields, setShowAllFields] = useState("Required Fields Only");
    const navigate = useNavigate()
    // useEffects

    useEffect(() => {
        patientSelected?.id && getPatientDetails(patientSelected, setLoader, setFormData, formData)
    }, [patientSelected])

    useEffect(() => {
        fetchData()
    }, [page])

    // functions

    // handling field values
    const handleChange = (field) => (event,selectedVal) => {
        const { value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]:  field !== 'insuranceName' ? value : selectedVal,
        }));
    };

    // handling date fields values
    const handleDateChange = (field) => (date) => {
        setFormData({
            ...formData,
            [field]: date,
        });
    };

    // getting data for all payers list
    const fetchData = async () => {
        setLoader({ ...loader, payersData: true });
        try {
            const allPayersdata = await get_payersdata(page, 20);
            const payers_array = allPayersdata?.data?.map((payer) => payer.payerName) || [];
            setPayersData(payersData?.concat(payers_array));
        } catch (error) {
            console.log('Error fetching payers data:', error);
        } finally {
            setLoader({ ...loader, payersData: false });
        }
    };

    // handling when patient is selected
    const getPatientDetails = async () => {
        if (patientSelected?.id) {
            try {
                setLoader({ ...loader, patient: true })
                const { data: { firstName: patientFirstName, middleName: patientMiddleName, lastName: patientLastName, dob, memberId: subscriberMemberId, patientInsuranceDetails } } = await get_GetPatientDataById(patientSelected?.id)
                setFormData({
                    ...formData,
                    patientFirstName,
                    patientMiddleName,
                    patientLastName,
                    patientBirthDate: dayjs(dob).format('YYYY-MM-DD'),
                    subscriberMemberId,
                    insuranceName: patientInsuranceDetails?.length ? patientInsuranceDetails?.[0] : ''

                })
            }
      
            catch (e) {
                console.log(e)
            }
            finally {
                setLoader({ ...loader, patient: false })
            }
        }
    }

    // form submission
    const handleSubmit = async() => {
        const { patientFirstName, patientLastName, patientBirthDate, patientSubscriberRelationshipCode, insuranceName } = formData
        if (!patientFirstName || !patientLastName || !patientBirthDate || !patientSubscriberRelationshipCode || !insuranceName) {
            toast.error('Please enter all required information to continue.');
            let allErrors = {}
            if(!patientFirstName){
                allErrors.patientFirstName = true
            }
            else allErrors.patientFirstName = false
            if(!patientLastName){
                allErrors.patientLastName = true
            }
            else allErrors.patientLastName = false
            if(!patientBirthDate){
                allErrors.patientBirthDate = true
            }
            else allErrors.patientBirthDate = false
            if(!patientSubscriberRelationshipCode){
                allErrors.patientSubscriberRelationshipCode = true
            }
            else allErrors.patientSubscriberRelationshipCode = false
            if(!insuranceName){
                allErrors.insuranceName = true
            }
            else allErrors.insuranceName = false
            setErrors({...errors,
                patientFirstName:allErrors?.patientFirstName,
                patientLastName:allErrors?.patientLastName,
                patientBirthDate:allErrors?.patientBirthDate,
                patientSubscriberRelationshipCode:allErrors?.patientSubscriberRelationshipCode,
                insuranceName:allErrors?.insuranceName
            })
        } else {
          setErrors({...errors,
            patientFirstName:false,
            patientLastName:false,
            patientBirthDate:false,
            patientSubscriberRelationshipCode:false,
            insuranceName:false
        })
        try{
            setLoader({...loader,form:true})
            const formSubmission =  await getServiceReviewsByParam({...formData,patientBirthDate: dayjs(formData?.patientBirthDate).format('YYYY-MM-DD'),});

            if(!formSubmission?.data?.isSuccessful){
                const errorMessage = JSON.parse(formSubmission?.data?.message)?.moreInformation
                errorMessage && toast.error(errorMessage)
            }
            navigate('/detailsPA', {state: {data: formSubmission?.data}})
        }
        catch(e){
            console.log(e)
        }
        finally{
            setLoader({...loader,form:false})
        }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Search PA for Patient
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded
                        handleCrossValue={() => setFormData(availityServicePayload)}
                    />
                </div>
                {loader?.patient ?
                    <PageLoader />
                    :
                    <>
                    <FormControl>
                        <FormLabel id='show-required-fields'>Form Type</FormLabel>
                            <RadioGroup
                            row
                                    aria-labelledby='show-required-fields'
                                    defaultValue={showAllFields}
                                    value={showAllFields}
                                    onClick={(event) => setShowAllFields(event.target.value)}
                                >
                                    <FormControlLabel value={"Required Fields Only"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Quick Search" />
                                    <FormControlLabel value={"Basic Form"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Advance Search" />
                                </RadioGroup>
                       
                    </FormControl>
                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={showAllFields === "Basic Form" ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label="Patient First Name"
                                        onChange={handleChange('patientFirstName')}
                                        required
                                        value={formData?.patientFirstName}
                                        error={errors?.patientFirstName}

                                    />
                                </Grid>
                                {showAllFields === "Basic Form" && <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Patient Middle Name"
                                        onChange={handleChange('patientMiddleName')}
                                        value={formData?.patientMiddleName}
                                    />
                                </Grid>}
                                <Grid item xs={12} md={showAllFields === "Basic Form" ? 4 : 6}>
                                    <TextField
                                        fullWidth
                                        label="Patient Last Name"
                                        onChange={handleChange('patientLastName')}
                                        required
                                        value={formData?.patientLastName}
                                        error={errors?.patientLastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="Patient Date of Birth"
                                        value={dayjs(formData.patientBirthDate)}
                                        onChange={handleDateChange('patientBirthDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                                error: !!errors?.patientBirthDate,
                                            },
                                        }}
                                        sx={errors?.patientBirthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                    />
                                </Grid>
                                {showAllFields === "Basic Form" && 
                                <>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="Transaction Date"
                                        value={dayjs(formData.transactionDate)}
                                        onChange={handleDateChange('transactionDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="From Date"
                                        value={dayjs(formData.fromDate)}
                                        onChange={handleDateChange('fromDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="To Date"
                                        value={dayjs(formData.toDate)}
                                        onChange={handleDateChange('toDate')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                </>
                                }
                                <Grid item xs={12} md={6}>
                                    <DefaultSelect
                                        label="Insurance Name *"
                                        options={payersData}
                                        value={formData?.insuranceName || null}
                                        loading={loader?.payersData}
                                        onScroll={(e) => handlePaginationScroll(e, setPage, page,loader?.payersData)}
                                        error={errors?.insuranceName}
                                        onChange={handleChange('insuranceName')}
                                        color='primary'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth required error={errors?.patientSubscriberRelationshipCode}>
                                        <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                                        <Select
                                            value={formData.patientSubscriberRelationshipCode}
                                            label="Patient's Relationship to Subscriber"
                                            onChange={handleChange('patientSubscriberRelationshipCode')}
                                        >
                                            {
                                                !!policyHolderRelationship?.length && policyHolderRelationship?.map((item, index) => (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="NPI"
                                        inputProps={{
                                            maxLength: 60,
                                        }}
                                        required
                                        readOnly
                                        disabled
                                        value={getLocalRequestNpi()}
                                    />
                                </Grid>
                                {showAllFields === "Basic Form" && <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Subscriber Member ID"
                                        onChange={handleChange('subscriberMemberId')}
                                        value={formData?.subscriberMemberId}
                                    />
                                </Grid>}


                            </Grid>
                        </Box>
                        <Grid
                            item
                            xs={12}
                            display={'flex'}
                            sx={sharedStyles?.justifyEnd}
                            gap={1}
                        >
                            <GreenButton onClick={handleSubmit} disabled={loader?.form} >
                                {loader?.form ? <CircularProgress color='inherit' size={17}/> : 'Submit'}
                            </GreenButton>
                        </Grid>

                    </>
                }
            </Paper>
        </LocalizationProvider>
    );
};

export default NewSubmitAvaility;
