import React, { useContext, useState } from 'react'
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext'
import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { styles } from '../../components/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, hsPlaceOfServiceOps, hsReportTypes, hsServiceTypes, posArFields, serviceTypeArFields } from '../../../../../utils/staticData';
import CommonNextButton from '../../components/CommonNextButton';
import { handleChangePaRegexs } from '../../handleChangeRegexes';

const ServiceInfoSshpForm = () => {
    const {serviceInfo, setServiceInfo, setSelectedTab, onceCompleted} = useContext(SilverSummitHealtPlanHsContext)
    const [showAllFields, setShowAllFields] = useState(false);
    const [errors, setErrors] = useState({
        serviceType:false,serviceLevel:false,placeOfServiceCode:false

    })

    const handleChange = (event, field) => {
        const { value } = event.target;
      
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        
    };

    const handleSubmit = (submit) => {

        if (!serviceInfo?.serviceLevel || !serviceInfo?.serviceType || !serviceInfo?.placeOfServiceCode) {

            setErrors({ 
                serviceType: !serviceInfo?.serviceType ? 'Required Field' : false,
                serviceLevel:!serviceInfo?.serviceLevel ? 'Required Field' : false,
                placeOfServiceCode:!serviceInfo?.placeOfServiceCode ? 'Required Field' : false
            });
            return
        }

        else {
            setErrors({ serviceLevel: false, serviceType: false, placeOfServiceCode:false });
            submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab((prev) => prev + 1)
        }
    };


    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service & Quantity Type Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <FormControl fullWidth required
                        error={errors?.placeOfServiceCode}
                        >
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                            >
                                {hsPlaceOfServiceOps?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {showAllFields && 
                    <>
                    
                    
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth >
                            <InputLabel>Report Service Type</InputLabel>
                            <Select
                                value={serviceInfo?.reportType}
                                label="Report Service Type"
                                onChange={(e) => handleChange(e, 'reportType')}
                            >
                                {hsReportTypes?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    </>
                    }

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required error={errors?.serviceType}>
                            <InputLabel>Service Type</InputLabel>
                            <Select
                                value={serviceInfo?.serviceType}
                                label="Service Type"
                                onChange={(e) => handleChange(e, 'serviceType')}
                                error={errors?.serviceType}
                            >
                                {hsServiceTypes?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceType && errors?.serviceType}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required error={errors?.serviceLevel}>
                            <InputLabel>Service Level</InputLabel>
                            <Select
                                value={serviceInfo?.serviceLevel}
                                label="Service Level"
                                onChange={(e) => handleChange(e, 'serviceLevel')}
                                error={errors?.serviceLevel}
                            >
                                {aetnaDefaultData?.serviceLevelCodeOptions?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.serviceLevel && errors?.serviceLevel}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                  

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
}

export default ServiceInfoSshpForm