import React from 'react'
import AetnaSrForms from '../../../components/eligibility/paForms/serviceReviewForms/Aetna/AetnaSrForms';
import AnthemGaScForms from '../../../components/eligibility/paForms/serviceReviewForms/AnthemScForms/AnthemGaScForm';

const ScForms = ({ selectedPayer }) => {
  const renderForm = () => {
    switch (selectedPayer) {
      case 'AETNA':
      case 'SUTTER HEALTH AETNA':
      case 'TEXAS HEALTH AETNA':
      case "INNOVATION HEALTH":
      case 'BANNER AETNA':
      case 'ALLINA HEALTH AETNA':
        return <AetnaSrForms title={selectedPayer} />;
      case 'ANTHEM - GA':
      case "INNOVATION HEALTH":
      case "ANTHEM BCBS INDIANA":
      case "ANTHEM BCBS MISSOURI":
      case "ANTHEM BCBS NEW HAMPSHIRE":
      case "ANTHEM BCBS NEVADA":
      case "ANTHEM BC NY":
      case "ANTHEM BCBS OHIO":
      case "HEALTHY BLUE SOUTH CAROLINA":
      case "AMERIGROUP COMMUNITY CARE":
      case "ANTHEM BCBS VIRGINIA":
      case "ANTHEM BCBS WISCONSIN":
      case "HEALTHY BLUE MISSOURI":
      case "HEALTHY BLUE NEBRASKA":
      case "HEALTHY BLUE DUAL ADVANTAGE":
      case "HEALTHY BLUE NORTH CAROLINA":
      case "HEALTHY BLUE LOUISIANA":
      case "BLUE MEDICARE ADVANTAGE":
      case "ANTHEM BCBS NY":
      case "ANTHEM MAINEHEALTH":
      case "AMERIGROUP (CLAIMS)":
      case "WELLPOINT MARYLAND (pre 6-15-2024)":
      case "HIGHMARK BLUE CROSS BLUE SHIELD (NY) - MEDICAID AND CHP":
      case "CLEAR HEALTH ALLIANCE":
      case "COLORADO COMMUNITY HEALTH ALLIANCE":
      case "SUMMIT COMMUNITY CARE":
      case "ANTHEM BC CALIFORNIA":
      case "ANTHEM BCBS COLORADO":
      case "ANTHEM BCBS CONNECTICUT":
      case "ANTHEM BCBS KENTUCKY":
      case "ANTHEM BCBS MAINE":
      case "SIMPLY HEALTHCARE NEW":
      case "UNICARE":
        return <AnthemGaScForms title={selectedPayer} />
      default:
        return <div>No forms available for the selected payer.</div>;
    }
  };

  return renderForm();
};

export default ScForms;