import React, { useContext, useState } from 'react'
import { Box, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { AetnaSrContext } from '../../../../../context/priorAuthorizationForms/AetnaSrContext'
import { handleChangePaRegexs } from '../../handleChangeRegexes'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import CommonNextButton from '../../components/CommonNextButton'
import { memberIdRequiredStr } from '../../../../../utils/staticData'

const SubscriberSrForm = () => {
    
    const [errors, setErrors] = useState({memberId:false})
    const {setSelectedTab, subscriberInfo, setSubscriberInfo, onceCompleted} = useContext(AetnaSrContext)
    const [showAllFields, setShowAllFields] = useState(false);
 
    const handleChange = (field) => (event) => {
        const { value } = event.target;
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }
        setSubscriberInfo((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleSubmit = (submit) => {
        if(subscriberInfo?.memberId?.length < 2){
            toast.error(memberIdRequiredStr)
            setErrors({...errors,memberId:memberIdRequiredStr})
            return
        } 
        else {
            setErrors({...errors,memberId:false})
           submit ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }
    }

  return (
    <>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Subscriber Information
                </Typography>

                <RadioGroup
                            value={showAllFields}
                            onClick={(event) => setShowAllFields(!showAllFields)}
                        >
                            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                        </RadioGroup>
                    <Box sx={styles?.commonPaperContainer}>
                        <Grid container spacing={3}>

                            {showAllFields && 
                            <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    value={subscriberInfo?.lastName}
                                    onChange={handleChange('lastName')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    value={subscriberInfo?.firstName}
                                    onChange={handleChange('firstName')}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Suffix"
                                    value={subscriberInfo?.suffix}
                                    onChange={handleChange('suffix')}
                                />
                            </Grid>
                            </>
                            }
                            <Grid item xs={12} md={!showAllFields ? 12 : 6}>
                                <TextField
                                    fullWidth
                                    label="Member ID"
                                    value={subscriberInfo?.memberId}
                                    required
                                    onChange={handleChange('memberId')}
                                    helperText={'Enter a valid alphanumeric Member ID. Punctuation and special characters are not allowed. (Required)'}
                                    error={errors?.memberId}
                                />
                            </Grid>

                        </Grid>
                    </Box>

                    <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
            </Paper>
    </>
  )
}

export default SubscriberSrForm