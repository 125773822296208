import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, aetnaHsPosOptions, anthemCaDefaultData } from '../../../../../utils/staticData';
import CommonNextButton from '../../components/CommonNextButton';
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext';

const ServiceHsForm = () => {
    const [errors, setErrors] = useState({
        placeOfServiceCode: false

    })
    const [showAllFields, setShowAllFields] = useState(false);
    const { setSelectedTab, serviceInfo, setServiceInfo, onceCompleted } = useContext(AetnaHsContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
        setServiceInfo((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleSubmit = (submit) => {
        const { placeOfServiceCode } = serviceInfo
        if (!placeOfServiceCode) {
            toast.error('Please fill all important fields')
            setErrors({ placeOfServiceCode: true });
        }
        else {
            setErrors({ placeOfServiceCode: false });
            submit && onceCompleted ? setSelectedTab(onceCompleted) :   setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Service Type Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required error={Boolean(errors?.placeOfServiceCode)}>
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                                error={errors?.placeOfServiceCode}
                            >
                                {aetnaHsPosOptions?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {showAllFields &&
                        (<Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Service Level</InputLabel>
                                <Select
                                    value={serviceInfo?.serviceType}
                                    label="Service Level"
                                    onChange={(e) => handleChange(e, 'serviceType')}
                                >
                                    {aetnaDefaultData?.serviceLevelCodeOptions?.map((item) => (
                                        <MenuItem key={item.code} value={item.value}>
                                            {item.value}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Grid>)
                    }



                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
        </Paper>
    );
};

export default ServiceHsForm;
