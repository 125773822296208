import React, { useContext } from 'react'
import DefaultAetnaProviderNotesSrForm from '../../components/Aetna/DefaultAetnaProviderNotes'
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext'

const ProviderNotesArForm = () => {
    const {setSelectedTab, providerNotes, setProviderNotes, onceCompleted} = useContext(AetnaArContext)
  return (
    <DefaultAetnaProviderNotesSrForm
    setProviderNote={setProviderNotes}
    providerNote={providerNotes}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />
  )
}

export default ProviderNotesArForm