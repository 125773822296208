import React  from 'react'
import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { handleChangePaRegexs } from '../handleChangeRegexes';
import { styles } from './styles';
import { sharedStyles } from '../../../../sharedStyles';
import CommonNextButton from './CommonNextButton';

const CommonProviderNotes = ({setSelectedTab, providerNote, setProviderNote, handleSubmit, maxLength, onceCompleted}) => {
  
const handleChange = (event) => {
    const { value } = event.target;
  
    const regex = handleChangePaRegexs?.aetna?.message;
  
    if (regex && value !== "" && !regex.test(value)) {
      return; 
    }
  
    setProviderNote(value); 
  };

  return (
    <>
    <Paper elevation={3} sx={styles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
            Provider Notes
        </Typography>

        
            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Provider Note"
                            value={providerNote}
                            onChange={handleChange}
                            inputProps={{
                                maxLength: maxLength ?? 264
                            }}
                        />
                    </Grid>

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
    </Paper>
</>
  )
}

export default CommonProviderNotes