import { colors } from "../../utils/colors";

export const styles = {
    eligibilityDetailsButton:{ 
        backgroundColor: colors?.green, 
        border: 'none', 
        color: colors?.white, 
        fontSize: 'small',
     },
      statusStyle: {
        backgroundColor: colors?.white,
        color: colors?.green,
      },
      paListBox : {
        maxHeight: 200, 
        overflow: 'auto',
      },
      commonPaperContainer:{ 
        mb: 4, 
        p: 2, 
        border: `1px solid ${colors?.lightGrayBorder}`, 
        borderRadius: 1 
    },
    parentPaperContainer:{ 
        p: 4, 
        width: '80%', 
        mx: 'auto', 
        my: 4
     },
    }