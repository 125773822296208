import React, { useContext, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { sharedStyles } from '../../../../../sharedStyles';
import { arAdmissionnTypes, arAdmissionSource } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext';

const AdmissionInfoArForm
 = () => {
    const [errors, setErrors] = useState({type: false })
    const [showAllFields, setShowAllFields] = useState(false);
    const {setSelectedTab, admissionInfo, setAdmissionInfo, onceCompleted} = useContext(AetnaArContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
            setAdmissionInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
    };

    const handleSubmit = (submit) => {

        if (!admissionInfo?.type) {

            setErrors({ type: 'Required Field' });
            return
        }

        else {
            setErrors({ type: false });
        submit ?  setSelectedTab(onceCompleted) :  setSelectedTab(prev => prev + 1)
        }
    };


    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Admission Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                   <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <FormControl 
                        error={errors?.type}
                        fullWidth 
                        required>
                            <InputLabel>Admssion Type</InputLabel>
                            <Select
                                value={admissionInfo?.type}
                                label="Admssion Type"
                                onChange={(e) => handleChange(e, 'type')}
                            >
                                {arAdmissionnTypes?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.type && errors?.type}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {showAllFields && <Grid item xs={12} md={6}>
                        <FormControl fullWidth >
                            <InputLabel>Admssion Source</InputLabel>
                            <Select
                                value={admissionInfo?.source}
                                label="Admssion Source"
                                onChange={(e) => handleChange(e, 'source')}
                            >
                                {arAdmissionSource?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>}

                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
        </Paper>
    );
};

export default AdmissionInfoArForm
;
