import { colors } from "../../../../utils/colors";

export const styles ={
    commonPaperContainer:{ 
        mb: 4, 
        p: 2, 
        border: `1px solid ${colors?.lightGrayBorder}`, 
        borderRadius: 1 
    },
    parentPaperContainer:{ 
        p: 4, 
        width: '80%', 
        mx: 'auto', 
        my: 4
     },
    serviceInfoListContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
        border: `1px solid ${colors?.lightGrayDisabled}`,
        borderRadius: '4px',
        marginBottom: '8px',
    },
    detailCardContainer:{
        mb: 3,
        border: `1px solid ${colors?.borderGray}`, 
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: `0 8px 24px ${colors?.shadedBorderGray}`, 
        }
      },
    detailCardChip:{
        backgroundColor: colors?.softGray, 
        fontWeight: 500,
        fontSize: '0.875rem'
      },
      infoRowContainer:{
        display: 'flex',
        alignItems: 'flex-start',
        mb: 1,
        '&:last-child': { mb: 0 }
      },
      infoRowLabel:{
        width:'20%',
        color: colors?.mediumGray, 
        fontSize: '0.875rem'
      },
      infoRowValue:{
        flex: 1,
        color: colors?.darkGray, 
        fontSize: '0.875rem',
        fontWeight: 500
      },
      formDeatilsHeading:{ 
        mb: 4, 
        fontWeight: 300,
        textAlign: 'center',
        color: colors?.darkGray, 
      },
      formDetailsProviderNotes:{ 
        fontSize: '0.875rem',
        color: colors?.darkGray, 
        lineHeight: 1.6,
      },
      formDeatilsMainBox:{ maxWidth: 800, margin: '0 auto' },
      formDeatilsContainer:{ py: 4, animation: 'fadeIn 0.5s ease-in' }
}