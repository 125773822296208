import { Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect, useState } from 'react'
import { styles } from '../../Aetna/styles'
import { sharedStyles } from '../../../../../sharedStyles'
import { handleChangePaRegexs } from '../../handleChangeRegexes'
import { getPatientDetails } from '../commonFunctions'
import { AmeriGroupPayload, paFormErrors, paOptionalSuffixText, paTextFieldHelperText, paValidDateText } from '../../../../../utils/staticData'
import CommonNextButton from '../CommonNextButton'
import toast from 'react-hot-toast'
import TableHeader from '../../../../TableHeader'
import PageLoader from '../../../../loader/PageLoader'
import dayjs from 'dayjs'

const DefaultAmerigroupPatientForm = ({ setSelectedTab, formData, setFormData, noBack, onceCompleted }) => {
    const [errors, setErrors] = useState({ birthDate: false, lastName: false, firstName: false });
    const [showAllFields, setShowAllFields] = useState("Required Fields Only");
    const [patientSelected, setPatientSelected] = useState(null)
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        patientSelected?.id && getPatientDetails(patientSelected, setLoader, setFormData, formData)
    }, [patientSelected])

    const handleChange = (field) => (event) => {
        const { value } = event.target;

        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            birthDate: date,
        });
    };


    const handleSubmit = (submit) => {
        const { birthDate, lastName, firstName } = formData
        if (!birthDate || !lastName || !firstName) {
            toast.error('Please enter all required information to continue.');
            // setErrors((prev) => ({ ...prev, dob: true }));
            let allErrors = {}
            if (!birthDate) {
                allErrors.birthDate = paFormErrors.birthDateImp
            }
            else allErrors.birthDate = false
            if (!lastName) {
                allErrors.lastName = paFormErrors.lastNameImp
            }
            else allErrors.lastName = false
            if (!firstName) {
                allErrors.firstName = paFormErrors.firstNameImp
            }
            else allErrors.firstName = false
            setErrors(allErrors)
        } else {
            setErrors((prev) => ({ birthDate: false, lastName: false, firstName: false }));
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Patient Information
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={() => setFormData(DefaultAmerigroupPatientForm?.patientInfo)} />
                </div>
                {/* Radio Button to Toggle Fields */}
                {loader ?
                    <PageLoader />
                    :
                    <>
                        <FormControl>
                            <FormLabel id='show-required-fields'>Form Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby='show-required-fields'
                                defaultValue={showAllFields}
                                value={showAllFields}
                                onClick={(event) => setShowAllFields(event.target.value)}
                            >
                                <FormControlLabel value={"Required Fields Only"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Quick Search" />
                                <FormControlLabel value={"Basic Form"} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Advance" />
                            </RadioGroup>

                        </FormControl>
                      

                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                {/* Last Name */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Patient Last Name"
                                        value={formData.lastName}
                                        onChange={handleChange('lastName')}
                                        inputProps={{
                                            maxLength: 60,
                                        }}
                                        required
                                        error={errors?.lastName}
                                        helperText={paTextFieldHelperText}
                                    />
                                </Grid>

                                {/* First Name */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Patient First Name"
                                        value={formData.firstName}
                                        onChange={handleChange('firstName')}
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        required
                                        error={errors?.firstName}
                                        helperText={paTextFieldHelperText}
                                    />
                                </Grid>


                                {showAllFields === "Basic Form" && (<Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Patient Suffix"
                                        value={formData.suffix}
                                        onChange={handleChange('suffix')}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        helperText={paOptionalSuffixText}
                                    />
                                </Grid>)}

                                {/* Birth Date */}
                                <Grid item xs={12} md={showAllFields === "Basic Form" ? 6 : 12}>
                                    <DatePicker
                                        label="Patient Date of Birth"
                                        sx={errors?.birthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                        value={formData.birthDate}
                                        onChange={handleDateChange}
                                        minDate={dayjs('1870-01-01')}
                                        maxDate={dayjs('2024-12-12')}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                                helperText: paValidDateText,
                                                error: !!errors?.birthDate,
                                                inputProps: { readOnly: true },
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* Gender */}
                                {showAllFields === "Basic Form" && (<Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Patient Gender</InputLabel>
                                        <Select
                                            value={formData.genderCode}
                                            label="Patient Gender"
                                            onChange={handleChange('genderCode')}
                                        >
                                            {AmeriGroupPayload?.patientGenders?.map(({ value, label }) => (
                                                <MenuItem key={value} value={label}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>)}

                                {/* Relationship to Subscriber */}
                                {showAllFields === "Basic Form" && (<Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                                        <Select
                                            value={formData.subscriberRelationshipCode}
                                            label="Patient's Relationship to Subscriber"
                                            onChange={handleChange('subscriberRelationshipCode')}
                                        >
                                            {AmeriGroupPayload?.patientRelationToSubscriber?.map(({ value, label }) => (
                                                <MenuItem key={value} value={label}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                            </Grid>
                        </Box>
                        <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} noBack={noBack} onceCompleted={onceCompleted} />
                    </>
                }
            </Paper>
        </LocalizationProvider>
    )
}

export default DefaultAmerigroupPatientForm
