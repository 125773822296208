import { createContext, useState } from "react";
import { AmeriGroupPayload } from "../../utils/staticData";


const AmerigroupContext = createContext();

const AmerigroupContextProvider = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [patientInfo, setPatientInfo] = useState(AmeriGroupPayload?.patientInfo);
    const [diagnosesInfo, setDiagnosesInfo] = useState([]); 
    const [requestingProvider, setRequestingProvider] = useState(AmeriGroupPayload?.otherSupportedInfo?.requestingProvider);
    const [renderingProviders, setRenderingProviders] = useState(AmeriGroupPayload?.otherSupportedInfo?.renderingProviders);
    const [subscriberInfo, setSubscriberInfo] = useState(AmeriGroupPayload?.subscriberInfo);
    const [procedureInfo, setProcedureInfo] = useState([]);
    const [admissionInfo, setAdmissionInfo] = useState(AmeriGroupPayload?.admissionInfo);
    const [providerNotes, setProviderNotes] = useState([]);
    const [onceCompleted, setOnceCompleted] = useState(false);


    const handleSubmit = () => {
        console.log('submit')
    }

    return (
        <AmerigroupContext.Provider value={{
            selectedTab, setSelectedTab,
            patientInfo, setPatientInfo,
            diagnosesInfo, setDiagnosesInfo,
            requestingProvider, setRequestingProvider,
            renderingProviders, setRenderingProviders,
            subscriberInfo, setSubscriberInfo,
            procedureInfo, setProcedureInfo,
            admissionInfo, setAdmissionInfo,
            providerNotes, setProviderNotes,
            onceCompleted, setOnceCompleted,handleSubmit
        }}>
            {children}
        </AmerigroupContext.Provider>
    )

}

export { AmerigroupContext, AmerigroupContextProvider }