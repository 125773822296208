import React, { useState } from 'react';
import { Box, Grid, Paper, TextField, Typography, IconButton } from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { handleChangePaRegexs } from '../handleChangeRegexes';
import { sharedStyles } from '../../../../sharedStyles';
import CommonNextButton from './CommonNextButton';
import { styles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const DefaultProviderNotesArrayForm = ({ setSelectedTab, providerNotes, setProviderNotes, maxFields = 5, onceCompleted }) => {
  const [notes, setNotes] = useState(providerNotes || ['']); // Initialize with at least one empty note

  // Handle change of a specific note
  const handleChange = (index, event) => {
    const { value } = event.target;
    const regex = handleChangePaRegexs?.aetna?.message;

    if (regex && value !== "" && !regex.test(value)) {
      return; // Prevent invalid value based on regex
    }

    const newNotes = [...notes];
    newNotes[index] = value; // Update the note at the given index
    setNotes(newNotes);
    setProviderNotes(newNotes);
  };

  // Handle adding a new note field
  const handleAddField = () => {
    if (notes.length < maxFields) {
      setNotes([...notes, '']);
    }
  };

  // Handle deleting a note field
  const handleDeleteField = (index) => {
    if (notes.length > 1) { // Prevent deleting the last note
      const newNotes = notes.filter((_, i) => i !== index);
      setNotes(newNotes);
      setProviderNotes(newNotes);
    }
  };

  const handleSubmit = (submit) => {
    submit && onceCompleted ? setSelectedTab(onceCompleted) :  setSelectedTab((prev) => prev + 1);
  };
  return (
    <>
      <Paper elevation={3} sx={styles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
          Provider Notes
        </Typography>

        <Box sx={styles?.commonPaperContainer}>
          <Grid container spacing={3}>
            {notes.map((note, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  label={`Provider Note ${index + 1}`}
                  value={note}
                  onChange={(event) => handleChange(index, event)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleDeleteField(index)}
                        disabled={notes.length <= 1} // Disable delete button if only one field exists
                      >
                         <DeleteIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Add new field button */}
        {notes.length < maxFields && (
          <Box sx={sharedStyles?.mb4}>
            <IconButton onClick={handleAddField} color="primary">
              <AddIcon /> Add Note
            </IconButton>
          </Box>
        )}

        <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted} />
      </Paper>
    </>
  );
};

export default DefaultProviderNotesArrayForm;
