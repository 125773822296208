import { Box, Stack, Typography } from '@mui/material'
import { FaRegNoteSticky } from "react-icons/fa6";
import { LiaWrenchSolid } from "react-icons/lia";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineUnsubscribe } from "react-icons/md";
import { CgNametag } from "react-icons/cg";
import React from 'react'
import { sharedStyles } from '../sharedStyles';

const SCDetails = () => {

    return (
        <div>
            <Typography variant='h5' textAlign={'start'} fontWeight={600}>
                Abdul Moeed (Patient)</Typography>
            <Stack direction="row" gap={3} marginTop={2} flexWrap={"wrap"}>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <FaRegNoteSticky />
                    <Typography variant='body2'>
                        0 Provider Notes
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <LiaWrenchSolid />
                    <Typography variant='body2'>
                        1 Rendering Provider
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <LiaWrenchSolid />
                    <Typography variant='body2'>
                        Gender Code: M
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <CiCalendarDate />
                    <Typography variant='body2'>
                        Transaction Date: 19/11/2024
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <MdOutlineUnsubscribe />
                    <Typography variant='body2'>
                        Is patient subscriber: Yes
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <CiCalendarDate />
                    <Typography variant='body2'>
                        Birth Date: 1999-11-12
                    </Typography>
                </Box>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.alignItemsCenter, sharedStyles?.gap1]}>
                    <CgNametag />
                    <Typography variant='body2'>
                        Subscriber: Mars Jason
                    </Typography>
                    <Typography variant='body2'>
                        MemberID:         23
                    </Typography>
                </Box>
            </Stack>
            <Box>
                <Typography variant='h5' marginBottom={2} marginTop={4} fontWeight={600}>
                    Requesting Providers
                </Typography>
                <Stack direction="row" gap={6} flexWrap={'wrap'}>
                    <Stack direction="column">
                        <Typography variant='caption'>First Name</Typography>
                        <Typography variant='h6'>Stacey</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Last Name</Typography>
                        <Typography variant='h6'>Marshall</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>NPI</Typography>
                        <Typography variant='h6'>123456789</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>City</Typography>
                        <Typography variant='h6'>Akron</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Phone</Typography>
                        <Typography variant='h6'>012312939129</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Zip Code</Typography>
                        <Typography variant='h6'>54231</Typography>
                    </Stack>

                    <Stack direction="column">
                        <Typography variant='caption'>Role Code</Typography>
                        <Typography variant='h6'>FA</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Extension</Typography>
                        <Typography variant='h6'>2231231</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>State Code</Typography>
                        <Typography variant='h6'>GU</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Contact Name</Typography>
                        <Typography variant='h6'>Stacey Marshall</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Address Line1</Typography>
                        <Typography variant='h6'>12th Street</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Address Line2</Typography>
                        <Typography variant='h6'>Street 4</Typography>
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Typography variant='h5' marginBottom={2} marginTop={4} fontWeight={600}>
                    Rendering Providers
                </Typography>
                <Stack direction="row" gap={6} flexWrap={'wrap'}>
                    <Stack direction="column">
                        <Typography variant='caption'>First Name</Typography>
                        <Typography variant='h6'>Davis</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Last Name</Typography>
                        <Typography variant='h6'>Garcia</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>NPI</Typography>
                        <Typography variant='h6'>2213131231</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>City</Typography>
                        <Typography variant='h6'>Los Angeles</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Phone</Typography>
                        <Typography variant='h6'>4512313123</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Zip Code</Typography>
                        <Typography variant='h6'>54321</Typography>
                    </Stack>

                    <Stack direction="column">
                        <Typography variant='caption'>Address Line1</Typography>
                        <Typography variant='h6'>5th avenue</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Address Line2</Typography>
                        <Typography variant='h6'>7th avenue</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>State Code</Typography>
                        <Typography variant='h6'>IA</Typography>
                    </Stack>
                    <Stack direction="column">
                        <Typography variant='caption'>Role Code</Typography>
                        <Typography variant='h6'>SJ</Typography>
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Typography variant='h5' marginBottom={2} marginTop={4} fontWeight={600}>
                    Provider Notes
                </Typography>
                <Typography variant='body1'>
                    No provider notes found.
                </Typography>
            </Box>

        </div>
    )
}

export default SCDetails
