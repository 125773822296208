import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { styles } from "./styles"; // Import the styles
import GreenButton from '../../components/claims/GreenButton';
import DefaultHoverButton from '../../components/buttons/DefaultHoverButton';
import { getAllDiscoveryIds } from '../../utils/globalFunctions';
import { getAdjacentElements } from '../../utils/reusableFunctions';
import HorizontalBox from '../../components/shared/HorizontalBox';
import { sharedStyles } from '../../sharedStyles';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { getDiscoveryDetails } from './DiscoveryFunctions';
import { colors } from '../../utils/colors';

const NoInsuranceFound = ({rowData,name,id}) => {
    const [adjacentELems, setAdjacentElems] = useState({ before: null, after: null })
    const navigate = useNavigate();

    const handleSearch = () => {
        navigate('/Discoverytab?preSelectedTab=2')
    }
    const handleEligibility = () => {
        navigate('/EligibilitySearchResults?', { state: { preSelectedTab: 2, rowData } })
    }

    //  const getDiscoveryList = async () =>{
    //         const list = await getAllDiscoveryIds()
    
    //         const adjacentIds = getAdjacentElements(list, id)
    //         setAdjacentElems(adjacentIds)
    //     }

    //     useEffect(()=>{
    //         getDiscoveryList()
    //     },[])

        //   const handleArrowClick = (elem) => {
        //             getDiscoveryDetails(elem)
        //         }
            
    return (
        <div style={styles.container}>
            {/* Header Section */}
                <Box
                    sx={{
                        ...styles.headerContent,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'center', md: 'normal' }
                    }}
                >

                    <div style={styles.headerText}>
                    {/* <HorizontalBox sx={sharedStyles?.gap2}>
            {adjacentELems?.before && <FaChevronCircleLeft
                         style={sharedStyles?.cursorPointerOnly}
                         color={colors?.themeGreen}
                         onClick={()=>handleArrowClick(adjacentELems?.before)}
                     />} */}
                        <SearchIcon/>
                        <Typography variant="h6" color="textPrimary">
                           No Insurance Found for {rowData?.Patient && rowData?.Patient?.trim() ? rowData?.Patient : 'This Patient'}
                        </Typography>

             {/* {adjacentELems?.after && <FaChevronCircleRight
                             style={sharedStyles?.cursorPointerOnly}
                             color={colors?.themeGreen}
                             onClick={()=>handleArrowClick(adjacentELems?.after)}
                         />}
             </HorizontalBox> */}
                       
                    </div>
                    <div style={styles.buttonContainer}>
                        <DefaultHoverButton
                        variant='outlined'
                        onClick={handleEligibility}
                        >
                            CHECK ELIGIBILITY
                        </DefaultHoverButton>
                        <GreenButton
                            onClick={handleSearch}
                        >
                            NEW SEARCH
                        </GreenButton>
                    </div>
                </Box>

            {/* Main Content */}
            <div style={styles.card}>
                        <div style={styles.cardIconContainer}>
                            <SearchIcon style={styles.cardIcon} />
                        </div>

                        <div style={styles.cardTextContainer}>
                            <Typography variant="h5" color="textPrimary" style={styles.cardTitle}>
                                No Insurance Found
                            </Typography>
                            <Typography variant="body1" color="textSecondary" style={styles.cardDescription}>
                                We couldn't find any insurance information for this patient.
                                Please verify the information and try a new search.
                            </Typography>
                        </div>

                        <Box display={'flex'}
                        flexDirection={{xs:'column',md:'row'}}
                        gap={2}
                        >
                            <DefaultHoverButton
                            variant='outlined'
                            onClick={handleEligibility}
                            >
                                Check Eligibility
                            </DefaultHoverButton>
                            <GreenButton
                                onClick={handleSearch}
                            >
                                Try New Search
                            </GreenButton>
                        </Box>
            </div>
        </div>
    );
};

export default NoInsuranceFound;
