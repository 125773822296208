
import React, { useContext } from 'react'
import PatientArForm from './PatientArForm';
import { AetnaArContext, AetnaArContextProvider } from '../../../../../context/priorAuthorizationForms/AetnaArContext';
import SubscriberArForm from './SubscriberArForm';
import DiagnosesArForm from './DiagnosesArForm';
import ProceduresArForm from './ProceduresArForm';
import DatesInfoArForm from './DateInfoArForms';
import PosQuantitiesArForm from './PosQuantitiesArForm';
import ProviderNotesArForm from './ProviderNotesArForm';
import AdmissionInfoArForm from './AdmissionInfoArForm';
import RenderingProviderArForm from './RenderingProvidersArForm';
import RequestingProviderArForm from './RequestingProviderArForm';
import AdditionalServiceForm from './AdditionalServiceForm';
import FormDetails from '../../components/FormDetails';

const AetnaArForms = () => {
    return (
        <AetnaArContextProvider>
            <AllAetnaForms  />
        </AetnaArContextProvider>
    )
}

export default AetnaArForms

const AllAetnaForms = () => {
    const {selectedTab,patient,diagnosesInfo,proceduresInfo,subscriber,providerNotes,serviceInfo,renderingProviders,requestingProvider,setSelectedTab,handleArFormSubmit,additionalServiceInfo,admissionInfo,} = useContext(AetnaArContext)
    const forms = [
        <PatientArForm />,
        <SubscriberArForm/>,
        <DiagnosesArForm/>,
        <ProceduresArForm/>,
        <DatesInfoArForm/>,
        <PosQuantitiesArForm/>,
        <ProviderNotesArForm/>,
        <AdmissionInfoArForm/>,
        <RenderingProviderArForm/>,
        <RequestingProviderArForm/>,
        <AdditionalServiceForm/>,
        <FormDetails
        data={{
            patient,
            serviceType:serviceInfo?.serviceType,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:[providerNotes],
            serviceInfo,
            requestingProvider:requestingProvider,
            renderingProvider:renderingProviders,
            additionalServices:additionalServiceInfo,
            admissionInfo
        }}
        setSelectedTab={setSelectedTab}
        onSubmit={handleArFormSubmit}
        title={'For Aetna (AR)'}
        tabNums={{patient:0,subscriberInfo:1,diagnosesInfo:2,proceduresInfo:3,datesInfo:4,serviceInfo:5,providerNotes:6,admissionInfo:7,renderingProviders:8,requestingProvider:9,additionalServices:10}}
        
        // isLoading={isLoading}
        />
        
    ];

    return forms[selectedTab] || null;
};