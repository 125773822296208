import { createContext, useEffect, useState } from "react";
import { aetnaDefaultData, aetnaHsPosOptions, aetnaHsRequestingForm, aetnaSrDefaultData, anthemCaDefaultData, roleCodesForPosHs } from "../../utils/staticData";
import { ymdDateFormat } from "../../utils/DateFunctions";
import { submitPriorAuthAvailityForm } from "../../services/PayerService";
;

const AetnaHsContext = createContext();


const AetnaHsContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        firstName:'',
        lastName:'',
        contactName:'',
        city:'',
        roleCode:'',
        stateCode:'',
        addressLine1:'',
        addressLine2:'',
        phone:'',
        zipCode:'',
        npi:''
      }]
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(aetnaSrDefaultData?.patientInfo)
    const [providerNotes, setProviderNotes] = useState('')
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [subscriber, setSubscriber] = useState(aetnaDefaultData?.subscriberInfo)
    const [serviceInfo, setServiceInfo] = useState({placeOfServiceCode:'',serviceType:''})
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [requestingProvider,setRequestingProvider] = useState(aetnaHsRequestingForm)
    const [isLoading, setIsLoading] = useState(false)
    const [onceCompleted, setOnceCompleted] = useState(false)
    const [filteredRoleCodes, setFilteredRoleCodes] = useState([])
   

    const handleHsFormSubmit = async() => {
        try{
            setIsLoading(true)
            const payload = {
                requiredFields:{
                    patientFirstName:patient?.firstName || '',
                    patientMiddleName:null,
                    patienLastName:patient?.lastName || '',
                    patientDoB: patient?.birthDate ? ymdDateFormat(patient?.birthDate) : null,
    
                },
                configFields:{  patient:{
                    suffix: patient?.suffix || '',
                    firstName: patient?.firstName || '',
                    lastName: patient?.lastName || '',
                    birthDate: ymdDateFormat(patient?.birthDate) || null,
                    genderCode: aetnaDefaultData?.patientGenders?.find(e => e?.label === patient?.genderCode)?.value,
                    subscriberRelationshipCode:aetnaDefaultData?.patientRelationToSubscriber?.find(e => e?.label === patient?.subscriberRelationshipCode)?.value
                    
                },
                icdCodeIds:diagnosesInfo?.map(e => e?.id),
                cptCodeIds:proceduresInfo?.map(e => e?.id),
                subscriber:{
                    suffix:subscriber?.suffix || '',
                    lastName:subscriber?.lastName || '',
                    memberId:subscriber?.memberId || '',
                    firstName:subscriber?.firstName || ''
                },
                providerNotes: [{message:providerNotes}],
                requestTypeCode:'HS',
                serviceLevelCode:aetnaDefaultData?.serviceLevelCodeOptions?.find(e => e?.value === serviceInfo?.serviceType)?.code,
                placeOfServiceCode:anthemCaDefaultData?.pos?.find(e => e?.value === serviceInfo?.placeOfServiceCode)?.code,
                renderingProviders:renderingProviders?.map(elem => {
                    const {npi, city, phone, zipCode, lastName, roleCode, firstName, stateCode, contactName, addressLine1, addressLine2} = elem
                    return{
                        npi,
                        roleCode,
                        ...(firstName && {firstName}),
                        ...(city && {city}),
                        ...(phone && {phone}),
                        ...(zipCode && {zipCode}),
                        ...(lastName && {lastName}),
                        ...(stateCode && {stateCode}),
                        ...(contactName && {contactName}),
                        ...(addressLine1 && {addressLine1}),
                        ...(addressLine2 && {addressLine2}),
                    }
                }),
                requestingProvider:{
                    phone:requestingProvider?.phone,
                    npi:requestingProvider?.npi,
                    roleCode:requestingProvider?.roleCode ===  "Provider" ? '1P' : "FA",
                    contactName:requestingProvider?.contactName,
                    ...(requestingProvider?.city && {city:requestingProvider?.city}),
                    ...(requestingProvider?.zipCode && {zipCode:requestingProvider?.zipCode}),
                    ...(requestingProvider?.lastName && {lastName:requestingProvider?.lastName}),
                    ...(requestingProvider?.extension && {extension:requestingProvider?.extension}),
                    ...(requestingProvider?.firstName && {firstName:requestingProvider?.firstName}),
                    ...(requestingProvider?.stateCode && {stateCode:requestingProvider?.stateCode}),
                    ...(requestingProvider?.addressLine1 && {addressLine1:requestingProvider?.addressLine1}),
                    ...(requestingProvider?.addressLine2 && {addressLine2:requestingProvider?.addressLine2}),
                }}
            }
            const postSubmission = await submitPriorAuthAvailityForm(payload)
        }
        catch(e){
            console.log(e)
        }
        finally{
            setIsLoading(false)
        }
    }
   
    useEffect(() => {
        if (serviceInfo?.placeOfServiceCode) {
            setRenderingProviders(prev => prev?.map(e => {
                return(
                    {...e,roleCode:''}
                )
            }))
          const foundPosCode = aetnaHsPosOptions?.find(e => e?.value === serviceInfo?.placeOfServiceCode)?.code;
          const foundRoleCodes = roleCodesForPosHs?.find(e => e?.posCodes?.some(posCode => posCode === foundPosCode));
          setFilteredRoleCodes(foundRoleCodes?.roleCodes || []);
        }
      }, [serviceInfo, aetnaHsPosOptions, roleCodesForPosHs]);
      
    return (
        <AetnaHsContext.Provider value={{ 
            selectedTab, setSelectedTab,
            patient, setPatient,
            providerNotes, setProviderNotes,
            diagnosesInfo, setDiagnosesInfo,
            handleHsFormSubmit,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            serviceInfo, setServiceInfo,
            renderingProviders, setRenderingProviders,
            defaultRenderingProvider,
            requestingProvider,setRequestingProvider,
            isLoading,
            onceCompleted, setOnceCompleted,
            filteredRoleCodes
            }}>
            {children}
        </AetnaHsContext.Provider>
    )
};


export { AetnaHsContextProvider, AetnaHsContext };