import React, { useContext, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
    Chip,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { sharedStyles } from '../../../../../sharedStyles';
import {  arNursingCodes, posArFields, } from '../../../../../utils/staticData';
import { styles } from '../../components/styles';
import CommonNextButton from '../../components/CommonNextButton';
import { AetnaArContext } from '../../../../../context/priorAuthorizationForms/AetnaArContext';

const AdditionalServiceForm = () => {
    const [errors, setErrors] = useState({
        type:false

    })
    const [showAllFields, setShowAllFields] = useState(false);
    const { setSelectedTab, additionalServiceInfo, setAdditionalServiceInfo , onceCompleted, setOnceCompleted} = useContext(AetnaArContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field === 'additionalServiceType') {
            setAdditionalServiceInfo((prev) => {
                const currentType = prev?.type || []; 
                if (currentType.includes(value)) {
                    console.log(currentType, value)
                    return prev;
                }
                return {
                    ...prev,
                    type: value,
                };
            });
        }


        else {
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setAdditionalServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
    };

    const handleSubmit = () => {

        if (!additionalServiceInfo?.type?.length) {

            setErrors({
                type: !additionalServiceInfo?.quantity ? 'Required Field' : false,
            });
            return
        }

        else {
            setErrors({ type: false });
            setSelectedTab((prev) => prev + 1)
            setOnceCompleted(11)
        }
    };

    const handleRemove = (valueToRemove) => {
        setAdditionalServiceInfo((prev) => ({
            ...prev,
            type: prev.type.filter((value) => value !== valueToRemove),
        }));
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Additional Services Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={showAllFields ? 6 : 12}>
                        <FormControl fullWidth required
                        error={errors?.type}
                        >
                            <InputLabel>Additional Service Type</InputLabel>
                            <Select
                                multiple
                                value={additionalServiceInfo?.type || []}
                                label="Additional Service Type"
                                onChange={(e) => handleChange(e, 'additionalServiceType')}
                                renderValue={() => additionalServiceInfo?.type?.join(',')}

                            >
                                {posArFields?.map((item) => (
                                    <MenuItem
                                        key={item?.code}
                                        value={item?.value}
                                        disabled={
                                            additionalServiceInfo?.type?.length >= 13 &&
                                            !additionalServiceInfo?.type?.includes(item?.value)
                                        }
                                    >
                                        {item?.value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.type && errors?.type}
                            </FormHelperText>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                                {additionalServiceInfo?.type?.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={() => handleRemove(value)}
                                    />
                                ))}
                            </Box>
                        </FormControl>

                    </Grid>

                    {showAllFields && <Grid item xs={12} md={6}>
                        <FormControl fullWidth >
                            <InputLabel>Nursing Home Residential Status</InputLabel>
                            <Select
                                value={additionalServiceInfo?.nursingCode}
                                label="Nursing Home Residential Status"
                                onChange={(e) => handleChange(e, 'nursingCode')}
                            >
                                {arNursingCodes?.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>}



                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
        </Paper>
    );
};

export default AdditionalServiceForm;
