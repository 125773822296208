
import React, { useContext } from 'react'
import { AnthemGaScContext, AnthemGaScContextProvider } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'
import PatientAnthemScForm from './PatientAnthemScForm'
import DatesInfoAnthemGaScForm from './DatesInfoAnthemScForm'
import ProceduresAnthemGaScForm from './ProceduresAnthemGsScForm'
import DiagnosesAnthemGaScForm from './DiagnosesAnthemGaScForm'
import SubscriberAnthemGaHsForm from './SubscriberAnthemGsScForm'
import ProviderNotesAnthemGaScForm from './ProviderNotesAnthemGaScForm'
import ServicesInfoAnthemGaScForm from './ServicesInfoAnthemGaScForm'
import RenderingProvidersAnthemGaScForm from './RenderingProvidersAnthemGaScForm'
import RequestingProviderAnthemGaScForm from './RequestingProviderAnthemGaScForm'
import FormDetails from '../../components/FormDetails'

const AnthemGaScForms = ({ title }) => {
    return (
        <AnthemGaScContextProvider>
            <AllAetnaForms title={title} />
        </AnthemGaScContextProvider>
    )
}

export default AnthemGaScForms

const AllAetnaForms = ({ title }) => {

    const { 
        selectedTab, 
        patient,
        datesInfo,
        diagnosesInfo,
        proceduresInfo,
        subscriber,
        providerNotes,
        serviceInfo,
        renderingProviders,
        requestingProvider,
        setSelectedTab
    } = useContext(AnthemGaScContext);

    const forms = [
        <PatientAnthemScForm />,
        <DatesInfoAnthemGaScForm/>,
        <ProceduresAnthemGaScForm/>,
        <DiagnosesAnthemGaScForm/>,
        <SubscriberAnthemGaHsForm/>,
        <ProviderNotesAnthemGaScForm/>,
        <ServicesInfoAnthemGaScForm/>,
        <RenderingProvidersAnthemGaScForm/>,
        <RequestingProviderAnthemGaScForm/>,
        <FormDetails data={{
            patient,
            datesInfo,
            serviceInfo,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:[providerNotes],
            serviceInfo,
            requestingProvider,
            renderingProvider:renderingProviders,
        }}
        setSelectedTab={setSelectedTab}

        tabNums={{
            patient:0,
            datesInfo:1,
            diagnosesInfo:3, proceduresInfo:2,subscriberInfo:4,providerNotes:5,serviceInfo:6,renderingProviders:7,requestingProvider:8}}
        title={`for ${title} (SC)`}
        />
            
    ];

    return forms[selectedTab] || null;
};