import React, { useContext } from 'react'
import DefaultAetnaSubscribersForm from '../../components/Aetna/DefaultAetnaSubscribersForm'
import { smplyHsMemberIdHelperText } from '../../../../../utils/staticData'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'

const SubscriberAnthemGaHsForm = () => {
    const {setSelectedTab, subscriber, setSubscriber, onceCompleted} = useContext(AnthemGaHsContext)
  return (
    <DefaultAetnaSubscribersForm
    subscriberInfo={subscriber}
    setSubscriberInfo={setSubscriber}
    setSelectedTab={setSelectedTab}
    noSuffix
    memberIdFieldName='smplyHsMemberId'
    onceCompleted={onceCompleted}
    helperText={smplyHsMemberIdHelperText}
    />
  )
}

export default SubscriberAnthemGaHsForm