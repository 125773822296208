import React, { useContext, useState } from 'react'
import ImpServicesForm from '../../components/ServicesInfoForms/ImpServicesForm'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'
import { serviceInfoErrorsAnthem } from '../../../../../utils/staticData'

const ServicesInfoAnthemGaHsForm = () => {
    const {setSelectedTab, serviceInfo, setServiceInfo, onceCompleted} = useContext(AnthemGaHsContext)
     const [errors, setErrors] = useState(serviceInfoErrorsAnthem)
  return (
   <ImpServicesForm
   errors={errors}
   setErrors={setErrors}
   setSelectedTab={setSelectedTab}
   serviceInfo={serviceInfo}
   setServiceInfo={setServiceInfo}
   onceCompleted={onceCompleted}
   
   />
  )
}

export default ServicesInfoAnthemGaHsForm