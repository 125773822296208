

import React, { useContext } from 'react'
import DefaultHsPatientForm from '../../components/SilverSummitHealthPlanHs/DefaultHsPatientForm'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'

const PatientAnthemScForm = () => {
    const {  setSelectedTab, patient, setPatient,onceCompleted} = useContext(AnthemGaScContext)
  return (
    <DefaultHsPatientForm
    setSelectedTab={setSelectedTab} 
    formData={patient} 
    setFormData={setPatient} 
    noBack 
    importantFields={['lastName', 'firstName', 'birthDate']}
    fieldsToHide={['suffix']}
    dateLimits={{min:'1870-01-01',max:'2024-12-25'}}
    onceCompleted={onceCompleted}
/>

  )
}

export default PatientAnthemScForm