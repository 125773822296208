import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { sharedStyles } from '../../sharedStyles'
import { useLocation } from 'react-router-dom'
import UseWidth from '../../custom-hooks/UseWidth'
import { AgGridReact } from 'ag-grid-react'

const SearchPaResults = () => {
    const [rowData, setRowData] = useState(null);
    const { width } = UseWidth();
    const { state: { data: { data: { id, patientId, isSubscriberPatient, status, paStatus } } } } = useLocation();



    const veiwPayer = ({ data }) => {
        console.log(data)
        return (
            <div className="flex">
                <Button variant='contained' onClick={() => console.log('sending')} color='success' size='small' sx={sharedStyles?.iconCell}>
                    View Details
                </Button>
            </div>
        )
    };


    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: false },
            { headerName: 'Patient ID', field: 'patientId', filter: true, width: 200, resizable: false },
            {
                headerName: 'Is Subscriber Patient', field: 'isSubscriberPatient', filter: true, width: 400, resizable: true,
            },
            { headerName: 'Status', field: 'status', width: 400, filter: true, resizable: true },
            { headerName: 'PA Status', field: 'paStatus', width: 400, filter: true, resizable: true },
            { headerName: 'Details', field: 'Details', width: 200, filter: true, resizable: false, cellRenderer: veiwPayer },
        ];
    };

    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            Loading
        </span>
    `
    }



    useEffect(() => {
        const rowsData = [{
            id: id || 'N/A',
            patientId: patientId || 'N/A',
            isSubscriberPatient: isSubscriberPatient || 'N/A',
            status: status || 'N/A',
            paStatus: paStatus || 'N/A'
        }]

        setRowData(rowsData)
    }, [])


    return (

        <Box className="ag-theme-quartz"
            sx={[sharedStyles?.responsiveTableHeight, sharedStyles?.wFull]}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Cpt Codes Found!'
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
            />
        </Box>

    )
}

export default SearchPaResults
