import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext';
import CommonNextButton from '../../components/CommonNextButton';
import { aetnaDefaultData, aetnaSrDefaultData, anthemCaDefaultData } from '../../../../../utils/staticData';

const RenderingProviderCaForm = () => {

    const {
        renderingProviders,
        setRenderingProviders,
        setSelectedTab,
        defaultRenderingProvider,
        handleSrFormSubmit
    } = useContext(AnthemCaContext)
    const [errors, setErrors] = useState([]);
    const [showAllFields, setShowAllFields] = useState(false);

    const handleChange = (index, field) => (event) => {
        const { value } = event.target;

        // Validation using regex
        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== '' && !regex.test(value)) {
            return;
        }

        // Update the renderingProviders state
        const newProcedures = [...renderingProviders];
        newProcedures[index][field] = value;
        setRenderingProviders(newProcedures);

        // Reset the error state for this field after changing
        const newErrors = [...errors];
        newErrors[index] = { ...newErrors[index], [field]: false };
        setErrors(newErrors);
    };


    const addProcedure = () => {
        if (renderingProviders.length < 14) {
            setRenderingProviders([...renderingProviders, ...defaultRenderingProvider]);
            setErrors([...errors, {}]);
        } else {
            toast.error('Maximum 14 renderingProviders allowed');
        }
    };

    const removeProcedure = (index) => {
        if (renderingProviders.length > 1) {
            const newProcedures = [...renderingProviders];
            newProcedures.splice(index, 1);
            setRenderingProviders(newProcedures);

            const newErrors = [...errors];
            newErrors.splice(index, 1); // Remove error for the removed procedure
            setErrors(newErrors);
        }
    };

    const handleSubmit = () => {
        const newErrors = renderingProviders.map((elem, index) => ({
            roleCode: !elem.roleCode,
            firstName: !elem?.firstName,
            lastName:!elem?.lastName,
            fax: elem?.fax && elem?.fax?.length < 10,
            city: elem?.city && elem?.city?.length < 2,
            phone: elem?.phone && elem?.phone?.length < 10,
            zipCode: (elem?.zipCode && elem?.zipCode?.length < 5) || (elem?.zipCode && elem?.zipCode?.length > 5 && elem?.zipCode?.length < 9),
            addressLine1: elem?.addressLine1 && elem?.addressLine1?.length < 2,
            addressLine2: elem?.addressLine2 && elem?.addressLine2?.length < 2,
        }));

        setErrors(newErrors);

        const failedValidation = newErrors.some(error =>
            error.roleCode || error.firstName || error?.fax || error?.city || error?.phone || error?.zipCode || error?.addressLine1 || error?.addressLine2 || error?.lastName
        );

        if (failedValidation) {
            toast.error('Please fill in all fields with proper information');
            return;
        }
        handleSrFormSubmit()
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Rendering Providers Information
                </Typography>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>

                {renderingProviders?.map((renderingProvider, index) => (
                    <Box key={index} sx={styles?.commonPaperContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <Typography variant="h6">Rendering Provider {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={() => removeProcedure(index)}
                                    disabled={renderingProviders?.length === 1}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>

                            {/* First Name */}
                            <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                <TextField
                                    fullWidth
                                    label="Rendering Provider First Name"
                                    value={renderingProvider.firstName}
                                    onChange={handleChange(index, 'firstName')}
                                    inputProps={{ maxLength: 35 }}
                                    error={!!errors[index]?.firstName}
                                    required
                                    helperText={errors[index]?.firstName ? 'First Name is required' : ''}

                                />
                            </Grid>
                            {showAllFields &&
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Rendering Provider Middle Name"
                                        value={renderingProvider.middleName}
                                        onChange={handleChange(index, 'middleName')}
                                        inputProps={{ maxLength: 25 }}

                                    />
                                </Grid>
                            }

                            <Grid item xs={12} md={showAllFields ? 4 : 6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Rendering Provider Last Name"
                                    value={renderingProvider.lastName}
                                    onChange={handleChange(index, 'lastName')}
                                    inputProps={{ maxLength: 60 }}
                                    error={!!errors[index]?.lastName}
                                    helperText={errors[index]?.lastName ? 'Last Name is required' : ''}

                                />
                            </Grid>


                            {showAllFields && (

                                <>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="City"
                                            value={renderingProvider?.city}
                                            onChange={handleChange(index, 'city')}
                                            error={!!errors[index]?.city}
                                            helperText={errors[index]?.city ? 'Enter a valid city name from 2 to 30 characters in length. (optional)' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel>Rendering Provider State</InputLabel>
                                            <Select
                                                value={renderingProvider?.stateCode}
                                                label="Rendering Provider State"
                                                onChange={handleChange(index, 'stateCode')}
                                            >
                                                {aetnaDefaultData?.aetnRequestingProviderStates?.map(({ value, code }) => (
                                                    <MenuItem key={value} value={code}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Fax"
                                            value={renderingProvider?.fax}
                                            onChange={handleChange(index, 'fax')}
                                            error={!!errors?.[index]?.fax}
                                            helperText={errors[index]?.fax ? 'Fax should be exactly 10 digits long, (optional)' : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Rendering Provider Address Line 1"
                                            value={renderingProvider.addressLine1}
                                            onChange={handleChange(index, 'addressLine1')}
                                            error={!!errors?.[index]?.addressLine1}
                                            helperText={errors[index]?.addressLine1 ? 'Enter a valid mailing address from 2 to 55 characters in length. (optional)' : ''}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Rendering Provider Address Line 2"
                                            value={renderingProvider.addressLine2}
                                            onChange={handleChange(index, 'addressLine2')}
                                            error={!!errors?.[index]?.addressLine2}
                                            helperText={errors[index]?.addressLine2 ? 'Enter a valid mailing address from 2 to 55 characters in length. (optional)' : ''}

                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Phone"
                                            value={renderingProvider?.phone}
                                            onChange={handleChange(index, 'phone')}
                                            error={!!errors?.[index]?.phone}
                                            helperText={errors[index]?.phone ? 'Enter a valid phone number containing 10 numeric digits including area code.(optional)' : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Rendering Provider Tax Id"
                                            value={renderingProvider.taxId}
                                            onChange={handleChange(index, 'taxId')}
                                            inputProps={{ maxLength: 9 }}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Zip Code"
                                            value={renderingProvider.zipCode}
                                            onChange={handleChange(index, 'zipCode')}
                                            error={!!errors?.[index]?.zipCode}
                                            helperText={errors[index]?.zipCode ? 'Enter a valid ZIP code containing five numeric digits. The four-digit extension is optional and must also be numeric. (optional)' : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Rendering Provider Contact Extension"
                                            value={renderingProvider.extension}
                                            onChange={handleChange(index, 'extension')}
                                            inputProps={{ maxLength: 8 }}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Requesting Provider License Number"
                                            value={renderingProvider.licenseNumber}
                                            onChange={handleChange(index,'licenseNumber')}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Requesting Provider License State</InputLabel>
                                            <Select
                                                value={renderingProvider.licenseStateCode}
                                                label="Requesting Provider License State"
                                                onChange={handleChange(index,'licenseStateCode')}
                                            >
                                                {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                                                    <MenuItem key={value} value={code}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}


                            <Grid item xs={12}>
                                <FormControl fullWidth error={errors?.[index]?.roleCode}>
                                    <InputLabel>Rendering Provider Role*</InputLabel>
                                    <Select
                                        value={renderingProvider.roleCode}
                                        label="Rendering Provider Role"
                                        onChange={handleChange(index, 'roleCode')}
                                        error={errors?.roleCode}
                                    >
                                        <MenuItem value={'SJ'}>
                                            Service Provider
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText sx={sharedStyles?.errorText}>
                                        {errors?.[index]?.roleCode && 'Rendering provider type is required'}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Box>
                ))}

                <Box sx={{ mb: 4 }}>
                    <IconButton onClick={addProcedure} color="primary">
                        <AddIcon /> Add Procedure
                    </IconButton>
                </Box>
                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />
            </Paper>
        </LocalizationProvider>
    );
};

export default RenderingProviderCaForm;
