import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react'
import { sharedStyles } from '../../sharedStyles';
import { Box } from '@mui/material';
import UseWidth from '../../custom-hooks/UseWidth';

const CdTable = ({data,onRowSelected}) => {
    const [rowData, setRowData] = useState(null);
    const {width} = UseWidth();


    const columnDefs = () => {
        return [
            { headerName: 'ID', field: 'id', filter: true, width: 100, resizable: false },
            { headerName: 'ICD Code', field: 'icdCode', filter: true, width: 200, resizable: false },
            {
                headerName: 'Long Description', field: 'longDescription', filter: true, flex:1, resizable: true,
            },
            { headerName: 'Code Type', field: 'codyType', width: 200, filter: true, resizable: true },
             { headerName: 'Version', field: 'version', width: 100, filter: true, resizable: true},
        ];
    };


    const gridOptions = {
        pagination: true,
        paginationPageSize: width <= 900 ? 20 : 10,
        domLayout: 'paginationPrevNext,paginationPageSize,paginationFirstLast',
        overlayNoRowsTemplate: `
        <span class="ag-overlay-loading-center">
            Loading
        </span>
    `,
    onRowClicked: (event) => {
        if (onRowSelected) {
            onRowSelected(event.data); // Pass the row's data to the provided handler
        }
    }
    };


    useEffect(() => {
        if (data) {
            const rowsData = data?.map(({ icdCode, fullDescription, longDescription, id, version,icdcodeType }) => ({
                id: id || 'N/A',
                icdCode: icdCode || 'N/A',
                fullDescription: fullDescription || 'N/A',
                longDescription: longDescription || 'N/A',
                // shortDescription: shortDescription || 'N/A',
                version: version || 'N/A',
                codyType: icdcodeType || 'N/A'
               
            }))
            setRowData(rowsData)
        } else {
            setRowData([])
        }
    }, [data])

    return (
        <Box className="ag-theme-quartz"
            sx={sharedStyles?.responsiveTableHeight}>
            <AgGridReact
                columnDefs={columnDefs()}
                rowData={rowData}
                gridOptions={gridOptions}
                overlayNoRowsTemplate='No Cpt Codes Found!'
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
            />
        </Box>
    )
}

export default CdTable
