import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, Tabs } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs'
import { useLocation, useNavigate } from 'react-router-dom';
import PayerDetailsCard from '../../components/shared/PayerDetailsCard';
import { BsFillCalendarDateFill } from "react-icons/bs";
import { HiCurrencyDollar } from "react-icons/hi2";
import { MdOutlineNumbers } from "react-icons/md";
import HorizontalBox from '../../components/shared/HorizontalBox';
import { GetPatientClaimStatus } from '../../services/ClaimService';
import GreenButton from '../../components/claims/GreenButton.js';
import ClaimTabDetails from '../../components/claims/ClaimTabDetails.js';
import { FaHandshakeAngle } from "react-icons/fa6";
import { SiInstatus } from "react-icons/si";
import toast from 'react-hot-toast';
import { colors } from '../../utils/colors.js';
import DetailsHeader from '../../components/shared/DetailsHeader.js';
import { AiFillCreditCard } from "react-icons/ai";
import { PiListNumbersFill } from "react-icons/pi"
import  "../../css/ClaimSearchStyles.css";
import {sharedStyles}  from "../../sharedStyles.js";
import { TbActivityHeartbeat } from "react-icons/tb";
import { PiHeartbeatFill } from "react-icons/pi";

function Claimdetails1() {
    const { formWrapper } = styles;
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [claimResponse, setClaimResponse] = useState()
    const [tabs, setTabs] = useState([])
    const [topCardsItems, setTopCardItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const searchId = new URLSearchParams(location.search).get('id');
    const throughResults = new URLSearchParams(location.search).get('searchResult');

    const [topRightButtons] = useState([
        { name: 'Add Patient', action: () => navigate('/patients?preSelectedTab=2') },
        { name: 'New Search', action: () => navigate('/ClaimTab?preSelectedTab=2') },
        { name: 'Back to Results', action: () => throughResults ? navigate(-1) : navigate('/ClaimTab?preSelectedTab=1') }
    ])

    const handleTabChange = (newTabIndex) => {
        setTabIndex(newTabIndex);
    };


    const fetchClaimsData = async () => {
        try {
            setIsLoading(true)
            const {data}  = await GetPatientClaimStatus(searchId);
            // if (data?.claimStatuses?.length) {
                const cardItems = [
                    { name: 'Status', value: data?.claimStatus  ?? 'N/A', icon: <PiHeartbeatFill fill={colors?.themeGreen} /> },
                    { name: 'Payor', value: data?.payer?.info?.name ?? 'N/A', icon: <FaHandshakeAngle fill={colors?.themeGreen} size={20} /> },
                    { name: 'Date of Service', value: data?.serviceDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },
                    { name: 'Adjudication Date', value: data?.claimStatuses?.[0]?.statusInfo?.adjudicationFinalizedDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },
                    { name: 'Charge Amount', value: data?.chargeAmount ?? 'N/A', icon: <AiFillCreditCard fill={colors?.themeGreen} size={20} /> },
                    { name: 'Payer Paid', value: data?.payerPaid ?? 'N/A', icon: <HiCurrencyDollar fill={colors?.themeGreen} size={20} /> },
                    { name: 'Check Number', value: data?.claimStatuses?.[0]?.statusInfo?.checkNumber ?? 'N/A', icon: <PiListNumbersFill fill={colors?.themeGreen} size={20} /> },
                    { name: 'Check Date', value: data?.checkDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },

                ]
                setTopCardItems(cardItems)
            // }
            setClaimResponse(data);
            setIsLoading(false)
        }
        catch (e){
            // toast.error('Failed to get data')
            setIsLoading(false)
            console.log(e)
        }
    };
    useEffect(() => {
        fetchClaimsData();
    }, []);

    useEffect(() => {
        const allTabs = claimResponse?.claimStatuses?.map((elem, index) => {
            return (
                {
                    label: `claim${index + 1}`,
                    value: <ClaimTabDetails data={{ ...claimResponse, claimStatuses: claimResponse?.claimStatuses[index] }} />
                }
            )
        })
        setTabs(allTabs)

    }, [claimResponse])

    return (
        isLoading ?
        <Box className="loadingContainer">
        <CircularProgress color="success" size={22} />
    </Box>
    :
    <Box style={sharedStyles.fullWidth}>
                <DetailsHeader topRightButtons={topRightButtons} 
                name={`${claimResponse?.firstName ?? claimResponse?.subscriber?.firstName} ${claimResponse?.lastName ?? claimResponse?.subscriber?.lastName}`} dob={claimResponse?.dob} message={claimResponse?.apiResponseMessage} 
                pageName={!throughResults && 'ClaimResults1'}
                id={!throughResults && searchId}
                />
        <Box>
            <Box className="marginTopBox">
                <Box className={formWrapper}>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        {topCardsItems?.map((elem, index) => (
                            <Grid item xs={12} sm={6} lg={3} key={index}>
                                <PayerDetailsCard>
                                    {elem?.icon}
                                    <Box className="textAlignCenter">
                                        <Typography className="boldText">{elem?.name}</Typography>
                                        <Typography>{elem?.value}</Typography>
                                    </Box>
                                </PayerDetailsCard>
                            </Grid>
                        ))}
                    </Grid>
                    {!!tabs?.length && (
                        <Box style={sharedStyles.fullWidth}>
                            <Tabs className="marginTopTabs">
                                <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex} />
                            </Tabs>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    </Box>
    

    );
}
export default Claimdetails1;
