import React, { useContext, useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { aetnaDefaultData, aetnaSrDefaultData, anthemCaDefaultData, paFormErrors,  paTextFieldHelperText, paValidDateText } from '../../../../../utils/staticData';
import { sharedStyles } from '../../../../../sharedStyles';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { styles } from '../../Aetna/styles';
import CommonNextButton from '../../components/CommonNextButton';
import TableHeader from '../../../../TableHeader';
import { getPatientDetails } from '../../components/commonFunctions';
import PageLoader from '../../../../loader/PageLoader';
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext';

const PatientCaForm = () => {
    const { setSelectedTab, patient: formData, setPatient: setFormData } = useContext(AnthemCaContext);
    const [errors, setErrors] = useState(anthemCaDefaultData?.patientCaFormDefaultErrors);
    const [showAllFields, setShowAllFields] = useState(false);
    const [patientSelected, setPatientSelected] = useState(null)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        patientSelected?.id && getPatientDetails(patientSelected, setLoader, setFormData, formData)
    }, [patientSelected])

    const handleChange = (field) => (event) => {
        const { value } = event.target;

        const regex = handleChangePaRegexs?.aetna?.[field];
        if (regex && value !== "" && !regex.test(value)) {
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            birthDate: date,
        });
    };

    const validateFields = () => {
        let allErrors = {}
        // destructuring
        const {city,firstName,lastName,zipCode,phone, homePhone, workPhone, addressLine1, addressLine2, birthDate} = formData

        // checking required field
        if(!firstName || !lastName || !birthDate){
            toast.error(paFormErrors?.importantInfo)
            if(!firstName){
                allErrors.firstName = paFormErrors?.firstNameImp
            }
            else allErrors.firstName = false
            if(!lastName){
                allErrors.lastName = paFormErrors?.lastNameImp
            }
            else allErrors.lastName = false
            if(!birthDate){
                allErrors.birthDate = paFormErrors?.lastNameImp
            }
            else allErrors.birthDate = false
            setErrors(allErrors)
            return allErrors
        }

        // checking fields validations
        if(city && city?.length < 2){
            toast.error(paFormErrors?.city)
            allErrors.city = paFormErrors?.cityOptional
        }
        else allErrors.city = false

        if (zipCode) {
            if (zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)) {
              toast.error(paFormErrors?.zipCode)
              allErrors.zipCode = paFormErrors?.zipCodeOptional
            }
            else {
              allErrors.zipCode = false
            }
          }
          else {
            allErrors.zipCode = false
          }
          if(phone && phone?.length < 10){
            toast.error(paFormErrors?.phoneOptional)
            allErrors.phone = paFormErrors?.phoneOptional
          }
        else allErrors.phone = false
        if(homePhone && homePhone?.length < 10){
            toast.error(paFormErrors?.phoneOptional)
            allErrors.homePhone = paFormErrors?.phoneOptional
          }
        else allErrors.homePhone = false
        if(workPhone && workPhone?.length < 10){
            toast.error(paFormErrors?.phoneOptional)
            allErrors.workPhone = paFormErrors?.phoneOptional
          }
        else allErrors.workPhone = false
        if(addressLine1 && addressLine1?.length < 2){
            toast.error(paFormErrors?.addressOptional)
            allErrors.addressLine1 = paFormErrors?.addressOptional
        }
        else allErrors.addressLine1 = false

        if(addressLine2 && addressLine2?.length < 2){
            toast.error(paFormErrors?.addressOptional)
            allErrors.addressLine2 = paFormErrors?.addressOptional
        }
        else allErrors.addressLine2 = false
        setErrors(allErrors)
        return allErrors
    }

    const handleSubmit = () => {
    const validatedFields = validateFields()
    if (!Object.values(validatedFields).some(Boolean)) {
        setSelectedTab((prev) => prev + 1)
      }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Patient Information
                </Typography>

                <div style={sharedStyles?.mt10px}>
                    <TableHeader setFilterPatient={setPatientSelected} idNeeded handleCrossValue={() => setFormData(aetnaSrDefaultData?.patientInfo)} />
                </div>
                {/* Radio Button to Toggle Fields */}
                {loader ?
                    <PageLoader />
                    :
                    <>
                        <RadioGroup
                            value={showAllFields}
                            onClick={(event) => setShowAllFields(!showAllFields)}
                        >
                            <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                        </RadioGroup>

                        <Box sx={styles?.commonPaperContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Patient Last Name"
                                        value={formData.lastName}
                                        onChange={handleChange('lastName')}
                                        inputProps={{
                                            maxLength: 60,
                                        }}
                                        helperText={paTextFieldHelperText}
                                        error={errors?.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Patient First Name"
                                        value={formData.firstName}
                                        onChange={handleChange('firstName')}
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        helperText={paTextFieldHelperText}
                                        error={errors?.firstName}
                                    />
                                </Grid>
                                

                                        <Grid item xs={12} md={showAllFields ? 6 : 12}>
                                            <DatePicker
                                                label="Patient Date of Birth"
                                                sx={errors?.birthDate ? sharedStyles?.errorDatePickerStyles : sharedStyles?.defaultDatePickerStyles}
                                                value={formData.birthDate}
                                                onChange={handleDateChange}
                                                minDate={dayjs('1870-01-01')}
                                                maxDate={dayjs('2024-12-14')}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        helperText: paValidDateText,
                                                        error: !!errors?.birthDate,
                                                        required: true,
                                                        inputProps: { readOnly: true },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {showAllFields &&
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>Patient Gender</InputLabel>
                                                <Select
                                                    value={formData.genderCode}
                                                    label="Patient Gender"
                                                    onChange={handleChange('genderCode')}
                                                >
                                                    {aetnaDefaultData?.patientGenders?.map(({ value, label }) => (
                                                        <MenuItem key={value} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Height"
                                                value={formData.height}
                                                onChange={handleChange('height')}
                                                inputProps={{ maxLength: 10 }}
                                                error={errors?.height}
                                                helperText={errors?.height}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Weight"
                                                value={formData.weight}
                                                onChange={handleChange('weight')}
                                                inputProps={{ maxLength: 10 }}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Cell Phone"
                                                value={formData.phone}
                                                onChange={handleChange('phone')}
                                                inputProps={{ maxLength: 10 }}
                                                error={errors?.phone}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Home Phone"
                                                value={formData.homePhone}
                                                onChange={handleChange('homePhone')}
                                                inputProps={{ maxLength: 10 }}
                                                error={errors?.homePhone}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Work Phone"
                                                value={formData.workPhone}
                                                onChange={handleChange('workPhone')}
                                                inputProps={{ maxLength: 10 }}
                                                error={errors?.workPhone}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>Patient Provider State</InputLabel>
                                                <Select
                                                    value={formData.stateCode}
                                                    label="Patient Provider State"
                                                    onChange={handleChange('stateCode')}
                                                >
                                                    {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                                                        <MenuItem key={value} value={code}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient City"
                                                value={formData.city}
                                                onChange={handleChange('city')}
                                                inputProps={{ maxLength: 30 }}
                                                error={errors?.city}
                                                helperText={errors?.city}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Zip Code"
                                                value={formData.zipCode}
                                                onChange={handleChange('zipCode')}
                                                inputProps={{ maxLength: 9 }}
                                                error={errors?.zipCode}
                                                helperText={errors?.zipCode}

                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Address Line 1"
                                                value={formData.addressLine1}
                                                onChange={handleChange('addressLine1')}
                                                inputProps={{ maxLength: 55 }}
                                                error={errors?.addressLine1}
                                                helperText={errors?.addressLine1}

                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Patient Address Line 2"
                                                value={formData.addressLine2}
                                                onChange={handleChange('addressLine2')}
                                                inputProps={{ maxLength: 55 }}
                                                error={errors?.addressLine2}
                                                helperText={errors?.addressLine2}

                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <FormControl fullWidth>
                                                <InputLabel>Patient's Relationship to Subscriber</InputLabel>
                                                <Select
                                                    value={formData.subscriberRelationshipCode}
                                                    label="Patient's Relationship to Subscriber"
                                                    onChange={handleChange('subscriberRelationshipCode')}
                                                >
                                                    {aetnaDefaultData?.patientRelationToSubscriber?.map(({ value, label }) => (
                                                        <MenuItem key={value} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                        <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} />
                    </>
                }
            </Paper>
        </LocalizationProvider>
    );
};

export default PatientCaForm;
