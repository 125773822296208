import { createContext, useState } from "react";
import { aetnaDefaultData,  aetnaSrDefaultData, hsspRenderingProviderDefault, hsspReqProvider, hsspServices, hsSubscriberDefaultData } from "../../utils/staticData";



const SilverSummitHealtPlanHsContext = createContext();


const SilverSummitHealtPlanHsContextProvider = ({ children }) => {

    const defaultRenderingProvider = [{
        fax:'',
        npi:'',
        city:'',
        phone:'',
        taxId:'',
        zipCode:'',
        lastName:'',
        roleCode:'',
        extension:'',
        firstName:'',
        stateCode:'',
        contactName:'',
        addressLine1:'',
        addressLine2:''
     }]
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [patient, setPatient] = useState(aetnaSrDefaultData?.patientInfo)
    const [datesInfo, setDatesInfo] = useState(aetnaDefaultData?.datesInfo);
    const [diagnosesInfo, setDiagnosesInfo] = useState([])
    const [proceduresInfo, setProceduresInfo] = useState([])
    const [subscriber, setSubscriber] = useState(hsSubscriberDefaultData)
    const [providerNotes, setProviderNotes] = useState('')
    const [serviceInfo, setServiceInfo] = useState(hsspServices)
    const [requestingProvider,setRequestingProvider] = useState(hsspReqProvider)
    const [renderingProviders, setRenderingProviders] = useState(defaultRenderingProvider)
    const [onceCompleted, setOnceCompleted] = useState(false)

   
    return (
        <SilverSummitHealtPlanHsContext.Provider value={{ 
            selectedTab, setSelectedTab,
            patient, setPatient,
            datesInfo, setDatesInfo,
            diagnosesInfo, setDiagnosesInfo,
            proceduresInfo, setProceduresInfo,
            subscriber, setSubscriber,
            providerNotes, setProviderNotes,
            serviceInfo, setServiceInfo,
            requestingProvider,setRequestingProvider,
            renderingProviders, setRenderingProviders,
            defaultRenderingProvider,
            onceCompleted, setOnceCompleted
            }}>
            {children}
        </SilverSummitHealtPlanHsContext.Provider>
    )
};


export { SilverSummitHealtPlanHsContextProvider, SilverSummitHealtPlanHsContext };