import React, { useContext } from 'react'
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext'
import DefaultHsPatientForm from '../../components/SilverSummitHealthPlanHs/DefaultHsPatientForm'

const PatientSshpForm = () => {
    const {  setSelectedTab, patient, setPatient,onceCompleted} = useContext(SilverSummitHealtPlanHsContext)
  return (
    <DefaultHsPatientForm
    setSelectedTab={setSelectedTab} 
    formData={patient} 
    setFormData={setPatient} 
    noBack 
    importantFields={['lastName', 'firstName', 'birthDate', 'subscriberRelationshipCode']}
    fieldsToHide={['suffix']}
    dateLimits={{min:'1870-01-01',max:'2024-12-25'}}
    onceCompleted={onceCompleted}
/>

  )
}

export default PatientSshpForm