
import React, { useContext } from 'react'
import { AetnaHsContext, AetnaHsContextProvider } from '../../../../../context/priorAuthorizationForms/AetnaHsContext';
import PatientHsForm from './PatientHsForm';
import ProviderNotesHsForm from './ProviderNotesHsForm';
import DiagnosesHsForm from './DiagnoseHsForm';
import ProceduresHsForm from './ProceduresHsForm';
import SubscriberHsForm from './SubscriberHsForm';
import ServiceHsForm from './ServiceHsForm';
import RenderingProviderHsForm from './RenderingProviderHsForm';
import RequestingProvideHsForm from './RequestingProviderHsForms';
import FormDetails from '../../components/FormDetails';

const AetnaHsForms = ({title}) => {
    return (
        <AetnaHsContextProvider>
            <AllAetnaForms  title={title}/>
        </AetnaHsContextProvider>
    )
}

export default AetnaHsForms

const AllAetnaForms = ({title}) => {
    const {selectedTab,patient,serviceInfo,diagnosesInfo,proceduresInfo,subscriber,providerNotes,requestingProvider,renderingProviders,setSelectedTab,handleHsFormSubmit, isLoading} = useContext(AetnaHsContext)
    const forms = [
        <PatientHsForm />,
        <ProviderNotesHsForm/>,
        <ProceduresHsForm/>,
        <DiagnosesHsForm/>,
        <SubscriberHsForm/>,
        <ServiceHsForm/>,
        <RenderingProviderHsForm/>,
        <RequestingProvideHsForm/>,
        <FormDetails
        data={{
            patient,
            serviceType:serviceInfo?.serviceType,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:[providerNotes],
            serviceInfo,
            requestingProvider,
            renderingProvider:renderingProviders
        }}
        setSelectedTab={setSelectedTab}
        onSubmit={() => handleHsFormSubmit(title)}
        isLoading={isLoading}
        tabNums={{patient:0,providerNotes:1,diagnosesInfo:3,proceduresInfo:2,subscriberInfo:4,serviceInfo:5,renderingProviders:6,requestingProvider:7}}
        title={title ? `for ${title}` : 'for Aetna'}
        />
    ];

    return forms[selectedTab] || null;
};