import React, { useContext } from 'react'
import DefaultAetnaSubscribersForm from '../../components/Aetna/DefaultAetnaSubscribersForm'
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext'

const SubscriberSshpForm = () => {
    const {setSelectedTab, subscriber, setSubscriber, onceCompleted} = useContext(SilverSummitHealtPlanHsContext)
  return (
    <DefaultAetnaSubscribersForm
    subscriberInfo={subscriber}
    setSubscriberInfo={setSubscriber}
    setSelectedTab={setSelectedTab}
    noSuffix
    memberIdFieldName='sshpMemberId'
    onceCompleted={onceCompleted}
    />
  )
}

export default SubscriberSshpForm