import React, { useContext, useState } from 'react'
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext'
import DefaultAetnaDiagnosesForm from '../../components/Aetna/DefaultAetnaDiagnosesForm'
import { getAllCDcodes } from '../../../../../services/pVerify'
import CdTable from '../../../../table/CdTable'
import ServiceInfoList from '../../components/ServicesInfoList'
import toast from 'react-hot-toast'
import { paFormErrors } from '../../../../../utils/staticData'

const DiagnosesHsForm = () => {
    const { diagnosesInfo, setDiagnosesInfo, setSelectedTab, onceCompleted } = useContext(AetnaHsContext)
    const [errors, setErrors] = useState([])

    const handleSubmit = (submit) => {
        if(diagnosesInfo?.length){
            const inCompleteDiagnoses = diagnosesInfo?.filter(e => !e?.qualifierCode)
            if(inCompleteDiagnoses?.length){
                setErrors(diagnosesInfo?.map(e => {
                    return(
                        {
                            qualifierCode : e?.qualifierCode ? false : true
                        }
                    )
                }))
                toast?.error(paFormErrors?.propInfo)
                return
            }
            submit && onceCompleted ? setSelectedTab(onceCompleted) :    setSelectedTab(prev => prev + 1)
        }
        else{
            toast?.error(paFormErrors?.diagnosesImp)
        }
    }

    return (
        
            <DefaultAetnaDiagnosesForm
                fetchCodesApi={getAllCDcodes}
                limit={5}
                TableComponent={CdTable}
                ListComponent={(props) => (
                    <ServiceInfoList
                        serviceInfoArray={props.items}
                        onRemove={(id) => props.onRemove(id)}
                        showQualifierCode
                        errors={errors}
                        setServiceInfoArray={setDiagnosesInfo}
                    />
                )}
                selectedItems={diagnosesInfo}
                setSelectedItems={setDiagnosesInfo}
                setSelectedTab={setSelectedTab}
                onSubmit={handleSubmit}
                onceCompleted={onceCompleted}
             
            />
    )
}

export default DiagnosesHsForm