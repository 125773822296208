import React from 'react';
import { Card, CardContent, Typography, Box, Chip, IconButton } from '@mui/material';
import { styles } from './styles';
import HorizontalBox from '../../../shared/HorizontalBox';
import { sharedStyles } from '../../../../sharedStyles';
import { Edit } from '@mui/icons-material';

const DetailCard = ({ title, children,onEdit }) => (
  <Card 
    elevation={0}
    sx={styles?.detailCardContainer}
  >
    <CardContent>
      <HorizontalBox sx={sharedStyles?.mb2}>
      <Chip 
          label={title}
          sx={styles?.detailCardChip}
        />
        <IconButton onClick={onEdit} >
          <Edit />
        </IconButton>
      </HorizontalBox>
      {children}
    </CardContent>
  </Card>
);

export default DetailCard;
