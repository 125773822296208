import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import toast from 'react-hot-toast';
import { sharedStyles } from '../../../../sharedStyles';
import { styles } from './styles';
import { getAllCDcodes } from '../../../../services/pVerify';
import HorizontalHeadingPlusText from '../../../shared/HorizontalHeadingPlusText'
import CdTable from '../../../table/CdTable';
import PageLoader from '../../../../components/loader/PageLoader';

const CommonDiagnosesForm = ({serviceInfo,setServiceInfo,button}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [cptCodes, setCptCodes] = useState([])



    const getCptCodes = async () => {
        try {
            setIsLoading(true)
            const data = await getAllCDcodes();
            if (data) {
                setCptCodes(data?.data?.data)
            } else {
                setCptCodes([])
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCptCodes();
    }, [])

    const onRowSelected = (rowData) => {
        setServiceInfo(rowData)
    };


    return (

        <>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Diagnoses Information
                </Typography>


                <Box sx={styles?.commonPaperContainer}>
                    {isLoading ?
                        <PageLoader />
                        :
                        (
                            <>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <CdTable data={cptCodes} onRowSelected={onRowSelected} />
                                    </Grid>

                                </Grid>
                                {serviceInfo?.id &&
                                    <div style={sharedStyles?.mt10px}>
                                        <HorizontalHeadingPlusText heading={'Selected Diagnosis'} text={`${serviceInfo?.icdCode}`} />
                                    </div>}

                            </>
                        )}
                </Box>
                {button && button}
            </Paper>
        </>
    );
};

export default CommonDiagnosesForm;
