import { Button, styled } from '@mui/material';
import { colors } from '../../utils/colors';

const DefaultHoverButton = styled(Button)(({ theme, ...props }) => ({
  backgroundColor: colors?.white,
  color: colors?.green,
  '&:hover': {
    backgroundColor: colors?.green,
    color: colors?.white,
  },
  '&.Mui-disabled': {
    backgroundColor: colors?.lightGrayBorder,
    color: colors?.shadedGray,
  },
  // Apply green border when 'outlined' variant is passed
  ...(props.variant === 'outlined' && {
    borderColor: colors?.green,
    borderWidth: '1px',
    '&:hover': {
      backgroundColor: colors?.green,
      borderColor: colors?.green,
      color: colors?.white,
    },
  }),
}));

export default DefaultHoverButton;
