import dayjs from "dayjs"
import { get_GetPatientDataById } from "../../services/addPatient"
import { capitalizedFirstLetter, endStringWithSpace, failedGetApi, joinArrayWithCommas } from "../../utils/reusableFunctions"
import { colors } from "../../utils/colors"
import { GetPatientClaimStatus } from "../../services/ClaimService"
import { SiInstatus } from "react-icons/si"
import { FaHandshakeAngle } from "react-icons/fa6"
import { BsFillCalendarDateFill } from "react-icons/bs"
import { AiFillCreditCard } from "react-icons/ai"
import { HiCurrencyDollar } from "react-icons/hi2"
import { PiListNumbersFill } from "react-icons/pi"
import ClaimTabDetails from "../../components/claims/ClaimTabDetails"
import { dayJsMdyFormat } from "../../utils/DateFunctions"

export const formatGridArray = (array) => {
    const formattedArray = array?.length ? array?.map(({ firstName, lastName, patientInsuranceDetails, appointments, phone, createdDate, id: ID, updatedDate, status, dob }) => {
        return ({
            Date_Added: createdDate ? dayJsMdyFormat(createdDate) : '',
            Appt: !appointments || !appointments.length ? '' : joinArrayWithCommas(appointments),
            Patient: capitalizedFirstLetter(firstName) + ' ' + capitalizedFirstLetter(lastName) || '',
            Phone: phone || '',
            Copay: '',
            Deductible: '',
            Insurance: !patientInsuranceDetails || !patientInsuranceDetails?.length ? '' : patientInsuranceDetails[0],
            Date_Updated: updatedDate ? dayJsMdyFormat(updatedDate) : '',
            ID,
            DOB: dayJsMdyFormat(dob)
        })
    }) : []
    return formattedArray
}

export const clear = (setPatientDetails, setFieldsErr) => {
    setPatientDetails({
        patientMiddleName: "",
        patientFirstName: "",
        patientLastName: "",
        patientDOB: null,
        phoneNumber: "",
        email: null,
        appointmentDate: null,
        insurancename: [],
        memberId: "",
        groupNumber: "",
        policyHolderRelationship: "",
        requestNpi: ""
    });
    setFieldsErr(false);
};

export const fetchPatientDetails = async (setPatientDetails, selectedPatient) => {
    try {
        const patientData = await get_GetPatientDataById(selectedPatient?.id)
        if (patientData?.data) {
            const {
                firstName: patientFirstName,
                lastName: patientLastName,
                middleName,
                dob: patientDOB,
                phone: phoneNumber,
                email,
                patientInsuranceDetails: insuranceName,
                appointments,
                groupNumber,
                memberId,
                policyHolderRelationship
            } = patientData?.data

            setPatientDetails({
                patientLastName,
                patientFirstName,
                patientMiddleName:middleName,
                patientDOB: dayjs(patientDOB),
                phoneNumber,
                email,
                appointmentDate: appointments && appointments?.length ? dayjs(appointments[0]) : null,
                insurancename: insuranceName ?? [],
                memberId,
                groupNumber,
                policyHolderRelationship,
            });
        }
        else {
            failedGetApi()
        }
    }
    catch {
        failedGetApi()
    }
}

export const fieldsErrCheck = (patientFirstName, patientLastName, patientDOB) => {
    return !patientFirstName || !patientLastName || !patientDOB
}

export const optionalFieldsProvidedCheck = (memberId, groupNumber, appointmentDate, insurancename, policyHolderRelationship, phoneNumber, email) => {
    return memberId || groupNumber || appointmentDate || insurancename || policyHolderRelationship || phoneNumber || email
}

export const requiredFieldsProvidedCheck = (patientFirstName, patientLastName, patientDOB) => {
    return patientFirstName && patientLastName && patientDOB
}

export const selectedCardHeading = (cardIndex) => {
    const heading = ['New Patients Added', 'Patients With Insurance', 'Patients Without Insurance']?.find((_, index) => index + 1 === cardIndex)
    return heading
}


export const selectedCardGraphData = (cardIndex, data) => {
    const { labels, datasets } = data
    let arrayLabels = []
    let arrayDataSets = []
    // checking if graph has repeated data added to handle the line graph
    if (labels?.length === 2 && labels?.[0] === labels?.[1]) {
        arrayLabels = [labels?.[0]]
        if (cardIndex === 1) {
            const matchedVal = datasets?.find(elem => elem?.label === 'New Patients Added')
            arrayDataSets = [{ ...matchedVal, data: [matchedVal?.data?.[0]] }]
        }
        if (cardIndex === 2) {
            const matchedVal = datasets?.find(elem => elem?.label === "Patients having insurance")
            arrayDataSets = [{ ...matchedVal, data: [matchedVal?.data?.[0]] }]
        }
        if (cardIndex === 3) {
            const matchedVal = datasets?.find(elem => elem?.label === "Patients missing insurance")
            arrayDataSets = [{ ...matchedVal, data: [matchedVal?.data?.[0]] }]
        }

        return (
            {
                labels: arrayLabels,
                datasets: [{
                    ...arrayDataSets?.[0],
                    borderRadius: 5,
                    barThickness: 8,
                    backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                }]
            }
        )
    }

    // else 
    else {
        if (cardIndex === 1) {
            arrayDataSets = [datasets?.find(elem => elem?.label === 'New Patients Added')]
        }
        if (cardIndex === 2) {
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients having insurance")]
        }
        if (cardIndex === 3) {
            arrayDataSets = [datasets?.find(elem => elem?.label === "Patients missing insurance")]
        }
        return (
            {
                labels,
                datasets: arrayDataSets?.map((elem) => {
                    return (
                        {
                            ...elem,
                            borderRadius: 5,
                            barThickness: 8,
                            backgroundColor: cardIndex === 3 ? colors?.red : cardIndex === 2 ? colors?.yellow : colors?.themeGreen,
                        }
                    )
                })
            }
        )
    }
}


export const getClaimsDetails = async (id, setClaimDetails, setTopCardItems, setTabs, setIsLoading) => {
    setIsLoading(true)
    try {
        const { data } = await GetPatientClaimStatus(id)
        if (data) {

            const cardItems = [
                { name: 'Status', value: data?.claimStatus ?? 'N/A', icon: <SiInstatus fill={colors?.themeGreen} /> },
                { name: 'Payor', value: data?.payer?.info?.name ?? 'N/A', icon: <FaHandshakeAngle fill={colors?.themeGreen} size={20} /> },
                { name: 'Date of Service', value: data?.serviceDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },
                { name: 'Adjudication Date', value: data?.claimStatuses?.[0]?.statusInfo?.adjudicationFinalizedDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },
                { name: 'Charge Amount', value: data?.chargeAmount ?? 'N/A', icon: <AiFillCreditCard fill={colors?.themeGreen} size={20} /> },
                { name: 'Payer Paid', value: data?.payerPaid ?? 'N/A', icon: <HiCurrencyDollar fill={colors?.themeGreen} size={20} /> },
                { name: 'Check Number', value: data?.claimStatuses?.[0]?.statusInfo?.checkNumber ?? 'N/A', icon: <PiListNumbersFill fill={colors?.themeGreen} size={20} /> },
                { name: 'Check Date', value: data?.checkDate ?? 'N/A', icon: <BsFillCalendarDateFill fill={colors?.themeGreen} /> },

            ]

            const allTabs = data?.claimStatuses?.map((elem, index) => {
                return (
                    {
                        label: `claim${index + 1}`,
                        value: <ClaimTabDetails data={{ ...data, claimStatuses: data?.claimStatuses[index] }} />
                    }
                )
            })

            setTopCardItems(cardItems)
            setClaimDetails(data)
            setTabs(allTabs)
        }
    }
    catch (e) {
        console.log(e)
    }
    finally {
        setIsLoading(false)
    }

}