import React, { useContext } from 'react'
import DefaultAetnaSubscribersForm from '../../components/Aetna/DefaultAetnaSubscribersForm'
import { AetnaHsContext } from '../../../../../context/priorAuthorizationForms/AetnaHsContext'

const SubscriberHsForm = () => {
    const {setSelectedTab, subscriber, setSubscriber, onceCompleted} = useContext(AetnaHsContext)
  return (
    <DefaultAetnaSubscribersForm
    subscriberInfo={subscriber}
    setSubscriberInfo={setSubscriber}
    setSelectedTab={setSelectedTab}
    onceCompleted={onceCompleted}
    />
  )
}

export default SubscriberHsForm