
import React, { useContext } from 'react'
import { AnthemGaHsContext, AnthemGaHsContextProvider } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext';
import PatientAnthemGaHsForm from './PatientAnthemGaHsForm';
import DatesInfoAnthemGaHsForm from './DatesInfoAnthemGaHsForm';
import DiagnosesAnthemGaHsForm from './DiagnosesAnthemGaHsForm';
import ProceduresAnthemGaHsForm from './AnthemGaHsProcedures';
import SubscriberAnthemGaHsForm from './SubscriberAnthemGaHsForm';
import ProviderNotesAnthemGaHsForm from './ProviderNotesAnthemGaHsForm';
import ServicesInfoAnthemGaHsForm from './ServicesInfoForm';
import RenderingProviderAnthemGaHsForm from './RenderingProviderAnthemGaHsForm';
import RequestingProviderAnthemGaHsForm from './RequestingProviderAnthemGaHsForm';
import FormDetails from '../../components/FormDetails';
import AdmissionAnthemGaHsForm from './AdmissionAnthemGaHsForm';

const AnthemGaHsForms = ({title}) => {
    return (
        <AnthemGaHsContextProvider>
            <AllForms  title={title}/>
        </AnthemGaHsContextProvider>
    )
}

export default AnthemGaHsForms

const AllForms = ({title}) => {
    const {
        selectedTab, setSelectedTab,
        patient,
        datesInfo,
        diagnosesInfo, 
        proceduresInfo,
        subscriber, 
        providerNotes, 
        serviceInfo,
        requestingProvider,
        renderingProviders,
        admissionInfo,
        
    } = useContext(AnthemGaHsContext)
    const forms = [
        <PatientAnthemGaHsForm/>,
        <DatesInfoAnthemGaHsForm/>,
        <ProceduresAnthemGaHsForm/>,
        <DiagnosesAnthemGaHsForm/>,
        <ProviderNotesAnthemGaHsForm/>,
        <SubscriberAnthemGaHsForm/>,
        <ServicesInfoAnthemGaHsForm/>,
        <RenderingProviderAnthemGaHsForm/>,
        <RequestingProviderAnthemGaHsForm/>,
        <AdmissionAnthemGaHsForm/>,
        <FormDetails
        data={{
            patient,
            serviceType:serviceInfo?.serviceType,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:[providerNotes],
            serviceInfo:{...serviceInfo,
                reportType:serviceInfo?.reportType,
                serviceLevel:serviceInfo?.serviceLevel
            },
            requestingProvider,
            renderingProvider:renderingProviders,
            datesInfo,
            admissionInfo:{source:admissionInfo}
        }}
        title={title ? `for ${title} (HS)` : ''}
        setSelectedTab={setSelectedTab}
        tabNums={{patient:0,datesInfo:1,diagnosesInfo:3,proceduresInfo:2,subscriberInfo:5,providerNotes:4,serviceInfo:6,requestingProvider:8,renderingProviders:7,admissionInfo:9}}
        onSubmit={()=>console.log('submit')}
        // isLoading={isLoading}
        />,
    ]
    return forms[selectedTab] || null;
};