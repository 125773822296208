import React  from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import CommonNextButton from '../CommonNextButton';

const DefaultAdmissionForm = ({setSelectedTab, admissionInfo, setAdmssionInfo, onceCompleted, handleSubmit}) => {

  
const handleChange = (event) => {
    const { value } = event.target;
    setAdmssionInfo(value); 
  };

    // const handleSubmit = (submit) => {
    //   submit ? setSelectedTab(onceCompleted):   setSelectedTab((prev) => prev + 1)
    // }
  return (
    <>
    <Paper elevation={3} sx={styles?.parentPaperContainer}>
        <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
            Admission Information
        </Typography>

        
            <Box sx={styles?.commonPaperContainer}>
            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel>Admission Source</InputLabel>
                                    <Select
                                        value={admissionInfo}
                                        label="Admission Source"
                                        onChange={handleChange}
                                    >
                                                <MenuItem value={"Court/Law Enforcement"}>
                                                Court/Law Enforcement
                                                </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab}  onceCompleted={onceCompleted}/>
    </Paper>
</>
  )
}

export default DefaultAdmissionForm