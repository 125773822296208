
import React, { useContext } from 'react'
import { AnthemCaContext, AnthemCaContextProvider } from '../../../../../context/priorAuthorizationForms/AnthemCaContext'
import RequestingProviderAnthemCa from './RequestingProviderAnthemCa'
import SubscriberCaForm from './SubscriberCaForm'
import PatientCaForm from './PatientCaForm'
import POSQuantitiesCaForm from './PosQuantitiesCaForm'
import DatesCaForm from './DatesCaForm'
import DiagnosesCaForm from './DiagnosesCaForm'
import ProceduresCaForm from './ProceduresCaForm'
import PrescriptionCaForm from './PrescriptionsCaForm'
import ProviderNotesCaForm from './ProviderNotesCaForm'
import ServiceLevelCaForm from './ServiceLevelCaForm'
import RenderingProviderCaForm from './RenderingProvidersCaForm'

const AnthemCaForms = ({patientBasicDetails}) => {
    return (
        <AnthemCaContextProvider>
            <AllAnthemCaForms  patientBasicDetails={patientBasicDetails}/>
        </AnthemCaContextProvider>
    )
}

export default AnthemCaForms

const AllAnthemCaForms = () => {

    const { selectedTab } = useContext(AnthemCaContext);
    const forms = [
        <RequestingProviderAnthemCa/>,
        <PatientCaForm/>,
        <SubscriberCaForm/>,
        <POSQuantitiesCaForm/>,
        <DatesCaForm/>,
        <DiagnosesCaForm/>,
        <ProceduresCaForm/>,
        <PrescriptionCaForm/>,
        <ProviderNotesCaForm/>,
        <ServiceLevelCaForm/>,
        <RenderingProviderCaForm/>,
    ];

    return forms[selectedTab] || null;
};