import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Tabs } from '@mui/material';
import styles from '../../css/addpatient.module.css'
import { useState, useEffect } from 'react';
import MyTabs from '../../components/tabs/MyTabs'
import { useLocation, useNavigate, } from 'react-router-dom';
import Plandetail from './Plandetail';
import { get_GetInusuranceDiscoveryByPatientId, refreshDiscoveryDetails } from '../../services/registration';
import { CgCalendarDates } from "react-icons/cg";
import { IoIosJournal } from "react-icons/io";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { FaCcAmazonPay } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { colors } from '../../utils/colors';
import { FaIdCard } from "react-icons/fa";
import DetailsHeader from '../../components/shared/DetailsHeader';
import { SiInstatus } from 'react-icons/si';
import {fetchDiscoveryDetails, fetchPatientData, handleDiscoveryRefresh} from './DiscoveryFunctions'
import { sharedStyles } from '../../sharedStyles';
import { dayJsMdyFormat } from '../../utils/DateFunctions';
import { capitalizedFirstLetter } from '../../utils/reusableFunctions';
import { handleExportPDF } from './exportPDF';  
import { TbActivityHeartbeat } from "react-icons/tb";
import { PiHeartbeatFill } from "react-icons/pi";
import PageLoader from '../../components/loader/PageLoader'
import NoInsuranceFound from './NoInsuranceFound';


function Discoverydetails1() {
  const { formWrapper } = styles;
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const [searchdata, setsearchdata] = useState([]);
  const [tabData, setTabData] = useState()
  const [detailData,setDetailData] = useState()
  const [detailsLoading, setDetailsLoading] = useState(false)
  const [discoveryLoading, setDiscoveryLoading] = useState(false)
  const [refetchDetails, setRefetchDetails] = useState(false)
  const [refetchDiscovery, setRefetchDiscovery] = useState(false)
  const [loaders,setLoaders] = useState({refreshing:false})

  // route handlers
  const rowDataString = new URLSearchParams(location.search).get('rowData');
  const through = new URLSearchParams(location.search).get('through');
  const rowData = rowDataString ? JSON.parse(decodeURIComponent(rowDataString)) : null;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDiscoveryDetails(rowData,setsearchdata,setTabData,setDiscoveryLoading)
  }, [refetchDiscovery]);

  useEffect(()=>{
    fetchPatientData(rowData,setDetailData,setDetailsLoading)
  },[refetchDetails])

  const exportData = async () => {
    if (rowData && rowData.ID) {
      console.log('Initiating export for patient ID:', rowData.ID);
      try {
        await handleExportPDF(rowData.ID);
        console.log('Export completed successfully');
      } catch (error) {
        console.error('Export failed:', error);
      }
    } else {
      console.error('No patient ID available for export');
     
    }
  };
  // states using route handlers
  const [topRightButtons] = useState(() => {
    const buttons = [
      {
        name: 'Check Eligibility',
        action: () => navigate('/EligibilitySearchResults?', { state: { preSelectedTab: 2, rowData } })
      },
      { 
        name: 'New Search', 
        action: () => navigate('/discoveryTab?preSelectedTab=2') 
      },
      { 
        name: 'Back to Results', 
        action: () => navigate(through === 'Discovery' ? -1 : '/discoveryTab?preSelectedTab=1') 
      },
      { 
        name: 'Export', 
        action: exportData 
      },
    ];
  
    if (searchdata?.isRefreshable) {
      buttons.unshift({ name: 'Refresh', action: () => handleDiscoveryRefresh(rowData?.TableId || rowData?.id,setLoaders,setRefetchDiscovery)});
    }
  
    return buttons;
  });


  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const tabs =
    [
      { label: "Plan Detail", value: <Plandetail tabname="detail" rowData={rowData} data={tabData} detailData={detailData?.data?.planDetails} detailsLoading={detailsLoading} setRefetchDetails={setRefetchDetails}/> },
      { label: "Coverage Details", value: <Plandetail tabname="coverage" rowData={rowData} data={tabData} detailData={detailData?.data?.coverageDetails} setRefetchDetails={setRefetchDetails}/> },
      { label: "DemographicInfo", value: <Plandetail tabname="DemographicInfo" rowData={rowData} data={tabData} detailData={detailData?.data?.demographicInfo} setRefetchDetails={setRefetchDetails}/> },
      { label: "In-Network", value: <Plandetail tabname="Innetwork" rowData={rowData} data={tabData} detailData={detailData?.data?.inNetworkDetails} setRefetchDetails={setRefetchDetails}/>, changeTab: 3 },
      {
        label: "Out-of-Network", value: <Plandetail tabname="Outofnetwork" rowData={rowData} data={tabData} detailData={detailData?.data?.outOfNetworkDetails} setRefetchDetails={setRefetchDetails}/>, changeTab: 4,
      },
      {
        label: "Speciality", value: <Plandetail tabname="Speciality" rowData={rowData} data={tabData} detailData={detailData?.data?.planDetails}/>, changeTab: 5,
      },
    ]
  const insurancePayer = (insurance) =>{
   return !insurance ? 'N/A' : `${capitalizedFirstLetter(insurance)}`
  }

  const topCardsItems = [
    { name: 'Status', value: searchdata?.searchStatus, icon: <PiHeartbeatFill fill={colors?.themeGreen} /> },
    { name: 'Network', value: searchdata.isProviderInNetwork || 'N/A', icon: <PiShareNetworkDuotone fill={colors?.themeGreen} /> },
    { name: 'Payer', value: insurancePayer(rowData.Insurance), icon: <FaCcAmazonPay fill={colors?.themeGreen} /> },
    { name: 'Member Id', value: searchdata.memberId || 'N/A', icon: <FaIdCard fill={colors?.themeGreen} /> },
    { name: 'Copay', value: rowData.Copay || 'N/A', icon: <FaHandsHelping fill={colors?.themeGreen} /> },
    { name: 'Deductible', value: searchdata.deductibleData || 'N/A', icon: <FaMinusCircle fill={colors?.themeGreen} /> },
    { name: 'Plan Begin Date', value: dayJsMdyFormat(searchdata.effectiveDate) || 'N/A', icon: <CgCalendarDates fill={colors?.themeGreen} /> },
    { name: 'Plan Type', value: searchdata.policyType || 'N/A', icon: <IoIosJournal fill={colors?.themeGreen} /> },
  ]
  return (
    <Box sx={{ width: { xs: '100%' } }}>
     {detailsLoading || discoveryLoading ?
    <PageLoader/>
    :
   !searchdata?.payerName  && !searchdata?.status ?
   <NoInsuranceFound 
   rowData={rowData}
   name={`${searchdata?.firstNameToDisplay ?? ''} ${searchdata?.lastNameToDisplay ?? ''}`} 
  //  id={through !== 'Discovery' && rowData?.ID}
   />
   :
   <>
   <DetailsHeader 
    insurance={rowData?.Insurance}
    topRightButtons={topRightButtons} 
    name={`${searchdata?.firstNameToDisplay ?? ''} ${searchdata?.lastNameToDisplay ?? ''}`} 
    dob={rowData?.DOB && rowData?.DOB !== 'N/A' ? rowData?.DOB : ''} 
    topRightButtonsLoaders={[{name:'Refresh',loader:loaders?.refreshing}]}
    // pageName={through !== 'Discovery' && 'Discoverydetails1'}
    // id={through !== 'Discovery' && rowData?.ID}
    />
    <Box>
      <Box>
        <Box className={formWrapper} sx={sharedStyles?.mt2em}>
       { rowData?.Notes !== 'N/A' &&  <Typography sx={sharedStyles?.mb1} align='center'>{rowData?.Notes}</Typography>}
          <Grid container rowSpacing={3} columnSpacing={8}>

            {topCardsItems?.map((elem, index) => {
              return (
                <Grid item xs={12} sm={6} lg={3} key={index} >
                  <Box sx={{ boxShadow: '0px 4px 8px #5E8A75', borderRadius: 2, bgcolor: 'white', height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, cursor: 'pointer',px:2 }}>
                    {elem?.icon}
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontWeight: 'bold', color: colors?.themeGreen }}>{elem?.name}</Typography>
                      <Typography>{elem?.value}</Typography>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
         
         <Box sx={{ width: '100%', mt: 5 }}>
            <Tabs>
              <MyTabs tabs={tabs} onChange={handleTabChange} activeTab={tabIndex}
              />
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  </> 
    }
    </Box>
  );
}
export default Discoverydetails1;
