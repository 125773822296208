import React, { useContext, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { SilverSummitHealtPlanHsContext } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext';
import { styles } from '../../components/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import CommonNextButton from '../../components/CommonNextButton';

const DatesInfoHsForm = () => {

    const [dateLimits] = useState({min:"2024-12-24",max:'2025-06-25'})
    const {datesInfo:formData,setDatesInfo:setFormData,setSelectedTab, onceCompleted} = useContext(SilverSummitHealtPlanHsContext)


    const handleDateChange = (date,field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };


    const handleSubmit = (submit) => {
        submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3} sx={styles?.parentPaperContainer}>
                <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                    Dates Information
                </Typography>
                <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3} mb={2}>
                  

                    {/* From Date */}
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="From Date"
                            value={formData.fromDate}
                            onChange={(date) => handleDateChange(date,'fromDate')}
                            minDate={dayjs(dateLimits?.min)}
                            maxDate={dayjs(dateLimits?.max)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    inputProps: { readOnly: true },
                                }
                            }}
                        />
                    </Grid>

                    {/* To Date */}
                    <Grid item xs={12} md={6}>
                    <DatePicker
                        label="To Date"
                        value={formData.toDate}
                        onChange={(date) => handleDateChange(date,'toDate')}
                        minDate={dayjs(dateLimits?.min)}
                        maxDate={dayjs(dateLimits?.max)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                inputProps: { readOnly: true },
                            }
                        }}
                    />
                </Grid>
                   

                </Grid>
                </Box>
                <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
            </Paper>
            
        </LocalizationProvider>
    );
};

export default DatesInfoHsForm;