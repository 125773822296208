
import React, { useContext } from 'react'
import { SilverSummitHealtPlanHsContext, SilverSummitHealtPlanHsContextProvider } from '../../../../../context/priorAuthorizationForms/SilverSummitHealtPlanHsContext';
import PatientSshpForm from './PatientSshpForm';
import DatesInfoHsForm from './DatesInfoSshpForm';
import DiagnosesSshpForm from './DiagnosesSshpForm';
import ProceduresSshpForm from './ProceduresSshpForm';
import SubscriberSshpForm from './SubscriberSshpForm';
import ProviderNotesSshpForm from './ProviderNotesSshpForm';
import ServiceInfoSshpForm from './ServiceInfoSshpForm';
import RequestingProviderSshpForm from './RequestinProviderSShpForm';
import RenderingProviderSshpForm from './RenderingProviderSshpForm';
import FormDetails from '../../components/FormDetails';

const SilverSummitHealthPlanHsForms = () => {
    return (
        <SilverSummitHealtPlanHsContextProvider>
            <AllForms  />
        </SilverSummitHealtPlanHsContextProvider>
    )
}

export default SilverSummitHealthPlanHsForms

const AllForms = () => {
    const {
        selectedTab, setSelectedTab,
            patient,
            datesInfo,
            diagnosesInfo,
            proceduresInfo,
            subscriber,
            providerNotes,
            serviceInfo,
            requestingProvider,
            renderingProviders,
            
    } = useContext(SilverSummitHealtPlanHsContext)
    const forms = [
        <PatientSshpForm/>,
        <DatesInfoHsForm/>,
        <DiagnosesSshpForm/>,
        <ProceduresSshpForm/>,
        <SubscriberSshpForm/>,
        <ProviderNotesSshpForm/>,
        <ServiceInfoSshpForm/>,
        <RequestingProviderSshpForm/>,
        <RenderingProviderSshpForm/>,
        <FormDetails
        data={{
            patient,
            serviceType:serviceInfo?.serviceType,
            diagnose:diagnosesInfo,
            procedure:proceduresInfo,
            subscriberInfo:subscriber,
            providerNote:providerNotes?.length ? providerNotes : [''],
            serviceInfo:{...serviceInfo,
                reportType:serviceInfo?.reportType,
                serviceLevel:serviceInfo?.serviceLevel
            },
            requestingProvider,
            renderingProvider:renderingProviders,
            datesInfo
        }}
        title={'For SilverSummit Health Plan'}
        setSelectedTab={setSelectedTab}
        tabNums={{patient:0,datesInfo:1,diagnosesInfo:2,proceduresInfo:3,subscriberInfo:4,providerNotes:5,serviceInfo:6,requestingProvider:7,renderingProviders:8}}
        // onSubmit={handleHsFormSubmit}
        // isLoading={isLoading}
        />,
        
    ];

    return forms[selectedTab] || null;
};