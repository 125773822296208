import React from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';

const InfoRow = ({ label, value }) => {
  if (!value) return null;
  
  return (
    <Box 
      sx={styles?.infoRowContainer}
    >
      <Typography 
        sx={styles?.infoRowLabel}
      >
        {label}
      </Typography>
      <Typography 
        sx={styles.infoRowValue}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default InfoRow;
