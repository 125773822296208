import { useContext, useEffect } from "react"
import { AmerigroupContext, AmerigroupContextProvider } from "../../../../../context/priorAuthorizationForms/AmerigroupArContext"
import AmeriGroupPatientForm from "./AmeriGroupPatientForm"
import def from "ajv/dist/vocabularies/discriminator"
import AmerigroupSubscribersForm from "./AmerigroupSubscribersForm"
import AmerigroupDiagnosisForm from "./AmerigroupDiagnosisForm"


const AmeriGroupForm = ({ patientDetails }) => {


    return (
        <AmerigroupContextProvider>
            <AllAmeriGroupForms />
        </AmerigroupContextProvider>
    )
}

const AllAmeriGroupForms = () => {
    const { selectedTab } =  useContext(AmerigroupContext)


    const forms = [
        <AmeriGroupPatientForm />,
        <AmerigroupSubscribersForm />,
        <AmerigroupDiagnosisForm />
    ]


    return forms[selectedTab] || null;
}


export default AmeriGroupForm