import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box } from '@mui/material'
import Eligibility from './Eligibility';
import Search from './Search';
import { useLocation, useSearchParams } from 'react-router-dom'
import { sharedStyles } from '../../sharedStyles';
import MBILookup from './MBILookup';
import PriorAuthTable from './PriorAuthTable';
import PaForm from './PaForm';
import SubmitAvaility from './SubmitAvaility';
import NewSubmitAvaility from './NewSubmitAvaility';

const EligibilityTab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()
     
    const tabs =
    [
        {
            label: "Eligibility ", value: <Eligibility />,
            button: 'New Search', changeTab: 1
        },
        {
            label: "Search", value: <Search />, changeTab: 1, button: 'Back',
        },
        {
            label: "Prior Authorization", value: <PriorAuthTable />, changeTab: 1, button: 'Back',
        },
        {
            label: "Submit PA", 
            // value: <PriorAuth />, 
            value:<PaForm/>,
            changeTab: 1, button: 'Back',
        },
        {
            label: "Search PA", 
            // value: <PriorAuth />, 
            // value:<SubmitAvaility/>,
                value: <NewSubmitAvaility/>,
            changeTab: 1, button: 'Back',
        },
        {
            label: "MBI Lookup", value: <MBILookup />, changeTab: 1, button: 'Back',
        }
    ]


    useEffect(() => {
        setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    }, [preSelectedTabParam]);
    return (
        <section className='Eligibility' key={location?.key}>
            <Box sx={sharedStyles?.tabWrapper}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    )
}
export default EligibilityTab
