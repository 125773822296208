import React, { useContext, useState } from 'react'
import dayjs from 'dayjs'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext';
import ImpDatesForm from '../../components/CommonDatesForms.jsx/ImpDatesForm';
import { paFormErrors } from '../../../../../utils/staticData';

const DatesInfoAnthemGaScForm = () => {
    const today = dayjs();
    const maxDate = today.add(18, 'month');
    const [dateLimits] = useState({ min: "2002-1-1", max: maxDate })
    const { datesInfo: formData, setDatesInfo: setFormData, setSelectedTab, onceCompleted } = useContext(AnthemGaScContext)
    const [errors, setErrors] = useState({ fromDate: false, toDate: false })

    const handleSubmit = (submit) => {
        const { toDate, fromDate } = formData
        if (!toDate || !fromDate) {
            setErrors({
                fromDate: fromDate ? false : paFormErrors?.reqField,
                toDate: toDate ? false : paFormErrors?.reqField
            })
            return
        }
        else {
            setErrors({
                fromDate: false,
                toDate: false
            })
            submit && onceCompleted ? setSelectedTab(onceCompleted) : setSelectedTab((prev) => prev + 1)
        }

    }

    return (
        <ImpDatesForm
            handleSubmit={handleSubmit}
            dateLimits={dateLimits}
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            onceCompleted={onceCompleted}
            setSelectedTab={setSelectedTab}
            dateLabels={{fromDate:'From Date', toDate:'To Date'}}
        />
    )
}

export default DatesInfoAnthemGaScForm