import React, { useContext, useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import CommonNextButton from '../../components/CommonNextButton';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, aetnaSrDefaultData, anthemCaDefaultData, paFormErrors } from '../../../../../utils/staticData';
import { AnthemCaContext } from '../../../../../context/priorAuthorizationForms/AnthemCaContext';

const RequestingProviderAnthemCa = () => {
  const [errors, setErrors] = useState(anthemCaDefaultData?.reqProvidersErrors);
  const { handleSrFormSubmit, requestingProvider: formData, setRequestingProvider: setFormData, setSelectedTab } = useContext(AnthemCaContext)
  const [showAllFields, setShowAllFields] = useState(false);


  const handleChange = (field, passValidation) => (event) => {
    const { value } = event.target;
    const regex = handleChangePaRegexs?.aetna?.[field];
    if (!passValidation) {
      if (regex && value !== "" && !regex.test(value)) {
        return;
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };


  const checkRequiredFields = () => {
    const requiredFields = ['npi', 'roleCode', 'phone', 'lastName'];
    let hasErrors = false;
    const errors = requiredFields.reduce((acc, field) => {
      if (!formData?.[field]) {
        acc[field] = true;
        hasErrors = true;
      } else {
        acc[field] = false;
      }
      return acc;
    }, {});
    return { hasErrors, errors };
  };

  const validateAddressFields = () => {
    const errors = {};
    const { addressLine1, addressLine2, npi, city, zipCode, phone, extension, emailAddress, fax } = formData;

    if (addressLine1 && addressLine1.length < 2) {
      toast.error(paFormErrors?.address);
      errors.addressLine1 = paFormErrors?.addressOptional;
    } else {
      errors.addressLine1 = false;
    }

    if (addressLine2 && addressLine2.length < 2) {
      toast.error(paFormErrors?.address);
      errors.addressLine2 = paFormErrors?.addressOptional;
    } else {
      errors.addressLine2 = false;
    }

    if (npi?.length < 10) {
      toast.error(paFormErrors?.npi);
      errors.npi = paFormErrors?.npi
    }
    else {
      errors.npi = false
    }

    if (city && city.length < 2) {
      toast.error(paFormErrors?.city);
      errors.city = paFormErrors?.cityOptional;
    } else {
      errors.city = false;
    }

    if (zipCode) {
      if (zipCode?.length < 5 || (zipCode?.length > 5 && zipCode?.length < 9)) {
        toast.error(paFormErrors?.zipCode)
        errors.zipCode = paFormErrors?.zipCodeOptional
      }
      else {
        errors.zipCode = false
      }
    }
    else {
      errors.zipCode = false
    }

    if (phone && phone?.length < 10) {
      toast.error(paFormErrors?.phone);
      errors.phone = paFormErrors?.phone;
    } else {
      errors.phone = false;
    }

    if (extension && extension?.length < 8) {
      toast.error(paFormErrors?.extension);
      errors.extension = paFormErrors?.extension;
    } else {
      errors.extension = false;
    }
    if (emailAddress && !handleChangePaRegexs?.aetna?.emailAddress?.test(emailAddress)) {
      toast.error(paFormErrors?.emailAddress)
      errors.emailAddress = paFormErrors?.emailAddressOptional
    }
    else {
      errors.emailAddress = false
    }
    if (fax && fax?.length < 10) {
      toast.error(paFormErrors?.fax)
      errors.fax = paFormErrors?.fax
    }
    else {
      errors.fax = false
    }
    return errors;
  };

  const handleSubmit = () => {

    const { hasErrors: missingRequiredFields, errors: requiredErrors } = checkRequiredFields();
    setErrors((prev) => ({ ...prev, ...requiredErrors }));

    if (missingRequiredFields) {
      toast.error('Please enter all important information');
      return;
    }

    const addressErrors = validateAddressFields();
    setErrors((prev) => ({ ...prev, ...addressErrors }));

    if (!Object.values({ ...requiredErrors, ...addressErrors }).some(Boolean)) {
      setSelectedTab((prev) => prev + 1)
    }
  };



  return (
    <Paper elevation={3} sx={styles?.parentPaperContainer}>
      <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
        Requesting Provider Information
      </Typography>
      <RadioGroup
        value={showAllFields}
        onClick={(event) => setShowAllFields(!showAllFields)}
      >
        <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
      </RadioGroup>
      <Box sx={styles?.commonPaperContainer}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Requesting Provider Last Name"
              value={formData.lastName}
              onChange={handleChange('lastName')}
              inputProps={{ maxLength: 60 }}
              error={errors?.lastName}
            />
          </Grid>
          {showAllFields &&
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Requesting Provider First Name"
                value={formData.firstName}
                onChange={handleChange('firstName')}
                inputProps={{ maxLength: 35 }}

              />
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Requesting Provider NPI"
              value={formData.npi}
              onChange={handleChange('npi')}
              inputProps={{ maxLength: 10 }}
              helperText='Enter a valid Requesting Provider National Provider Identifier (NPI) containing 10 numeric digits and beginning with a 1, 2, 3, or 4'
              error={errors?.npi}

            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Requesting Provider Contact Phone"
              value={formData.phone}
              onChange={handleChange('phone')}
              inputProps={{ maxLength: 10 }}
              error={errors?.phone}
              helperText={errors?.phone}
            />
          </Grid>

          {showAllFields &&
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Address Line 1"
                  value={formData.addressLine1}
                  onChange={handleChange('addressLine1')}
                  inputProps={{ maxLength: 55 }}
                  error={errors?.addressLine1}
                  helperText={errors?.addressLine1}

                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Address Line 2"
                  value={formData.addressLine2}
                  onChange={handleChange('addressLine2')}
                  inputProps={{ maxLength: 55 }}
                  error={errors?.addressLine2}
                  helperText={errors?.addressLine2}

                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider City"
                  value={formData.city}
                  onChange={handleChange('city')}
                  inputProps={{ maxLength: 30 }}
                  error={errors?.city}
                  helperText={errors?.city}

                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Requesting Provider State</InputLabel>
                  <Select
                    value={formData.stateCode}
                    label="Requesting Provider State"
                    onChange={handleChange('stateCode')}
                  >
                    {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                      <MenuItem key={value} value={code}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider ZIP Code"
                  value={formData.zipCode}
                  onChange={handleChange('zipCode')}
                  inputProps={{ maxLength: 9 }}
                  error={errors?.zipCode}
                  helperText={errors?.zipCode}

                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Contact Name"
                  value={formData.contactName}
                  onChange={handleChange('contactName')}
                  inputProps={{ maxLength: 60 }}

                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Extension"
                  value={formData.extension}
                  onChange={handleChange('extension')}
                  inputProps={{ maxLength: 8 }}
                  error={errors?.extension}
                  helperText={errors?.extension}
                />
              </Grid>


              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Email Address"
                  value={formData.emailAddress}
                  onChange={handleChange('emailAddress', true)}
                  error={errors?.emailAddress}
                  helperText={errors?.emailAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Fax"
                  value={formData.fax}
                  onChange={handleChange('fax')}
                  inputProps={{ maxLength: 10 }}
                  error={errors?.fax}
                  helperText={errors?.fax}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider Tax ID"
                  value={formData.taxId}
                  onChange={handleChange('taxId')}
                  inputProps={{ maxLength: 9 }}
                  error={errors?.taxId}
                  helperText={errors?.taxId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requesting Provider License Number"
                  value={formData.licenseNumber}
                  onChange={handleChange('licenseNumber')}
                  inputProps={{ maxLength: 50 }}
                  error={errors?.licenseNumber}
                  helperText={errors?.licenseNumber}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Requesting Provider License State</InputLabel>
                  <Select
                    value={formData.licenseStateCode}
                    label="Requesting Provider License State"
                    onChange={handleChange('licenseStateCode')}
                    error={errors?.licenseStateCode}
                  >
                    {aetnaSrDefaultData?.requestingProviderStates?.map(({ value, code }) => (
                      <MenuItem key={value} value={code}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          }


          <Grid item xs={12} md={!showAllFields ? 6 : 12}>
            <FormControl fullWidth required>
              <InputLabel>Requesting Provider Type</InputLabel>
              <Select
                value={formData.roleCode}
                label="Requesting Provider Type"
                onChange={handleChange('roleCode')}
                error={errors?.roleCode}
              >
                {aetnaDefaultData?.roleCodeVals?.map(({ value, code }) => (
                  <MenuItem key={value} value={code}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} noBack/>
    </Paper>
  );
};

export default RequestingProviderAnthemCa;
