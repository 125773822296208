import React, { useContext, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import toast from 'react-hot-toast';
import { AetnaSrContext } from '../../../../../context/priorAuthorizationForms/AetnaSrContext';
import { handleChangePaRegexs } from '../../handleChangeRegexes';
import { styles } from '../../Aetna/styles';
import { sharedStyles } from '../../../../../sharedStyles';
import { aetnaDefaultData, aetnaSrDefaultData } from '../../../../../utils/staticData';
import CommonNextButton from '../../components/CommonNextButton';

const POSQuantitiesSrForm = () => {
    const [errors, setErrors] = useState({
        placeOfServiceCode: false

    })
    const [showAllFields, setShowAllFields] = useState(false);
    const { setSelectedTab, serviceInfo, setServiceInfo, onceCompleted, setServiceType, serviceType } = useContext(AetnaSrContext)

    const handleChange = (event, field) => {
        const { value } = event.target;
        if (field !== 'quantity') {
            setServiceInfo((perv) => ({
                ...perv,
                [field]: value,
            }));
        }
        else {
            const regex = handleChangePaRegexs?.aetna?.[field];
            if (regex && value !== "" && !regex.test(value)) {
                return;
            }
            setServiceInfo((prevFormData) => ({
                ...prevFormData,
                [field]: value,
            }));
        }
    };

    const handleSubmit = (submit) => {

        if (!serviceInfo?.placeOfServiceCode) {

            setErrors({ placeOfServiceCode: true });
            toast.error('Please fill all important fields')
            return
        }
        else {
            setErrors({ placeOfServiceCode: false });
       submit ?   setSelectedTab(onceCompleted) :  setSelectedTab((prev) => prev + 1)
        }
    };

    return (
        <Paper elevation={3} sx={styles?.parentPaperContainer}>
            <Typography variant="h5" gutterBottom sx={sharedStyles?.mb4}>
                Place Of Service & Quantity Type Information
            </Typography>
            <>
                <RadioGroup
                    value={showAllFields}
                    onClick={(event) => setShowAllFields(!showAllFields)}
                >
                    <FormControlLabel value={false} control={<Radio sx={sharedStyles?.muiCheckedThemeColor} />} label="Required Fields Only" />
                </RadioGroup>
            </>

            <Box sx={styles?.commonPaperContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel>Place Of Service</InputLabel>
                            <Select
                                value={serviceInfo?.placeOfServiceCode}
                                label="Place Of Service"
                                onChange={(e) => handleChange(e, 'placeOfServiceCode')}
                                error={errors?.placeOfServiceCode}
                            >
                                {aetnaSrDefaultData?.placeOfServiceCode.map((item) => (
                                    <MenuItem key={item.code} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                ))}

                            </Select>
                            <FormHelperText sx={sharedStyles?.errorText}>
                                {errors?.placeOfServiceCode && errors?.placeOfServiceCode}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {
                        showAllFields &&
                        <>
                            <Grid item xs={12} >
                                            <FormControl fullWidth>
                                                <InputLabel>Service Type</InputLabel>
                                                <Select
                                                    value={serviceType}
                                                    label="Service Type"
                                                    onChange={(e) => setServiceType(e?.target?.value)}
                                                >
                                                              <MenuItem value="">
                                                                  <em>None</em>
                                                              </MenuItem>
                                                    {aetnaSrDefaultData?.serviceTypes?.map(({ value, code }) => (
                                                        <MenuItem key={value} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Quantity"
                                    value={serviceInfo?.quantity}
                                    onChange={(e) => handleChange(e, 'quantity')}
                                    error={errors?.quantity}
                                    helperText={errors?.quantity}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Quantity Type</InputLabel>
                                    <Select
                                        value={serviceInfo?.quantityTypeCode}
                                        label="Quantity Type"
                                        onChange={(e) => handleChange(e, 'quantityTypeCode')}
                                        error={errors?.quantityTypeCode}
                                    >
                                        {aetnaDefaultData?.quantityTypes?.map((item) => (
                                            <MenuItem key={item.code} value={item.value}>
                                                {item.value}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>

            <CommonNextButton handleSubmit={handleSubmit} setSelectedTab={setSelectedTab} onceCompleted={onceCompleted}/>
        </Paper>
    );
};

export default POSQuantitiesSrForm;
