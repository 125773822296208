import React, { useContext, useState } from 'react'
import ImpServicesForm from '../../components/ServicesInfoForms/ImpServicesForm'
import { AnthemGaScContext } from '../../../../../context/priorAuthorizationForms/AnthemGaScContext'
import { serviceInfoErrorsAnthem } from '../../../../../utils/staticData'

const ServicesInfoAnthemGaScForm = () => {
    const {setSelectedTab, serviceInfo, setServiceInfo, onceCompleted} = useContext(AnthemGaScContext)
     const [errors, setErrors] = useState(serviceInfoErrorsAnthem)
  return (
   <ImpServicesForm
   errors={errors}
   setErrors={setErrors}
   setSelectedTab={setSelectedTab}
   serviceInfo={serviceInfo}
   setServiceInfo={setServiceInfo}
   onceCompleted={onceCompleted}
   fieldsToHide={['serviceLevel']}
   
   />
  )
}

export default ServicesInfoAnthemGaScForm