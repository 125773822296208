import React, { useContext } from 'react'
import { AnthemGaHsContext } from '../../../../../context/priorAuthorizationForms/AnthemGaHsContext'
import CommonRequestingProviders from '../../components/RequestingProviders/CommonRequestingProviders'

const RequestingProviderAnthemGaHsForm = () => {
    const { requestingProvider: formData, setRequestingProvider: setFormData, setSelectedTab, onceCompleted } = useContext(AnthemGaHsContext)
  return (
  <CommonRequestingProviders
  formData={formData}
  setFormData={setFormData}
  setSelectedTab={setSelectedTab}
  onceCompleted={onceCompleted}
  />
  )
}

export default RequestingProviderAnthemGaHsForm